import React, { Component } from 'react';
import { SliderComponent } from './SliderComponent';
import RangeSliderStyle from './RangeSlider.style';

export default class RangeSlider extends Component<RangeSliderProps, RangeSliderState> {
  internalValue: number = 0;
  timer?: NodeJS.Timer;
  value?: number;


  constructor(props: RangeSliderProps) {
    super(props);
    this.state = {
      internalValue: 0
    }
  }

  render() {
    let { value, min, max, labelText, updateResult } = this.props;
    let { internalValue } = this.state;
    internalValue = !internalValue || this.value !== value ? value : internalValue;
    this.value = value;
    return (
    <RangeSliderStyle.div  className="a-rangeSlider flex align center left">
    <span className="label-no-grow">{labelText}</span>
      <div className="a-rangeSlider__input"> 
      <SliderComponent min={min} max={max} step={1} value={internalValue} onAfterChange={(milage) => updateResult(milage)} onChange={(milage) => { this.updateValue(milage) }} />
      </div>
      <span className="no-grow">+{internalValue} km</span>
    </RangeSliderStyle.div>
    
    )
  }

  updateValue(internalValue: number) {
    const { valueUpdated } = this.props;
    this.internalValue = internalValue;
    this.setState({ internalValue });
    if (!this.timer) {
      this.timer = setTimeout(() => {
        if (valueUpdated) valueUpdated(this.internalValue);
        this.timer = undefined
      }, 200)
    }
  }
}

interface RangeSliderProps {
  value: number;
  min: number;
  max: number;
  labelText?: string,
  valueUpdated?: (value: number) => void;
  updateResult: (value: number) => void
}

interface RangeSliderState {
  internalValue: number
}