import fetch from "cross-fetch";
import {
    AsyncAction,
    AsyncState,
    GlobalState,
    shouldFetch,
} from ".";
import { Reducer } from "redux";
import { ErrorResponse, Error } from "./Models/types";
import { ThunkDispatch } from "redux-thunk";
import { debounce } from "lodash";
import commons from "../constants/commons"

export function makeAsyncActionCreator<TInput, TOutput extends ErrorResponse>() {

    interface FetchRequest<TType> {
        type: TType;
        input: TInput;
    }

    interface FetchFailure<TType> {
        type: TType;
        key?: string;
        errors: Error[];
    }

    interface FetchSuccess<TType> {
        type: TType;
        key?: string;
        value: TOutput;
    }

    interface Params<RequestType extends string, FailureType extends string, SuccessType extends string> {
        /**
         * Redux type for the request action.
         */
        requestType: RequestType;

        /**
         * Redux type for the failure action.
         */
        failureType: FailureType;

        /**
         * Redux type for the success action.
         */
        successType: SuccessType;

        /**
         * Get the relevant sub section of the global redux state.
         */
        getSection: (state: GlobalState) => AsyncState<TOutput>;

        /**
         * Get `fetch` input to load the remote data,
         */
        inputToHttpRequest: (input: TInput, encodeURIComponent: (value: string) => string, state: GlobalState) => { url: string, request: RequestInit };

        /**
         * Get a key unique to this request. Used to avoid repeating the same request over and over.
         */
        getInputKey?: (input: TInput) => string;

        /**
         * Callback run on all successful response values. This is where you want to precalculate things like feature lookups and similar.
         * Running this should not cause any side effects.
         * TODO: This should ideally return a different type than TOuput.
         */
        transform?: (value: TOutput, globalState: GlobalState) => TOutput;

        /**
         * This is where you take actions as a consequence of a successful request.
         */
        successHandler?: (input: TInput, value: TOutput, globalState: GlobalState) => Promise<void> | void;

        /**
         * Should this be debounced, with a minimum request interval.
         */
        shouldDebounce?: boolean;
    }

    return function makeAsyncActionCreatorInner<RequestType extends string, FailureType extends string, SuccessType extends string>({
        requestType,
        failureType,
        successType,
        getSection,
        inputToHttpRequest,
        getInputKey,
        successHandler,
        shouldDebounce,
        transform,
    }: Params<RequestType, FailureType, SuccessType>) {
       


        type FetchActions = FetchRequest<RequestType> | FetchFailure<FailureType> | FetchSuccess<SuccessType>;
        
        async function func(input: TInput, globalState: GlobalState, dispatch: ThunkDispatch<GlobalState, void, FetchActions>, inputKey?: string) {
            let value: TOutput;
            try {

                const { url, request } = inputToHttpRequest(input, encodeURIComponent, globalState);

                let response;
                if (request.method === "POST") {
                    response = await fetch(url, {
                        body: request.body,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "POST",
                    });
                } else {
                    response = await fetch(url, request);
                }

                value = await response.json();
                if (value && value.errors) {
                    return dispatch({
                        errors: value.errors,
                        key: inputKey,
                        type: failureType,
                    });
                }

                if (!response.ok) {
                    return dispatch({
                        errors: [{
                            code: `HTTP ${response.status}`,
                            description: response.statusText,
                        }],
                        key: inputKey,
                        type: failureType,
                    });
                }

            } catch (e) {
                return dispatch({
                    errors: [{
                        code: `HTTP ${(e as Error).code}`,
                        description: (e as Error).description,
                    }],
                    key: inputKey,
                    type: failureType,
                });
            }

            const transformedValue = transform
                ? transform(value, globalState)
                : value;

            if (successHandler) {
                const outp = dispatch({
                    key: inputKey,
                    type: successType,
                    value: transformedValue,
                });
                await successHandler(input, transformedValue, globalState);
                return outp;
            }

            return dispatch({
                key: inputKey,
                type: successType,
                value: transformedValue,
            });
        }

        const debounceFunc = debounce(func, commons.DEBOUNCE_WAIT_TIME, { leading: true, trailing: true });

        function fetchAction(input: TInput, globalState: GlobalState): AsyncAction<FetchActions> {
            return async (dispatch) => {
                const inputKey = getInputKey && getInputKey(input);
                dispatch({
                    input,
                    key: inputKey,
                    type: requestType,
                });
                return (shouldDebounce
                  ? debounceFunc(input, globalState, dispatch, inputKey) || {
                      type: failureType,
                      key: undefined,
                      errors: [],
                    }
                  : func(input, globalState, dispatch, inputKey) || {
                      type: failureType,
                      key: undefined,
                      errors: [],
                    }) as Promise<FetchActions>;
            };
        }

        function fetchIfNeeded(input: TInput): AsyncAction<FetchActions> {
            
            return (dispatch, getState) => {
                const globalState = getState();
                 
                if (shouldFetch(getSection(globalState), getInputKey && getInputKey(input))) {
                    return dispatch(fetchAction(input, globalState));
                }
                return undefined;
            };
        }

        const reducer: Reducer<AsyncState<TOutput>, FetchActions> =
            (
                state = { isFetching: false, isInvalidated: false },
                action
            ) => {

                switch (action.type) {
                    case requestType:
                        return {
                            ...state,
                            isFetching: true,
                            errors: undefined,
                        };

                    case successType:
                        return {
                            ...state,
                            isFetching: false,
                            key: (action as FetchSuccess<SuccessType>).key,
                            value: (action as FetchSuccess<SuccessType>).value,
                            errors: undefined,
                        };

                    case failureType:
                    const newState = state;
                    
                        return {
                            ...newState,
                            isFetching: false,
                            errors: (action as FetchFailure<FailureType>).errors,
                        };

                    default:
                        break;
                }
                return state;
            };

        return {
            fetchIfNeeded,
            reducer,
            transform,
        };
    };
}
