// import { Reducer } from "redux";
import { SquidexContentResponse } from "../Models/types";
import { makeAsyncActionCreator } from "../makeAsyncActionCreator";
import { GlobalState } from "..";
import { SquidexContentData } from './types';
import actionTypes from "../../constants/actionTypes";
import apiEndpoints from "../../constants/apiEndpoints";


export interface SquidexContentActionType {
    languageMarket?:string;
}


export interface SquidexContentInput {
    squidexContentInput: SquidexContentActionType;

}

export const defaultValue: SquidexContentData = {
    searchInputLabel: "",
    filterButtonLabel: "",
    cearchInputLabel: "",
    chooseLabel: "",
    marketCode: "",
    searchErrorMessage: "",
    latestSearchText: "",
    yourCurrentLocationText: "",
    filtersHeading: "",
    quickSearchText: "",
    applyLabel: "",
    eventLabel: "",
    hoursText: "",
    contactsText: "",
    contactDescriptions: "",
    servicestext: "",
    eventsText: "",
    distanceUnitLabel:"",
    openingStatuses : null,
    weekDayNames: null,
    openingHourTypes: null,
    lunchBreakText: "",
    openNewTabText: "",
    getDirectionText: "",
    dealerPinImageURL: "",
    themeDir: "",
    clearAll: "",
    adjustRange: "",
    dealersLabel: "",
    workshopsLabel: "",
    lngLabel: "",
    electricityLabel: "",
    classDescriptions: null,
    busPinImageURL: "",
    openingHoursSequence: [1, 2, 3, 4, 5, 6, 0],
    chooseDealerButtonText: "",

}


export const actionCreatorSquidexContentsList= makeAsyncActionCreator<SquidexContentInput, SquidexContentResponse>()({
    requestType: actionTypes.SQUIDEX_CONTENT_ACTIONS.GET_SQUIDEX_CONTENT_DATA_REQUEST.toString(),
    failureType: actionTypes.SQUIDEX_CONTENT_ACTIONS.GET_SQUIDEX_CONTENT_DATA_FAILURE.toString(),
    successType: actionTypes.SQUIDEX_CONTENT_ACTIONS.GET_SQUIDEX_CONTENT_DATA_SUCCESS.toString(),
    getSection: (state: GlobalState) => state.squidexContentsList,
    inputToHttpRequest: (input: SquidexContentInput) => {
        return {
            url: `${apiEndpoints.DEALER_API_DOMAIN}${apiEndpoints.DEALER_API_ENDPOINTS.GET_DEALER_TRANSLATIONS}/${input.squidexContentInput.languageMarket}`,
            request: {},
        };
    },
    getInputKey: (input: SquidexContentInput) => "GetSquidexContent-" + Date.now(),
    successHandler: (input: SquidexContentInput, dalerData: SquidexContentResponse) => {
       
    },
});



export const fetchSquidexContentsListIfNeeded = actionCreatorSquidexContentsList.fetchIfNeeded;


export const reducer = {
    squidexContentsList: actionCreatorSquidexContentsList.reducer,
};
