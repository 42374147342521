import AppStyle from "./App.style";
import { Component } from "react";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import * as React from "react";
import DealerLocator from "../components/pages/DealerLocator/templates/DealerLocator";
import GlobalSettingsConatiner, { Props as GlobalSettingsConatinerProps } from "../containers/GlobalSettings/GlobalSettings";
import { getTruckConfiguratorFlag } from "../components/shared/PropsHelper"; 

class App extends Component<RouteComponentProps<any> & GlobalSettingsConatinerProps> {

    public render() {
        
        let classes = getTruckConfiguratorFlag(this.props) ? "appWrapper truckConfigurator" : "appWrapper"; 
        return (
            <AppStyle.div className={ classes }>
              <Route
                path="/"
                render={(props) => <DealerLocator {...props} />}
               />
               <div className="bottomLine" />
            </AppStyle.div>
        );
    }

}

export default withRouter(GlobalSettingsConatiner(App));
