import styledComponents from "styled-components";

export const div = styledComponents.div`
&.m-locationList {
  width: 100%;
 
  .m-locationList__itemsWrapper {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .m-locationList__item {
    background: #fff;
    border-bottom: 1px solid #d0d0d0;
    min-height: 43px; 
    padding: 16px 32px 16px 32px;

    @media (max-width: 768px) {
      padding: 5px 20px;
      min-height: 32px;
    }

    &:hover {
      background: #CFEAEF;
      transition: background .25s ease-in 0s;
    }

    .a-anchor {
      color: #4D4E53;
      display: flex;
      flex-wrap: nowrap;
      text-decoration: none;
    }

    span.no-grow {
      flex-basis: 42px;
    }
 
    .fa {
      color: #1251B5;
      font-size: 20px;
      margin-right: 25px;
      
    }

    img {
      height: auto;
      margin-right: 24px;
      margin-left: -2px;
      margin-top: 1px; 
      width: 19px;
    }
  }
}
`;


export const  LocationListStyle = {
  div: div,
};

export  default  LocationListStyle ;
