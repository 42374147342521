import styledComponents from "styled-components";

export const div = styledComponents.div`
&.a-checkBox  {
    color: #000;
    display: block;
    margin: 0;
    padding-right: 24px;
    position: relative;
    user-select: none;

    label {
        margin-right: 7.5px;
        position: relative;
    }

    .a-checkBox__input {
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 1;
    }

    .a-checkBox__tick {
        border: 1px solid #404040;
        border-radius: 3px;
        cursor: pointer;
        height: 20px;
        position: absolute;
        top: 2px;
        width: 20px;
        z-index: 0;
    }

    .a-checkBox__input[checked='checked'] + .a-checkBox__tick,
    .a-checkBox__input:checked + .a-checkBox__tick {

        &:after {
        color: #3071A9;
        content: "\f00c";
        font-family: FontAwesome;
        font-size: 18px;
        left: 0;
        position: absolute;
        top: -5px;
        }
    }
}
`;


export const  CheckBoxStyle = {
    div: div,
};

export  default  CheckBoxStyle ;





