import React, { Component } from "react";
import ErrorMessageStyle from "./ErrorMessage.style";

interface Props {
  errorMessageString: string,
}

class ErrorMessage extends Component<Props> {
  render() {

    const { errorMessageString } = this.props;

    return (
      <ErrorMessageStyle.div className={`error-message`}>
        {errorMessageString}
      </ErrorMessageStyle.div>
    );
  }
}

export default ErrorMessage;
