export enum DEALERS_FILTER_QUERY {
  RADIUS = "radius" as any,
  LATITUDE = "Latitude" as any,
  LONGITUDE = "Longitude" as any,
  CLASSES = "Classes" as any,
  SEARCH_COUNTRY_CODE = "Search=CountryCode eq " as any,
  SEARCH_NAME = "Search=Name contains " as any,
  SEARCH_CTDI_ID = "Search=ctdiid eq " as any,
  SEARCH_MAIN_DEALER_ID = "Search=maindealerid eq " as any,
  SEARCH_ORGANIZATION_ID = "Search=organizationid eq " as any,
  CITY_OR_NAME = "CityOrName" as any,
  DEALER_IDS = "ids" as any,
  SERVICES = "Services" as any,
  INITIAL_LOAD = "init" as any
}

// We can search from dealers API based on search parameter
// Name, CtdiId, Latitude, Longitude, City, AddressLine1, CountryName, CountryCode, Email, Website, Class, ClassDescription, SubClass, SubClassDescription

export enum LOCATION_IQ {
  LATITUDE = "lat" as any,
  LONGITUDE = "lon" as any,
  SEARCH_QUERY_Q = "q" as any,
  MAP_VIEWBOX = "viewBox" as any,
  COUNTRY_CODES = "countrycodes" as any,
  BOUNDED = "bounded" as any
}

export enum QUERY_TEXT {
  QUERY = "query" as any
}

export enum CONFIGURATOR_QUERY {
  NAME = "name" as any,
  ADDRESS_LINE1 = "addressLine1" as any,
  ADDRESS_LINE2 = "addressLine2" as any,
  ADDRESS_LINE3 = "addressLine3" as any,
  POSTCODE = "postcode" as any,
  CITY = "city" as any,
  COUNTRY_NAME = "countryName" as any
}

export const LIMIT: string = "limit";

export const BUSINESS_AREA_CODE = "businessAreaCode";

export const IS_DUAL_BRANDED = "isDualBranded";

export const FORMAT_JSON: string = "format=json";

export const QueryParams = {
  DEALERS_FILTER_QUERY: DEALERS_FILTER_QUERY,
  LOCATION_IQ: LOCATION_IQ,
  FORMAT_JSON: FORMAT_JSON,
  QUERY_TEXT: QUERY_TEXT,
  LIMIT: LIMIT,
  BUSINESS_AREA_CODE: BUSINESS_AREA_CODE,
  IS_DUAL_BRANDED: IS_DUAL_BRANDED,
  CONFIGURATOR_QUERY: CONFIGURATOR_QUERY,
};

export default QueryParams;
