import fetch from "cross-fetch";
import apiEndpoints from "../constants/apiEndpoints";
import { DealerInfoData } from "../store/DealerLocator/types";

export function getValueFromMap(map: any | null, key: string) {
  let returnValue: string = key;
  if (map) {
    if (key in map) {
      returnValue = map[key] || key;
    }
  }

  return returnValue;
}

export function getTranslatedText(
  map: any | null,
  key: string,
  defaultValue: string
) {
  let returnValue: string = defaultValue;
  if (map) {
    if (key in map) {
      returnValue = map[key] || key;
    }
  }

  return returnValue;
}

//**************** Scroll Up Animation *************************************************************************/

export const easeInOutQuad = function(
  t: number,
  b: number,
  c: number,
  d: number
) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const animateScrollTo = (element: any, to = 0, duration: number) => {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;
  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

//**************** Local Storage *************************************************************************/

export const saveToLocalStorage = (key: string, value: any, isBusLocator: boolean) => {  
  let keyListLabel = "DEALER-LOCATOR-KEY-LIST";
  if (typeof window === "undefined") return;
  keyListLabel = isBusLocator === true ? keyListLabel + "_BUS" : keyListLabel;
  let keyList = window.localStorage.getItem(keyListLabel)
    ? JSON.parse(window.localStorage.getItem(keyListLabel) as any)
    : [];
  if (keyList.indexOf(key) === -1) {
    keyList.push(key);
    window.localStorage.setItem(keyListLabel, JSON.stringify(keyList));
  }
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = ( isBusLocator: boolean, key?: string,) => {
  let keyListLabel = "DEALER-LOCATOR-KEY-LIST";
  if (!window || !window.localStorage) return {};
  if (key) {
    return window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key) as any)
      : {};
  }
  keyListLabel = isBusLocator === true ? keyListLabel + "_BUS" : keyListLabel;
  let keyList = window.localStorage.getItem(keyListLabel)
    ? JSON.parse(window.localStorage.getItem(keyListLabel) as any)
    : [];
  if (keyList && Array.isArray(keyList)) {
    let ret: any = {};
    keyList.forEach(k => {
      ret[k] = getFromLocalStorage(isBusLocator, k);
    });
    return ret;
  }
  return {};
};

export const getNumber = (inputString: string) => {
  let numberValue: number = 0;
  if (inputString) {
    numberValue = !isNaN(Number(inputString)) ? Number(inputString) : 0;
  }

  return numberValue;
};

export const getAdjustedUrl = (websiteUrl: string) => {
  let receivedUrl = websiteUrl || "";
  const searchMask = "www";
  const regEx = new RegExp(searchMask, "ig");
  const replaceMask = "http://www"; 

  receivedUrl = receivedUrl.toLowerCase().startsWith(searchMask)
    ? receivedUrl.replace(regEx, replaceMask)
    : receivedUrl;
    
  return receivedUrl;
};

//**************** Get Direction URL *************************************************************************/
export const getDirectionUrl = (
  userLatitude: string,
  userLongitude: string,
  dealerLatitude: number,
  dealerLongitude: number
) => {
  let directionUrl = "";
  let currentLocation = "Current+Location";
  if (userLatitude && userLongitude) {
    currentLocation = getNumber(userLatitude) + "," + getNumber(userLongitude);
  }
  if (dealerLatitude && dealerLongitude) {
    directionUrl =
      apiEndpoints.GOOGLE_GET_DIRECTION +
      currentLocation +
      "/" +
      dealerLatitude +
      "," +
      dealerLongitude;
  }

  return directionUrl;
};

//**************** Keyboard event checking *************************************************************************/
export const isKeyCode = (pressedKeyEvt: any, code: number) => {
  const e = pressedKeyEvt || window.event;
  const pressedCode = e.keyCode || 0;
  return pressedCode === code;
};

//**************** Equality check of tqo array *************************************************************************/
export const isArraysEquals = (array1: number[], array2: number[]) => {
  if (array1.length !== array2.length) return false;
  for (var i = array1.length; i--; ) {
    if (array1[i] !== array2[i]) return false;
  }

  return true;
};

//**************** Format Hours Time with :*************************************************************************/
export const formatHoursTime = (inputTime: string) => {
  if (inputTime && inputTime.length > 2) {
    const length: number = inputTime.length;
    const lastString = inputTime.substring(length - 2, length);
    const firstString = inputTime.substring(0, length - 2);
    inputTime = firstString + ":" + lastString;
  }

  return inputTime;
};

//**************** Method to check empty string with whitespaces :*************************************************************************/
export const isNotEmptyString = (inputString: string, length?: number) => {
  let isNotEmpty: boolean = false;
  length = length || 0;
  if (inputString && inputString.trim().length > length) {
    isNotEmpty = true;
  }

  return isNotEmpty;
};

//**************** Method to check empty string with whitespaces :*************************************************************************/
export const convertObjectToGeoJson = (
  dealerInfoData: Array<DealerInfoData>
) => {
  let geoJsons = [] as any;
  if (dealerInfoData && dealerInfoData.length > 0) {
    dealerInfoData.forEach(function(dealerInfoData: DealerInfoData) {
      geoJsons.push({
        type: "Feature",
        properties: {
          dealerId: dealerInfoData.id
        },
        geometry: {
          type: "Point",
          coordinates: [dealerInfoData.longitude, dealerInfoData.latitude]
        }
      });
    });
  }
  return geoJsons;
};


export const isObjectEmpty = (obj:any) => {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

export const getURLParamValue = (paramName:string) => {
  var results = new RegExp('[?&]' + paramName + '=([^&#]*)').exec(window.location.href);
  if (results === null){
     return null;
  }
  else{
     return decodeURI(results[1]) || 0;
  }
}

export async function callHttpGet(url: string, request: string) {
  let response;
  let value: any;
  const fullUrlString = decodeURIComponent(url) + (request?`?${request}`:"");
  try {
    response = await fetch(fullUrlString);
    value = await response.json();
    if (response.ok) {
      console.log(`response is ok =>>>>>>>> code: HTTP ${response.status}`);
    }
    if (value && value.errors) {
      console.log(`errors in response =>>>>>>>> ${value.errors}`);
    }
    if (!response.ok) {
      console.log(`response is not ok =>>>>>>>> code: HTTP ${response.status},
  description: ${response.statusText}`);
    }
  } catch (e) {
    console.log(`exception=>>>>>>>> ${e}`);
  }
}

export async function callHttpPost(url: string, data: any) {
  let response;
  let value: any;
  const decodedUrl = decodeURIComponent(url);
  try {
    response = await fetch(decodedUrl,{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });
    value = await response.json();
    if (response.ok) {
      console.log(`response is ok =>>>>>>>> code: HTTP ${response.status}`);
    }
    if (value && value.errors) {
      console.log(`errors in response =>>>>>>>> ${value.errors}`);
    }
    if (!response.ok) {
      console.log(`response is not ok =>>>>>>>> code: HTTP ${response.status},
  description: ${response.statusText}`);
    }
  } catch (e) {
    console.log(`exception=>>>>>>>> ${e}`);
  }
}

export async function postToParent(data: any) {  
  try {
    window.parent.postMessage(data, "*");
  } catch (e) {
    console.log(`exception=>>>>>>>> ${e}`);
  }
}

/**
 * If isSalesDealersOnly value is true then it will filter DealersData 
 * to get only sales only dealers (A,B and B class only)
 */
export const isSalesDealersOnlyData = () => {
 /* let isSalesDealersOnly: boolean = false;
  let isSalesDealersOnlyParam:string = (getURLParamValue("isSalesDealersOnly") || (window as any).isSalesDealersOnly || "false");
  
  if (isSalesDealersOnlyParam.toString().toLowerCase() === "true") {
    isSalesDealersOnly = true;
  }

  return isSalesDealersOnly;*/

  let isSalesDealersOnly: boolean = false;
  let isSalesDealersOnlyParam:string = (getURLParamValue("isSalesDealersOnly") || "");
  if(!isSalesDealersOnlyParam) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      isSalesDealersOnlyParam = baseDoc.getAttribute('data-isSalesDealersOnly') || "";
    }
  }
  isSalesDealersOnlyParam = isSalesDealersOnlyParam || (window as any).isSalesDealersOnly || "false";
  
  if (isSalesDealersOnlyParam.toString().toLowerCase() === "true") {
    isSalesDealersOnly = true;
  }

  return isSalesDealersOnly;
};

/**
 * If isDualBranded value is true then it will not filter DealersData 
 * and will show the Renault Trucks contacts for that specific market 
 * even with brand code 120
 */
export const isDualBrandedData = () => {
  let isDualBranded: boolean = false;
  let isDualBrandedParam:string = (getURLParamValue("isDualBranded") || "");
  if(!isDualBrandedParam) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      isDualBrandedParam = baseDoc.getAttribute('data-isDualBranded') || "";
    }
  }
  isDualBrandedParam = isDualBrandedParam || (window as any).isDualBranded || "false";
  
  if (isDualBrandedParam.toString().toLowerCase() === "true") {
    isDualBranded = true;
  }

  return isDualBranded;
};

