import styledComponents from "styled-components";

export const div = styledComponents.div`
&.a-rangeSlider {


  span.no-grow {
    font-size: 14px; 
    margin-left: 20px;
    margin-right: 20px;
    text-align: right;    
    color: #1251B5;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  span.label-no-grow {
    font-size: 14px;
    margin-left: 0;
    margin-right: 20px;
    text-align: left;
    flex-grow: 0;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      width: 100%;
    }
  }

  .a-rangeSlider__input {
    position: relative;
  }

`;


export const  RangeSliderStyle = {
    div: div,
};

export  default  RangeSliderStyle ;
