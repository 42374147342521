import styledComponents from "styled-components";

export const div = styledComponents.div`

&.o-viewDealer { 
  background: #fff;
  height: 100%;

  h3, h4 {
    color: #4D4E53;
  }

  .o-viewDealer__wrapper {
    background: #fff;
    padding: 32px 32px 16px;

    @media screen and (max-width: 1024px) {
      padding: 32px 20px 16px;
    }
  }
  
  .o-viewDealer__main {
    color: #4D4E53;

    h3 {
      font-size: 16px;
    }

    @media screen and (max-width: 1024px) {

      h3 {
        font-size: 20px;
      }

      .o-viewDealer__close {
        order: 0;
      }

      .o-viewDealer__favo {
        order: 1; 
        flex-basis: auto; 
        text-align: right;
        padding-top: 20px;
      }
    }

  }

  .fa {
    color: #4D4E53;
    font-size: 28px;
  }

  .fa-star, 
  .fa-star-o {
    font-size: 25px;
  }

  .o-viewDealer__close {
    background: transparent;
    border: none;
    box-shadow: none;
    color: #4D4E53;
    margin-left: 20px;
    padding: 0;
  }

  .o-viewDealer__address {
    margin: 30px 0 20px;
  }

  .o-viewDealer__openText {
    text-align: right;
    font-size: 14px;
    min-height: 40px; 

    @media screen and (max-width: 1024px) {
      order: 1;
      padding-top: 20px;
      text-align: left;
    }
  }
  
  .openNow {
    color: #009640
  }

  .o-viewDealer__links {
    font-size: 14px;
    line-height: 2; 
    margin-bottom: 10px;

    .fa {
      color: #00A0CE;
      font-size: 16px;
      margin-right: 15px;
    }

    a {
      line-height: 1.5;
    }
  }

  .m-accordion {
    font-size: 12px;
    margin: 0; 
  }

  .m-accordion__title {
    padding: 30px 0 10px;
    margin: 0 0 20px;
  }

  .m-accordion__title h4 {
    color: #4D4E53;
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
  }

  .m-accordion__contactItem {
    line-height: 1.5;
    padding: 0 16px 32px 0;

    h4 {
      color: #919296;
      font-size: 14px;
      margin: 0 0 3px;
    }

    strong {
      display: block;
      margin-bottom: 16px;
    }

    .fa {
      color: #00A0CE;
      font-size: 16px;
      margin-right: 12px;
    }

    .a-anchor {
      overflow-wrap: break-word;
      padding-bottom: 3px;
      word-wrap: break-word;
      word-break: break-word;
    }
  }

  .m-accordion__content h5 {
    color: #919296;
    font-size: 14px;
    margin: 0;
    padding: 20px 0 10px;
  }

  .m-accordion__content > h5:nth-child(1) {
    padding-top: 10px;
  }

  .m-accordion__title + .m-accordion__content h5 + .m-accordion__hoursItem  {
    background: #f5f5f5;
 

    & + .m-accordion__hoursItem + .m-accordion__hoursItem {
      background: #f5f5f5;
    }
  }

  .m-accordion__hoursItem {
    padding: 10px;

    &.today {
      font-weight: bold;
    }

    .lunchBreak {
      font-weight: normal;
    }
    
    > span:last-child {
      margin-left: 32px;
    }
  }

  .m-accordion__serviceItem {
    padding: 0 8px 7.5px 0;

    .fa {
      color: #00A0CE;
      font-size: 18px;
      margin-right: 12px;
    }
  }
}

`;


export const  DealerDetailsViewStyle = {
  div: div,
};

export  default  DealerDetailsViewStyle ;
