import * as React from 'react';
import ReactMapGL , { Marker, NavigationControl, Popup } from 'react-map-gl';
import { fromJS } from 'immutable';
import MapBoxStyle  from './MapBox.style';
import MapBoxLayer from './MapBoxLayer';
import MapBoxPopup from './MapBoxPopup';
import MapBoxPin from './MapBoxPin';
import MapBoxController from './MapBoxController';
import WebMercatorViewport from 'viewport-mercator-project';
import GlobalSettingsConatiner, { Props as GlobalSettingsConatinerProps } from "../../../../containers/GlobalSettings/GlobalSettings";
import DealerLocatorConatiner, { Props as DealerLocatorConatinerProps } from "../../../../containers/DealerLocator/DealerLocator";
import SquidexContentConatiner, { Props as SquidexContentProps } from "../../../../containers/SquidexContent/SquidexContent";
import Anchor from '../../atoms/Anchor';
import { DealerInfoData } from "../../../../store/DealerLocator/types";
import { LocationPinData } from "../../../../store/GlobalSettings/types";
import { SETTINGS as  settings } from './MapBox.settings';
import Commons from '../../../../constants/commons';
import { getDistantDealers } from '../../../shared/PropsHelper'; 

type Props =  & GlobalSettingsConatinerProps & DealerLocatorConatinerProps & SquidexContentProps & {
  data: DealerInfoData[];
  locationPinData?: LocationPinData;
  windowInnerWidth:any;
  windowInnerHeight:any;
  action:(e: React.MouseEvent, dealerId:number, dealerName: string) => void;
  toggleFavoriteDealersListFilter:() => void;
  isFavoriteDealersList: boolean;
  getDealersListForUserCurrentLocation: () => void;
  isUserCurrentLocation: boolean;
  updateUserCurrentLocationFlag: (flag:boolean, searchQueryText: string) => void;
  updateReactMapGLRef: (ReactMapGLRef: any) => void;
  dealerSearchView2: boolean;
}


// We define members as a state (the compoment holding this will be a container
// component)
interface State {
  viewport: any,
  settings: any,
  isUserNearByDealersList: boolean,
}
const accessToken = Commons.MAP_ACCESS_TOKEN;
const MARKER_ICON_HEIGHT = 40;
const MARKER_ICON_WIDTH = 30;
const MIN_MAP_HEIGHT = 400;
const MIN_SCROLLABLE_HEIGHT = 100;

const navStyleRight:any = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: 0,
};


const mapStyle = fromJS(
  {
    version: 8,
    name: 'LocationIQ Streets',
    sources: {
      streets: {
        type: 'raster',
        tiles: [
          `https://a-tiles.locationiq.org/v2/obk/r/{z}/{x}/{y}.png?key=${accessToken}`,
          `https://b-tiles.locationiq.org/v2/obk/r/{z}/{x}/{y}.png?key=${accessToken}`,
          `https://c-tiles.locationiq.org/v2/obk/r/{z}/{x}/{y}.png?key=${accessToken}`
        ],
        tileSize: 256,
        maxzoom: 20,
      }
    },
    layers: [
      {
        id: 'streets',
        type: 'raster',
        source: 'streets',
        paint: {
          'raster-fade-duration': 100,
        }
      }
    ]
  })

class MapBox extends React.Component<Props, State> {
  constructor(props:Props) {
      super(props); 
      this.state = {
        viewport: settings.map_viewport,
        settings: settings.map_settings,
        isUserNearByDealersList: false,
      };
      this.ReactMapGLRef = React.createRef();
      this.getLongLatArr = this.getLongLatArr.bind(this);
    }
    
    public mapBoxController:any = null;
    public ReactMapGLRef:any = null;
    
    componentDidMount() {
      if(!this.mapBoxController && this.props.windowInnerHeight ) {
        this.mapBoxController = new MapBoxController(this.props.windowInnerHeight<(MIN_MAP_HEIGHT+MIN_SCROLLABLE_HEIGHT) ? {disableMapDragging:true} : {disableMapDragging:false});
        if(this.props.data) {
          this.setViewport(this.props.data, this.props.windowInnerHeight);
        }
      }
    }


  componentWillReceiveProps(nextProps:Props) {
    if(!this.mapBoxController && nextProps.windowInnerHeight) {
      this.mapBoxController = new MapBoxController(nextProps.windowInnerHeight<(MIN_MAP_HEIGHT+MIN_SCROLLABLE_HEIGHT) ? {disableMapDragging:true} : {disableMapDragging:false});
      if(nextProps.data) {
        this.setViewport(nextProps.data, nextProps.windowInnerHeight);
      }
    }
    if (this.mapBoxController && nextProps && nextProps.data) {
      if (!this.props.data || JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
        this.setViewport(nextProps.data, nextProps.windowInnerHeight);
      }
    }
  }


  getLongLatArr(data:DealerInfoData[]){
      const distantDealers = getDistantDealers(this.props);
      const adjustedData = distantDealers && data.length > 1 ? data.filter(x => distantDealers.indexOf(x.ctdiId) < 0) : data; 
      const longFloats:any  = adjustedData.map(x => x.longitude && x.longitude ? parseFloat(""+x.longitude) : undefined).filter(x => x !== undefined);
      const latFloats:any   = adjustedData.map(x => x.latitude  && x.latitude? parseFloat(""+x.latitude)  : undefined).filter(x => x !== undefined);  
      if(!longFloats || !longFloats.length) {
        return null
      }
      return longFloats.length > 1 ?  [
        [Math.min.apply(null, longFloats), Math.min.apply(null, latFloats)],
        [Math.max.apply(null, longFloats), Math.max.apply(null, latFloats)]
      ] : [
        [Math.min.apply(null, longFloats), Math.min.apply(null, latFloats)]
      ];
    
  }

  setViewport(data:Array<DealerInfoData>, windowInnerWidth:number) {
    try{ 
      if(data && data.length){
        let longLatArr:any = this.getLongLatArr(data);
        if(longLatArr){
          if(longLatArr.length === 2){
            //we may want to remove some dealers from the longlatArr we use here. adjustedLongLatArr?
            const {longitude, latitude, zoom} = new WebMercatorViewport(this.state.viewport).fitBounds(
              longLatArr, {padding: 20, offset: [0, -100]}
          );

          const adjustedZoom = windowInnerWidth > 750 ? zoom : (zoom-1);
              this.setState({
                  viewport: {
                    ...this.state.viewport, 
                    longitude, latitude, zoom:adjustedZoom
                  }
                }); 
          }
          else{
            this.setState({
              viewport: {
                ...this.state.viewport,
                longitude:longLatArr[0][0], latitude:longLatArr[0][1]
              }
            })
          }
        }
        else{
          this.setState({
            viewport: {
              ...this.state.viewport
            }
          })
        }      
      }
    }catch(err){
      this.setState({
        viewport: {
          ...this.state.viewport,
        }
      });
    }
    
  }

  updateViewport = (viewport: any) => {
    if (JSON.stringify(this.state.viewport.latitude) !== JSON.stringify(viewport.latitude)
    || JSON.stringify(this.state.viewport.longitude) !== JSON.stringify(viewport.longitude)) {
      this.props.updateUserCurrentLocationFlag(false, "");
    }    
    this.setState({ viewport });
  }

  /** 
   * This method is used to set the react map gl refrence to 
   * dealer locator state variable so that we can use to fetch the Map
   * properties (example-bound) whenever required 
   */
  updateReactMapGLRef = (mapGLRef: any) => {
    this.props.updateReactMapGLRef(mapGLRef);
  }

  
  renderDealerMarker = (dealerInfoData: DealerInfoData, index:number) => {
    const { globalSettings, squidexContentsList } = this.props;
    const { isBusLocator } = globalSettings;
    const { viewName } = globalSettings
    const offsetLeft  = -MARKER_ICON_WIDTH/2;
    const offsetTop   = -MARKER_ICON_HEIGHT;
    const pinImageURL = (isBusLocator === true ? squidexContentsList.busPinImageURL : squidexContentsList.dealerPinImageURL);
    if (dealerInfoData.latitude && dealerInfoData.longitude) {
      return (
        <Marker 
          key={`marker-${index}`}
          longitude={dealerInfoData.longitude}
          latitude={dealerInfoData.latitude}  
          offsetLeft={offsetLeft} 
          offsetTop={offsetTop} >
          <MapBoxPin dealerPinImageURL={pinImageURL} viewName={viewName} action={this.props.action} index={index} data={dealerInfoData} />
        </Marker>
      );
    }
    return null;
    
  }

  renderDealerPopup = (dealerInfoData: DealerInfoData, index:number) => {
    const { globalSettings, squidexContentsList } = this.props;
    const { isTruckConfigurator, dealerId } = globalSettings;
    const showPopUp = dealerInfoData && dealerInfoData.id === dealerId ? true : false ;
    return (isTruckConfigurator && showPopUp &&
      <Popup
        key={`popup-${index}`}
        tipSize={5}
        anchor="top"
        longitude={dealerInfoData.longitude}
        latitude={dealerInfoData.latitude}  
        closeOnClick={false} >
        <MapBoxPopup index={index} data={dealerInfoData} 
        chooseDealerButtonText={squidexContentsList.chooseDealerButtonText} />
      </Popup>
    );
  }

  /**
   * This method to navigate the user location and 
   * if user continue clicking on the button 
   * then navigate the user location with the help of zoom
   * fetch all the near by data 
   * 
   */
  userNearByDealersList = () => {
    const { globalSettings } = this.props;
    const { locationPinData} = globalSettings;
    if (locationPinData.coordinates && locationPinData.coordinates.length > 0) {
    this.setState({
      viewport: {
        ...this.state.viewport, 
        longitude:locationPinData.coordinates[0][1], latitude:locationPinData.coordinates[0][0],
      }
    }); 
    } 
    this.props.getDealersListForUserCurrentLocation();      
  }

  /**
   * This method to navigate the user location and 
   * if user continue clicking on the button 
   * then navigate the user location with the help of zoom
   * fetch all the near by data 
   * 
   */
  favoriteDealersList = () => {
    const { globalSettings, isFavoriteDealersList } = this.props; 
    const { isBusLocator } = globalSettings;  
    let favouriteDealersId = isBusLocator === true ? ( globalSettings.localStorageData.favouriteListBus || []) :
    (globalSettings.localStorageData.favouriteList || []);    
    if(!isFavoriteDealersList) {
      if (favouriteDealersId && favouriteDealersId.length > 0 ){
      this.props.getFavoriteDealersList({
        dealersListInput: {
          languageMarket: globalSettings.languageMarket,
          dealerIds: favouriteDealersId.join()
        }
      });
    }  else{
      this.props.getFavoriteDealersList({
        dealersListInput: {
          languageMarket: globalSettings.languageMarket,
          dealerIds: ""+0
        }
      });
    }
  }
  this.props.toggleFavoriteDealersListFilter();
}

  render() {

    const { data , globalSettings, dealerSearchView2, isFavoriteDealersList, isUserCurrentLocation, squidexContentsList } = this.props;
    const { locationPinData, viewName } = globalSettings;
    const { viewport, settings } = this.state ;
    if(!this.mapBoxController) return null;

    return (
      <MapBoxStyle.div className="m-mapBox">       
          <ReactMapGL
          ref={ mapGLRef => {this.updateReactMapGLRef(mapGLRef)} }
          controller={this.mapBoxController}
          {...viewport}
          {...settings}
          width={100}
          height={100}
          mapStyle={mapStyle}
          onViewportChange={this.updateViewport}
          mapboxApiAccessToken={accessToken}
          >
          {locationPinData ? <MapBoxLayer dealerPinImageURL={squidexContentsList.dealerPinImageURL} locationPinData={locationPinData} /> :''}


          {(data && data.length > 0) ? (<React.Fragment>
            {data.map((dealerInfoData: DealerInfoData, index: number) => {
              return (
                <React.Fragment key={`mapbox_marker_fragment_${index}`}>
                  {(dealerInfoData && dealerInfoData.longitude && dealerInfoData.latitude) ?
                    <div key={`mapbox_marker_div_${index}`}>
                      {this.renderDealerMarker(dealerInfoData, index)}                      
                      {this.renderDealerPopup(dealerInfoData, index)}
                    </div>
                    : ''}
                </React.Fragment>

              );
            })} </React.Fragment>) : ''}

          <div className="nav" style={navStyleRight}>            
            <NavigationControl onViewportChange={this.updateViewport} />
            { !dealerSearchView2 && viewName !== Commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW2.toString() && 
              <React.Fragment>
              <Anchor action={this.favoriteDealersList} className="m-mapBox__favoriteIcon">
                <span className={isFavoriteDealersList ? 'active' : ''} >{isFavoriteDealersList ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i> }</span>
              </Anchor>          
              <Anchor action={this.userNearByDealersList} className="m-mapBox__navigationIcon">
                <span className={isUserCurrentLocation ? 'active' : ''}  >{isUserCurrentLocation ?  <i className="fa fa-location-arrow" aria-hidden="true"></i> : <i className="fa fa-location-arrow" aria-hidden="true"></i> }</span>
              </Anchor>  
              </React.Fragment>
            }          
          </div>
        </ReactMapGL> 
       
      </MapBoxStyle.div>
    );
  }
}
export { MapBox };
export default GlobalSettingsConatiner(SquidexContentConatiner(DealerLocatorConatiner(MapBox)));
