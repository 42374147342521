import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';

export class SliderComponent extends Component<SliderComponentProps, SliderComponentState> {

  render() {
    let { min, max, value, step, onChange, onAfterChange } = this.props
    return (<StyledSlider min={min} max={max} onAfterChange={e => onAfterChange(e)} onChange={e => onChange(e)} step={step} value={value} />)
  }
}

interface SliderComponentProps {
  value?: number,
  min?: number,
  max?: number,
  step?: number,
  onChange: (e: any) => any,
  onAfterChange: (e: any) => any
}

interface SliderComponentState {

}

const blue = '#1351B5'
const StyledSlider = styled(Slider)`
& {
  position: relative;
  touch-action: none;
  margin: 12px 0 15px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
}
& * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  width: 100%;
  background-color: #D0D0D0;
  height: 3.5px;
  border-radius: 5px;
  cursor: grabbing;
}
.rc-slider-track {
  position: absolute;
  left: 5px;
  top: 0;
  height: 3.5px;
  border-radius: 5px;
  background-color: ${blue};
  cursor: grabbing;
}
.rc-slider-handle {  
  position: absolute;
  width: 22px;
  height: 22px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -12px;
  cursor: grab;
  border-radius: 50%;
  border: 2px solid ${blue};
  background-color: #ffffff;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  box-shadow: 0 0 5px ${blue}, 0 2px 5px rgba(0, 0, 0, 0.35);
  outline: none;
  cursor: grabbing;
}
.rc-slider-handle-click-focused:focus {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
  cursor: grabbing;
}
.rc-slider-handle:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.rc-slider-handle:active {
  box-shadow: 0 0 5px ${blue}, 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: grabbing;
}
`;
