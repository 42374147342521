import actionTypes from "../../constants/actionTypes";
import Commons from "../../constants/commons";
import { getURLParamValue, isDualBrandedData } from "../../utils/commons";

export interface LastSearchObjectType {
  type:string,
  dealerId?: number,
  dealerName?: string,
  locationName?:string,
  locationLatitude?:string,
  locationLongitude?:string,
  searchText?:string,
}


/**
 * Generic method to get value from local storage based on the key passed
 */
export const getValueFromLocalStorage = (
  props: any,
  key: string,
  value: any
) => {
  let flag: boolean = false;
  key = updateLocalStorageKey(props, key);
  let values = props.globalSettings.localStorageData[key] || [];
  if (values && values.length > 0) {
    const index = values.indexOf(value);
    if (index > -1) {
      flag = true;
    }
  }
  return flag;
};

/**
 * Generic method to add value to local storage based on the key passed
 */
export const setValueToLocalStorage = (props: any, key: string, value: any) => {
  if(value){
    key = updateLocalStorageKey(props, key);
    let values = props.globalSettings.localStorageData[key] || [];
    if (values && values.length > 0) {
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(value);
      }
    } else {
      values.push(value);
    }

    props.setLocalStorage(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_LOCAL_STORAGE.toString(),
      key,
      values
    );
  }
};

/**
 * Generic method to get value from local storage based on the key passed
 */
export const getLastLocationSearchFromLocalStorage = (
  props: any,
  key: string
) => {
  key = updateLocalStorageKey(props, key);
  let values: Array<LastSearchObjectType> = props.globalSettings.localStorageData[key] || [];
  if (values && values.length > 0) {
    const array = [...values];
    return array.reverse();
  }
  return values;
};

/**
 * Generic method to add value to local storage based on the key passed
 */
export const setLastLocationSearchToLocalStorage = (
  props: any,
  key: string,
  value: LastSearchObjectType
) => {
    key = updateLocalStorageKey(props, key);
    let values = props.globalSettings.localStorageData[key] || [];
    if (values && values.length > 0) {
      if (values.length < 3) {
        values.push(value);
      } else {
        values.splice(0, 1);
        values.push(value);
      }
    } else {
      values.push(value);
    }

    props.setLocalStorage(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_LOCAL_STORAGE.toString(),
      key,
      values
    );
 
};


/**
 * Update local storage key for bus and truck localtor
 */
export const updateLocalStorageKey = (props: any, key: string) => {
  const { isBusLocator } = props.globalSettings;
  key = isBusLocator === true ? key + "Bus": key;
    
  return key;
};




/**
 * Method to get the site language
 */
export const getCountryCode = (props: any) => {
  let languageAndMarket = getLanguageAndMarket(props);
  let countryCode = "";
  if (languageAndMarket) {
    countryCode = languageAndMarket.substring(languageAndMarket.length - 2);
  }

  return countryCode;
};

/**
 * Method to get the site languageAndMarket
 */
export const getLanguageAndMarket = (props: any) => {
  let languageMarket = "";
  if (getURLParamValue("lang") && getURLParamValue("market")) {
    languageMarket = ''+getURLParamValue("lang") + getURLParamValue("market");
  } else {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      let lang = baseDoc.getAttribute('data-lang') || '';
      let market = baseDoc.getAttribute('data-market') || '';
      languageMarket = lang + market;
    }
  }
  languageMarket = languageMarket || (window as any).lang + (window as any).market || "enGB";
  return languageMarket;
};

/**
 * Method to get the maindealerid
 */
export const getMainDealerId = (props: any) => {
  let maindealerid = getURLParamValue("maindealerid") || undefined;
  if(!maindealerid) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      maindealerid = baseDoc.getAttribute('data-maindealerid') || undefined;
    }
  }
  maindealerid = maindealerid || (window as any).maindealerid || undefined;
  return maindealerid;
};

/**
 * Method to get the maindealerid
 */
 export const getPreSelectedServices = (props: any) => {
  let selectedServices = getURLParamValue("preselectedservices") || undefined;
  if(!selectedServices) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      selectedServices = baseDoc.getAttribute('data-preSelectedServices') || undefined;
    }
  }
  selectedServices = selectedServices || (window as any).selectedservices || undefined;
  return selectedServices?.split(',') || [];
};


/**
 * Method to get a list of dealers ignored in the map viewport
 */
 export const getDistantDealers = (props: any) => {
  let distantdealers = getURLParamValue("distantdealers") || undefined;
  if(!distantdealers) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      distantdealers = baseDoc.getAttribute('data-distantdealers') || undefined;
    }
  }
  distantdealers = distantdealers || (window as any).distantdealers || undefined;
  var distantDealersList = distantdealers?.split(',') || []; 
  return distantDealersList;
};

/**
 * Method to get the maindealerid
 */
 export const getOrganizationId = (props: any) => {
  let organizationid = getURLParamValue("organizationid") || undefined;
  if(!organizationid) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      organizationid = baseDoc.getAttribute('data-organizationid') || undefined;
    }
  }
  organizationid = organizationid || (window as any).organizationid || undefined;
  return organizationid;
};

/**
 * Method to get the site dealerid
 */
export const getDealerId = (props: any) => {
  let dealerid = getURLParamValue("dealerid") || undefined;
  if(!dealerid) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      dealerid = baseDoc.getAttribute('data-dealerid') || undefined;
    }
  }
  dealerid = dealerid || (window as any).dealerid || undefined;
  return dealerid;
};


let propsObject: any;

/** Methods to delermine the user current location based
 * on the browser navigation controle if user allow to access the location then fetch the dealers data based on the
 * user latitude and longitude to determine dealer distance from user current location other wise distnace will
 *  not be returned from API
 *  */

export const getInitialDealersList = (dealerLocatorObj: any, triggered: boolean) => {
  propsObject = dealerLocatorObj.props;
  const languageAndMarket = getLanguageAndMarket(propsObject);
  const countryCode = getCountryCode(propsObject); 
  const ignoreLocation = getIgnoreLocationFlag(propsObject); 
  const { squidexContentsList } = dealerLocatorObj.props;

  if(!triggered && ignoreLocation) {
    dealerLocatorObj.setState({ searchQueryText: "" });
    initialDealersListFallback(propsObject, languageAndMarket, countryCode);
  } else if ("geolocation" in navigator) {
    // check if geolocation is supported/enabled on current browser
    navigator.geolocation.getCurrentPosition(
     function success(position) {
        const { globalSettings } = propsObject;
        const { filterSelectedValue, locationPinData } = globalSettings;
        const crd = position.coords;
        /** Set user location co-ordinates to global props */
        propsObject.setUserCurrentLocation(
          actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_USER_LOCATION.toString(),
          crd.latitude,
          crd.longitude,
          ""
        );
        /** set location pin data to user current co-ordinate sto show user pin */ 
        if (crd.latitude && crd.longitude) {
          locationPinData.coordinates = [
          [crd.latitude, crd.longitude]
          ];
        } else {
            locationPinData.coordinates = [];
        }
        /** update search latitude/longitude with user co-ordinates */
        filterSelectedValue.selectedhLatitude = crd.latitude;
        filterSelectedValue.selectedLongitude =  crd.longitude;
        propsObject.setGlobalSettingsState(
          actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_DATA.toString(),
          globalSettings
        );
        dealerLocatorObj.setState({ searchQueryText: squidexContentsList.yourCurrentLocationText });
        dealerLocatorObj.setState({ isUserCurrentLocation: true });
        /** filter the dealer response based on co-ordinates */
        getFilteredDealersListByLatitudeLongitude(propsObject, ""+crd.latitude,  ""+crd.longitude, languageAndMarket, countryCode);
     },
    function error(error) {      
      dealerLocatorObj.setState({ searchQueryText: "" });
      switch(error.code) {
        case error.PERMISSION_DENIED:
        console.warn(
          `ERROR:(${error.code}): ${
            error.message
          } Fetch dealers using initialDealersListFallback ...`
        );
        initialDealersListFallback(propsObject, languageAndMarket, countryCode);
        break;
        default:
        console.warn(
          `ERROR:(${error.code}): ${
            error.message
          } Fetch dealers based on country code ...`
        );
        getDealersListBasedOnCountryCode(propsObject);
        break;
      }
    });
  } else {
    console.warn(
      `geolocation is not enabled on this browser fetch dealers using initialDealersListFallback ...`
    );
    dealerLocatorObj.setState({ searchQueryText: "" });
    initialDealersListFallback(propsObject, languageAndMarket, countryCode);
  }
};

/** Methods to fetch the dealer list based on 
 * if market code is there in the URL then fetch the dealers based on the market code
 * if market code is not there in the URL then fetch the dealers based on 
 *  VTC HQs locator in Göteborg 57.7126311,11.9230829 as the default location
 *  */
export const initialDealersListFallback = (props: any, languageAndMarket: string, countryCode: string) => {
  if (countryCode) {
    console.warn(
      `getting dealers based on market code  ... `+ countryCode
    );
    getDealersListBasedOnCountryCode(propsObject);
  } else {
    console.warn(
      `getting dealers based on Volvo HQ Lat/Lng  ... `+ Commons.VTC_HQ_LATITUDE + ' , '+ Commons.VTC_HQ_LONGITUDE
    );
    getFilteredDealersListByLatitudeLongitude(propsObject, "" + Commons.VTC_HQ_LATITUDE,
      "" + Commons.VTC_HQ_LONGITUDE, languageAndMarket, countryCode);
  }
}


/** Fetch dealres based on country code */
export const getDealersListBasedOnCountryCode = (props: any) => {
  propsObject = props;
  const languageAndMarket = getLanguageAndMarket(propsObject);
  let countryCode = getCountryCode(propsObject);
  let isBusLocator = getBusLocatorFlag(propsObject);

  const { globalSettings } = props;
  const { filterSelectedValue } = globalSettings;
  
  const { classTypes } = globalSettings.filtersDefaultValue;
  const classValue =
    filterSelectedValue.onlyDealerService &&
    filterSelectedValue.onlyWorkShopService
      ? classTypes.bothDealerAndWorkshop
      : filterSelectedValue.onlyDealerService
      ? classTypes.onlyDealer
      : filterSelectedValue.onlyWorkShopService
      ? classTypes.onlyWorkshop
      : "";

  propsObject.getDealersList({
    dealersListInput: {
      languageMarket: languageAndMarket,
      filterQueryCriteria: {
        range: filterSelectedValue.selectedRange,
        class: classValue,
        services: filterSelectedValue.selectedServices,
        countryCode: countryCode
      },
      isBusLocator: isBusLocator,
      isDualBranded: isDualBrandedData()
    }
  });
};

/** get the dealers list based on the latitide longitude and added filter */

export const getFilteredDealersListByLatitudeLongitude = (
  props: any,
  latitude: string,
  longitude: string,
  languageMarket: string,
  countryCode: string,
) => {  
  const { globalSettings } = props;
  const { filterSelectedValue } = globalSettings;
  const { classTypes } = globalSettings.filtersDefaultValue;
  let isBusLocator = getBusLocatorFlag(propsObject);
  
  const classValue =
    filterSelectedValue.onlyDealerService &&
    filterSelectedValue.onlyWorkShopService
      ? classTypes.bothDealerAndWorkshop
      : filterSelectedValue.onlyDealerService
      ? classTypes.onlyDealer
      : filterSelectedValue.onlyWorkShopService
      ? classTypes.onlyWorkshop
      : "";
  
  props.getDealersList({
    dealersListInput: {
      languageMarket: languageMarket,
      filterQueryCriteria: {
        latitude: latitude,
        longitude: longitude,
        range: filterSelectedValue.selectedRange,
        class: classValue,
        services: filterSelectedValue.selectedServices,
        countryCode: countryCode
      },  
      isBusLocator: isBusLocator,  
      isDualBranded: isDualBrandedData()  
    }
  });
};

/**
 * If businessAreaCode value is 1 then it will be truck locator and the bus locator flag will be false in that case
 * but if the businessAreaCode is 2 then bus locator flag will be true
 */
export const getBusLocatorFlag = (props: any) => {
  let isBusLocator: boolean = false;
  let isBusLocatorParam:string = (getURLParamValue("isBusLocator") || "");
  if(!isBusLocatorParam) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      isBusLocatorParam = baseDoc.getAttribute('data-isBusLocator') || "";
    }
  }
  isBusLocatorParam = isBusLocatorParam || (window as any).isBusLocator || "false";
  
  if (isBusLocatorParam.toString().toLowerCase() === "true") {
    isBusLocator = true;
  }

  return isBusLocator;

  /* let isBusLocator: boolean = false;
   let isBusLocatorParam:string = (getURLParamValue("isBusLocator") || (window as any).isBusLocator || "false");
   
   if (isBusLocatorParam.toString().toLowerCase() === "true") {
     isBusLocator = true;
   }
 
   return isBusLocator;*/
};

/**
 * If isTruckConfigurator value is true then it will be truck configurator flow
 */
export const getTruckConfiguratorFlag = (props: any) => {
  let isTruckConfigurator: boolean = false;
  let isTruckConfiguratorParam:string = (getURLParamValue("isTruckConfigurator") || "");
  if(!isTruckConfiguratorParam) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      isTruckConfiguratorParam = baseDoc.getAttribute('data-isTruckConfigurator') || "";
    }
  }
  isTruckConfiguratorParam = isTruckConfiguratorParam || (window as any).isTruckLocator || "false";
  
  if (isTruckConfiguratorParam.toString().toLowerCase() === "true") {
    isTruckConfigurator = true;
  }

  return isTruckConfigurator;
/*
  let isTruckConfigurator: boolean = false;
  let isTruckConfiguratorParam:string = (getURLParamValue("isTruckConfigurator") || (window as any).isTruckLocator || "false");
  if (isTruckConfiguratorParam.toString().toLowerCase() === "true") {
    isTruckConfigurator = true;
  }

  return isTruckConfigurator;*/
};

export const getIgnoreLocationFlag = (props: any) => {
  let ignoreLocation: boolean = false;
  let ignoreLocationParam:string = (getURLParamValue("ignoreLocation") || "");
  if(!ignoreLocationParam) {
    let baseDoc = document.getElementById('dealerlocator-root');
    if(baseDoc != null)
    {
      ignoreLocationParam = baseDoc.getAttribute('data-ignoreLocation') || "";
    }
  }
  ignoreLocationParam = ignoreLocationParam || (window as any).ignoreLocation || "false";
  
  if (ignoreLocationParam.toString().toLowerCase() === "true") {
    ignoreLocation = true;
  }

  return ignoreLocation;
/*
  let ignoreLocation: boolean = false;
  let ignoreLocationParam:string = (getURLParamValue("ignoreLocation") || (window as any).ignoreLocation || "false");
  if (ignoreLocationParam.toString().toLowerCase() === "true") {
    ignoreLocation = true;
  }

  return ignoreLocation;
  */
};
