
    export enum DEALER_LOCATOR_ACTIONS {
        GET_DEALER_LOCATOR_DATA = "GET_DEALER_LOCATOR_DATA" as any,
        GET_DEALER_LOCATOR_DATA_REQUEST = "GET_DEALER_LOCATOR_DATA_REQUEST" as any,
        GET_DEALER_LOCATOR_DATA_FAILURE = "GET_DEALER_LOCATOR_DATA_FAILURE" as any,
        GET_DEALER_LOCATOR_DATA_SUCCESS = "GET_DEALER_LOCATOR_DATA_SUCCESS" as any,
        
        GET_DEALER_DETAILS_DATA = "GET_DEALER_DETAILS_DATA" as any,
        GET_DEALER_LOCATOR_DATA_WITH_QUERY = "GET_DEALER_LOCATOR_DATA_WITH_QUERY" as any,
        UPDATE_DEALER_LOCATOR_DATA = "UPDATE_DEALER_LOCATOR_DATA" as any,

        GET_DEALER_LOCATOR_SEARCH_DATA_REQUEST = "GET_DEALER_LOCATOR_SEARCH_DATA_REQUEST" as any,
        GET_DEALER_LOCATOR_SEARCH_DATA_FAILURE = "GET_DEALER_LOCATOR_SEARCH_DATA_FAILURE" as any,
        GET_DEALER_LOCATOR_SEARCH_DATA_SUCCESS = "GET_DEALER_LOCATOR_SEARCH_DATA_SUCCESS" as any,

        GET_FAVORITE_DEALER_LOCATOR_DATA_REQUEST = "GET_FAVORITE_DEALER_LOCATOR_DATA_REQUEST" as any,
        GET_FAVORITE_DEALER_LOCATOR_DATA_FAILURE = "GET_FAVORITE_DEALER_LOCATOR_DATA_FAILURE" as any,
        GET_FAVORITE_DEALER_LOCATOR_DATA_SUCCESS = "GET_FAVORITE_DEALER_LOCATOR_DATA_SUCCESS" as any,

        GET_DEALER_LOCATOR_CTDI_ID_DATA_REQUEST = "GET_DEALER_LOCATOR_CTDI_ID_DATA_REQUEST" as any,
        GET_DEALER_LOCATOR_CTDI_ID_DATA_FAILURE = "GET_DEALER_LOCATOR_CTDI_ID_DATA_FAILURE" as any,
        GET_DEALER_LOCATOR_CTDI_ID_DATA_SUCCESS = "GET_DEALER_LOCATOR_CTDI_ID_DATA_SUCCESS" as any,
    }

    export enum DEALER_LOCATOR_DETAILS_ACTIONS {
        GET_DEALER_LOCATOR_DEATILS_DATA_REQUEST = "GET_DEALER_LOCATOR_DEATILS_DATA_REQUEST" as any,
        GET_DEALER_LOCATOR_DEATILS_DATA_FAILURE = "GET_DEALER_LOCATOR_DEATILS_DATA_FAILURE" as any,
        GET_DEALER_LOCATOR_DEATILS_DATA_SUCCESS = "GET_DEALER_LOCATOR_DEATILS_DATA_SUCCESS" as any,
    }

    export enum GLOBAL_SETTINGS_ACTIONS {
        UPDATE_GLOBAL_SETTINGS_DATA = "UPDATE_GLOBAL_SETTINGS_DATA" as any,
        SET_VIEW_NAME = "SET_VIEW_NAME" as any,
        SET_GLOBAL_SETTINGS_DATA = "SET_GLOBAL_SETTINGS_DATA" as any,
        SET_GLOBAL_SETTINGS_LANGUAGE_MARKET = "SET_GLOBAL_SETTINGS_LANGUAGE_MARKET" as any,
        SET_GLOBAL_SETTINGS_COUNTRYCODE = "SET_GLOBAL_SETTINGS_COUNTRYCODE" as any,
        SET_GLOBAL_SETTINGS_USER_LOCATION = "SET_GLOBAL_SETTINGS_USER_LOCATION" as any,
        SET_GLOBAL_SETTINGS_BUS_LOCATOR = "SET_GLOBAL_SETTINGS_BUS_LOCATOR" as any,
        SET_GLOBAL_SETTINGS_TRUCK_CONFIGURATOR = "SET_GLOBAL_SETTINGS_TRUCK_CONFIGURATOR" as any,
        SET_LOCAL_STORAGE = "SET_LOCAL_STORAGE" as any,
        GET_LOCAL_STORAGE = "GET_LOCAL_STORAGE" as any,
    }

    export enum LOCATIONIQ_ACTIONS {
        GET_AUTOCOMPLETE_DATA_REQUEST = "GET_AUTOCOMPLETE_DATA_REQUEST" as any,
        GET_AUTOCOMPLETE_DATA_FAILURE = "GET_AUTOCOMPLETE_DATA_FAILURE" as any,
        GET_AUTOCOMPLETE_DATA_SUCCESS = "GET_AUTOCOMPLETE_DATA_SUCCESS" as any,
        GET_REVERSE_GEOLOCATION_DATA_REQUEST = "GET_REVERSE_GEOLOCATION_DATA_REQUEST" as any,
        GET_REVERSE_GEOLOCATION_DATA_FAILURE = "GET_REVERSE_GEOLOCATION_DATA_FAILURE" as any,
        GET_REVERSE_GEOLOCATION_DATA_SUCCESS = "GET_REVERSE_GEOLOCATION_DATA_SUCCESS" as any,


    }

    export enum SQUIDEX_CONTENT_ACTIONS {
        GET_SQUIDEX_CONTENT_DATA_REQUEST = "GET_SQUIDEX_CONTENT_DATA_REQUEST" as any,
        GET_SQUIDEX_CONTENT_DATA_FAILURE = "GET_SQUIDEX_CONTENT_DATA_FAILURE" as any,
        GET_SQUIDEX_CONTENT_DATA_SUCCESS = "GET_SQUIDEX_CONTENT_DATA_SUCCESS" as any,
    }

    export enum DEALER_LOCATOR_SERVICES_ACTIONS {
        GET_DEALER_LOCATOR_SERVICES_DATA_REQUEST = "GET_DEALER_LOCATOR_SERVICES_DATA_REQUEST" as any,
        GET_DEALER_LOCATOR_SERVICES_DATA_FAILURE = "GET_DEALER_LOCATOR_SERVICES_DATA_FAILURE" as any,
        GET_DEALER_LOCATOR_SERVICES_DATA_SUCCESS = "GET_DEALER_LOCATOR_SERVICES_DATA_SUCCESS" as any,
    }

    export enum ERROR_ACTIONS {
        Unknown = "Unknown" as any,
        
    }


export const  ActionTypes = {
    DEALER_LOCATOR_ACTIONS,
    GLOBAL_SETTINGS_ACTIONS,
    DEALER_LOCATOR_DETAILS_ACTIONS:DEALER_LOCATOR_DETAILS_ACTIONS,
    ERROR_ACTIONS,
    LOCATIONIQ_ACTIONS,
    SQUIDEX_CONTENT_ACTIONS,
    DEALER_LOCATOR_SERVICES_ACTIONS
};

export  default  ActionTypes ;
