
import React, { Component } from 'react';
import OffScreenElementStyle from './OffScreenElement.style';

interface Props  {
    className?:string,    
    childClassName?: string,    
    offScreenId?:string,
    children: any,
    isOffScreenElementOpen: boolean,
    toggleOffScreenElement: () => void ,
  };
  
  interface State {
    clicked: boolean;
  }


class OffScreenElement extends Component<Props, State> {
 
  constructor(props: Props) {
    super(props);
    this.addBodyClassFunction = this.addBodyClassFunction.bind(this);
  }

  componentDidMount() {
    const { isOffScreenElementOpen } = this.props;
    this.addBodyClassFunction(isOffScreenElementOpen);
  }
     
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps && nextProps.isOffScreenElementOpen) {
      if (!this.props.isOffScreenElementOpen || JSON.stringify(nextProps.isOffScreenElementOpen) !== JSON.stringify(this.props.isOffScreenElementOpen)) {
        this.addBodyClassFunction(nextProps.isOffScreenElementOpen);
      }
    }
  }
     
  addBodyClassFunction(isNavOpen:boolean) {
    if(isNavOpen === true) {      
      document.body.classList.add('offScreenActive');
    } 
    
  }

  render() {
    const {
      className,
      isOffScreenElementOpen,
      children,
      toggleOffScreenElement,
      offScreenId,
      childClassName,
    } = this.props;

    const contentclass = `${childClassName} o-offScreen ${isOffScreenElementOpen ? 'isOpen' : ''}`;
 
    return (
        <OffScreenElementStyle.div className={`${className} o-offScreen__wrapper`} aria-expanded={isOffScreenElementOpen}>
        <div className={contentclass} aria-hidden={!isOffScreenElementOpen} id={offScreenId}>
          {/* content */}
          <div className="o-offScreen__content">
            {children}
          </div>
          {/* overlay */}
          <div className="o-offScreen__overlay" aria-hidden={!isOffScreenElementOpen} onClick={x => { this.addBodyClassFunction(!isOffScreenElementOpen);toggleOffScreenElement()}}  />
        </div>
      </OffScreenElementStyle.div>
    );
  }
}

export default OffScreenElement;


