// import { Reducer } from "redux";
import {
  DealersListResponse,
  DealerDetailsListResponse,
  ServicesResponse,
  DealersLocationListResponse
} from "../Models/types";
import { makeAsyncActionCreator } from "../makeAsyncActionCreator";
import { GlobalState } from "..";
import actionTypes from "../../constants/actionTypes";
import apiEndpoints from "../../constants/apiEndpoints";
import queryParams from "../../constants/queryParams";
import commons from "../../constants/commons";
import { getNumber } from "../../utils/commons";

export interface DealersListActionType {
  type?: string;
  languageMarket: string;
  filterQueryCriteria?: FilterQueryCriteria;
  searchQueryText?: string;
  dealerIds?: string;
  returnedDealerId?: string;
  limit?: number | 0;
  isBusLocator?: boolean | false;
  isDualBranded?: boolean | false;
}

export interface FilterQueryCriteria {
  latitude?: string;
  longitude?: string;
  range?: string;
  class?: string;
  countryCode?: string;
  mainDealerId?: string;
  organizationId?: string;
  ctdiId?: string;
  services?: number[];
}

export interface DealerDetailsActionType {
  languageMarket: string;
  dealerId?: number;
  isBusLocator?: boolean | false;
}

export interface DealersListInput {
  dealersListInput: DealersListActionType;
}

export interface DealerDetailsInput {
  dealerDetailsInput: DealerDetailsActionType;
}

export interface ServicesListActionType {
  languageMarket: string;
}

export interface ServicesListInput {
  servicesListInput: ServicesListActionType;
}

/**
 * Method to fetch a dealer list based on the search criteria
 *
 *
 */
export const actionCreatorDealersList = makeAsyncActionCreator<
  DealersListInput,
  DealersListResponse
>()({
  requestType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_DATA_REQUEST.toString(),
  failureType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_DATA_FAILURE.toString(),
  successType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_DATA_SUCCESS.toString(),
  getSection: (state: GlobalState) => state.dealersList,
  inputToHttpRequest: (input: DealersListInput) => {
    let queryCriteria = "";

    if (input.dealersListInput.filterQueryCriteria) {
      if (input.dealersListInput.filterQueryCriteria.range) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.RADIUS
        }${commons.EQUALS}${input.dealersListInput.filterQueryCriteria.range}`;
      }
      if (input.dealersListInput.filterQueryCriteria.latitude) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.LATITUDE
        }${commons.EQUALS}${
          input.dealersListInput.filterQueryCriteria.latitude
        }`;
      }
      if (input.dealersListInput.filterQueryCriteria.longitude) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.LONGITUDE
        }${commons.EQUALS}${
          input.dealersListInput.filterQueryCriteria.longitude
        }`;
      }
      if (input.dealersListInput.filterQueryCriteria.class) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.CLASSES
        }${commons.EQUALS}${input.dealersListInput.filterQueryCriteria.class}`;
      }
      if (
        input.dealersListInput.filterQueryCriteria.services &&
        input.dealersListInput.filterQueryCriteria.services.length > 0
      ) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.SERVICES
        }${
          commons.EQUALS
        }${input.dealersListInput.filterQueryCriteria.services.join()}`;
      }
      // Fallback pass the country code only when the latitude and longitude is not there...
      if (
        input.dealersListInput.filterQueryCriteria.countryCode &&
        !input.dealersListInput.filterQueryCriteria.latitude &&
        !input.dealersListInput.filterQueryCriteria.longitude
      ) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.SEARCH_COUNTRY_CODE
        }${input.dealersListInput.filterQueryCriteria.countryCode}`;
      }
      if (input.dealersListInput.filterQueryCriteria.mainDealerId) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.SEARCH_MAIN_DEALER_ID
        }${input.dealersListInput.filterQueryCriteria.mainDealerId}`;
      }
      if (input.dealersListInput.filterQueryCriteria.organizationId && 
        getNumber(input.dealersListInput.filterQueryCriteria.organizationId) > 0) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.SEARCH_ORGANIZATION_ID
        }${input.dealersListInput.filterQueryCriteria.organizationId}`;
      }
    }

    if (input.dealersListInput.searchQueryText) {
      queryCriteria += `${commons.AMPERSAND}${
        queryParams.DEALERS_FILTER_QUERY.CITY_OR_NAME
      }${commons.EQUALS}${input.dealersListInput.searchQueryText}`;
    }

    if (input.dealersListInput.isBusLocator) {
      queryCriteria += `${commons.AMPERSAND}${
        queryParams.BUSINESS_AREA_CODE
      }${commons.EQUALS}2`;
    } 

    
    if (input.dealersListInput.isDualBranded) {
      queryCriteria += `${commons.AMPERSAND}${
        queryParams.IS_DUAL_BRANDED
      }${commons.EQUALS}${input.dealersListInput.isDualBranded}`;
    } 

    queryCriteria += `${commons.AMPERSAND}${
      queryParams.DEALERS_FILTER_QUERY.INITIAL_LOAD
    }${commons.EQUALS}true`;
    

    queryCriteria += `${commons.AMPERSAND}${queryParams.LIMIT}${
      commons.EQUALS
    }${commons.NUMBER_THOUSANDS}`;
    return {
      url:
        apiEndpoints.DEALER_API_DOMAIN +
        apiEndpoints.DEALER_API_ENDPOINTS.GET_DEALERS_LIST +
        "?" +
        encodeURI(
          `lang=${input.dealersListInput.languageMarket}${queryCriteria}`
        ),
      request: {}
    };
  },
  getInputKey: (input: DealersListInput) => "GetDealersList-" + Date.now(),
  successHandler: (
    input: DealersListInput,
    dalerData: DealersListResponse
  ) => {},
  shouldDebounce: true
});

/**
 * Method to fetch a favorite dealers list
 *
 *
 */
export const actionCreatorFavoriteDealersList = makeAsyncActionCreator<
  DealersListInput,
  DealersListResponse
>()({
  requestType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_FAVORITE_DEALER_LOCATOR_DATA_REQUEST.toString(),
  failureType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_FAVORITE_DEALER_LOCATOR_DATA_FAILURE.toString(),
  successType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_FAVORITE_DEALER_LOCATOR_DATA_SUCCESS.toString(),
  getSection: (state: GlobalState) => state.dealersList,
  inputToHttpRequest: (input: DealersListInput) => {
    let queryCriteria = "";
    if (input.dealersListInput.dealerIds) {
      queryCriteria += `${commons.AMPERSAND}${
        queryParams.DEALERS_FILTER_QUERY.DEALER_IDS
      }${commons.EQUALS}${input.dealersListInput.dealerIds}`;
    }
    queryCriteria += `${commons.AMPERSAND}${queryParams.LIMIT}${
      commons.EQUALS
    }${commons.NUMBER_THOUSANDS}`;
    return {
      url:
        apiEndpoints.DEALER_API_DOMAIN +
        apiEndpoints.DEALER_API_ENDPOINTS.GET_DEALERS_LIST +
        "?" +
        encodeURI(
          `lang=${input.dealersListInput.languageMarket}${queryCriteria}`
        ),
      request: {}
    };
  },
  getInputKey: (input: DealersListInput) =>
    "GetFavoriteDealersList-" + Date.now(),
  successHandler: (
    input: DealersListInput,
    dalerData: DealersListResponse
  ) => {}
});

/**
 * Method to fetch a dealer list based on the search criteria
 *
 *
 */
export const actionCreatorDealersListWithSearchQuery = makeAsyncActionCreator<
  DealersListInput,
  DealersLocationListResponse
>()({
  requestType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_SEARCH_DATA_REQUEST.toString(),
  failureType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_SEARCH_DATA_FAILURE.toString(),
  successType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_SEARCH_DATA_SUCCESS.toString(),
  getSection: (state: GlobalState) => state.dealersList,
  inputToHttpRequest: (input: DealersListInput) => {
    let queryCriteria = "";
    if (
      input.dealersListInput.searchQueryText &&
      input.dealersListInput.searchQueryText.length > 0
    ) {
      queryCriteria += `${commons.AMPERSAND}${queryParams.QUERY_TEXT.QUERY}${
        commons.EQUALS
      }${input.dealersListInput.searchQueryText}`;
    }
    if (input.dealersListInput.isBusLocator) {
      queryCriteria += `${commons.AMPERSAND}${
        queryParams.BUSINESS_AREA_CODE
      }${commons.EQUALS}2`;
    } 
    if (input.dealersListInput.limit && input.dealersListInput.limit > 0) {
      queryCriteria += `${commons.AMPERSAND}${queryParams.LIMIT}${
        commons.EQUALS
      }${input.dealersListInput.limit}`;
    }
    return {
      url:
        apiEndpoints.DEALER_API_DOMAIN +
        apiEndpoints.DEALER_API_ENDPOINTS.GET_DEALERS_LIST_WITH_QUERY +
        "?" +
        encodeURI(
          `lang=${input.dealersListInput.languageMarket}${queryCriteria}`
        ),
      request: {}
    };
  },
  getInputKey: (input: DealersListInput) =>
    "GetDealersListWithSearchQuery -" + Date.now(),
  successHandler: (
    input: DealersListInput,
    dalerData: DealersLocationListResponse
  ) => {}
});

/**
 * Method to fetch a dealer details based on dealer id
 * to show dealer details on details page
 *
 */
export const actionCreatorDealerDetails = makeAsyncActionCreator<
  DealerDetailsInput,
  DealerDetailsListResponse
>()({
  requestType: actionTypes.DEALER_LOCATOR_DETAILS_ACTIONS.GET_DEALER_LOCATOR_DEATILS_DATA_REQUEST.toString(),
  failureType: actionTypes.DEALER_LOCATOR_DETAILS_ACTIONS.GET_DEALER_LOCATOR_DEATILS_DATA_FAILURE.toString(),
  successType: actionTypes.DEALER_LOCATOR_DETAILS_ACTIONS.GET_DEALER_LOCATOR_DEATILS_DATA_SUCCESS.toString(),
  getSection: (state: GlobalState) => state.dealerDetailsList,
  inputToHttpRequest: (input: DealerDetailsInput) => {
    let queryCriteria = "";
    if (input.dealerDetailsInput.isBusLocator) {
      queryCriteria += `${commons.AMPERSAND}${
        queryParams.BUSINESS_AREA_CODE
      }${commons.EQUALS}2`;
    } 
    return {
      url:
        apiEndpoints.DEALER_API_DOMAIN +
        apiEndpoints.DEALER_API_ENDPOINTS.GET_DEALER_DETAILS +
        `/${input.dealerDetailsInput.dealerId}?` +
        encodeURI(`lang=${input.dealerDetailsInput.languageMarket}${queryCriteria}`),
      request: {}
    };
  },
  getInputKey: (input: DealerDetailsInput) => "GetDealerDetails-" + Date.now(),
  successHandler: (
    input: DealerDetailsInput,
    dalerData: DealerDetailsListResponse
  ) => {}
});

/**
 * Method to fetch all the dealer services
 * to show them on filter page
 *
 */
export const actionCreatorServicesList = makeAsyncActionCreator<
  ServicesListInput,
  ServicesResponse
>()({
  requestType: actionTypes.DEALER_LOCATOR_SERVICES_ACTIONS.GET_DEALER_LOCATOR_SERVICES_DATA_REQUEST.toString(),
  failureType: actionTypes.DEALER_LOCATOR_SERVICES_ACTIONS.GET_DEALER_LOCATOR_SERVICES_DATA_FAILURE.toString(),
  successType: actionTypes.DEALER_LOCATOR_SERVICES_ACTIONS.GET_DEALER_LOCATOR_SERVICES_DATA_SUCCESS.toString(),
  getSection: (state: GlobalState) => state.servicesList,
  inputToHttpRequest: (input: ServicesListInput) => {
    return {
      url:
        `${apiEndpoints.DEALER_API_DOMAIN}${
          apiEndpoints.DEALER_API_ENDPOINTS.GET_DEALER_SERVICES
        }?` + encodeURI(`lang=${input.servicesListInput.languageMarket}`),
      request: {}
    };
  },
  getInputKey: (input: ServicesListInput) => "GetServicesList-" + Date.now(),
  successHandler: (
    input: ServicesListInput,
    servicesData: ServicesResponse
  ) => {}
});

/**
 * Method to fetch a dealer details based on dealer id
 * to show dealer details on details page
 *
 */
export const actionCreatorDealersListForCtdiId = makeAsyncActionCreator<
  DealersListInput,
  DealersListResponse
>()({
  requestType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_CTDI_ID_DATA_REQUEST.toString(),
  failureType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_CTDI_ID_DATA_FAILURE.toString(),
  successType: actionTypes.DEALER_LOCATOR_ACTIONS.GET_DEALER_LOCATOR_CTDI_ID_DATA_SUCCESS.toString(),
  getSection: (state: GlobalState) => state.dealersList,
  inputToHttpRequest: (input: DealersListInput) => {
    let queryCriteria = "";
    if (input.dealersListInput.filterQueryCriteria) {
      if (input.dealersListInput.filterQueryCriteria.ctdiId) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.SEARCH_CTDI_ID
        }${input.dealersListInput.filterQueryCriteria.ctdiId}`;
      }
      if (input.dealersListInput.filterQueryCriteria.countryCode){
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.SEARCH_COUNTRY_CODE
        }${input.dealersListInput.filterQueryCriteria.countryCode}`;
      }
      if (input.dealersListInput.filterQueryCriteria.organizationId && 
        getNumber(input.dealersListInput.filterQueryCriteria.organizationId) > 0) {
        queryCriteria += `${commons.AMPERSAND}${
          queryParams.DEALERS_FILTER_QUERY.SEARCH_ORGANIZATION_ID
        }${input.dealersListInput.filterQueryCriteria.organizationId}`;
      }
    }
    return {
      url:
        apiEndpoints.DEALER_API_DOMAIN +
        apiEndpoints.DEALER_API_ENDPOINTS.GET_DEALERS_LIST +
        "?" +
        encodeURI(
          `lang=${input.dealersListInput.languageMarket}${queryCriteria}`
        ),
      request: {}
    };
  },
  getInputKey: (input: DealersListInput) =>
    "GetDealersListForCtdiId-" + Date.now(),
  successHandler: (input: DealersListInput, output: DealersListResponse) => {}
});

export const fetchDealersListIfNeeded = actionCreatorDealersList.fetchIfNeeded;
export const fetchDealersListWithSearchQueryIfNeeded =
  actionCreatorDealersListWithSearchQuery.fetchIfNeeded;
export const fetchDealerDetailsIfNeeded =
  actionCreatorDealerDetails.fetchIfNeeded;
export const fetchServicesListIfNeeded =
  actionCreatorServicesList.fetchIfNeeded;
export const fetchFavoriteDealersListIfNeeded =
  actionCreatorFavoriteDealersList.fetchIfNeeded;
export const fetchCreatorDealersListForCtdiIdIfNeeded =
  actionCreatorDealersListForCtdiId.fetchIfNeeded;

export type FetchIfNeededDealersList = typeof fetchDealersListIfNeeded;
export type FetchIfNeededDealerDeatils = typeof fetchDealerDetailsIfNeeded;
export type FetchIfNeededServicesList = typeof fetchServicesListIfNeeded;
export type FetchIfNeededDealersListWithSearchQuery = typeof fetchDealersListWithSearchQueryIfNeeded;
export type FetchIfNeededFavoriteDealersList = typeof fetchFavoriteDealersListIfNeeded;
export type FetchCreatorDealersListForCtdiIdIfNeeded = typeof fetchCreatorDealersListForCtdiIdIfNeeded;

export const reducer = {
  dealersList: actionCreatorDealersList.reducer,
  dealersLocationListData: actionCreatorDealersListWithSearchQuery.reducer,
  dealerDetailsList: actionCreatorDealerDetails.reducer,
  dealersListCtdiId: actionCreatorDealersListForCtdiId.reducer,
  servicesList: actionCreatorServicesList.reducer,
  favoriteDealersList: actionCreatorFavoriteDealersList.reducer
};
