const paths:any = {


    chevronDown: {
      viewBox: '0 0 64 64',
      path:
      'M63.31,19.24a2,2,0,0,1-.63,1.45L33.25,50.13a2,2,0,0,1-2.9,0L.91,20.69a2,2,0,0,1,0-2.91l3.16-3.16a2,2,0,0,1,2.91,0L31.8,39.45,56.62,14.63a2,2,0,0,1,2.9,0l3.16,3.16A2,2,0,0,1,63.31,19.24Z',
    },
    chevronUp: {
      viewBox: '0 0 64 64',
      path:
      'M.28,45.52a2,2,0,0,1,.63-1.45L30.35,14.63a2,2,0,0,1,2.9,0L62.68,44.06a2,2,0,0,1,0,2.91l-3.16,3.16a2,2,0,0,1-2.91,0L31.8,25.31,7,50.13a2,2,0,0,1-2.9,0L.91,47A2,2,0,0,1,.28,45.52Z',
    },
    chevronRight: {
      viewBox: '0 0 64 64',
      path:
        'M18.66.86a2,2,0,0,1,1.45.63L49.55,30.93a2,2,0,0,1,0,2.9L20.11,63.26a2,2,0,0,1-2.91,0l-3.16-3.16a2,2,0,0,1,0-2.91L38.87,32.38,14.05,7.56a2,2,0,0,1,0-2.9l3.16-3.16A2,2,0,0,1,18.66.86Z',
    },
    chevronLeft: {
      viewBox: '0 0 64 64',
      path:
        'M44.94,63.9a2,2,0,0,1-1.45-.63L14.05,33.83a2,2,0,0,1,0-2.9L43.48,1.5a2,2,0,0,1,2.91,0l3.16,3.16a2,2,0,0,1,0,2.91L24.72,32.38,49.55,57.2a2,2,0,0,1,0,2.9l-3.16,3.16A2,2,0,0,1,44.94,63.9Z',
    },
    close: {
      viewBox: '0 0 64 64',
      path:
        'M64 3.66 60.34 0 32 28.34 3.66 0 0 3.66 28.34 32 0 60.34 3.66 64 32 35.66 60.34 64 64 60.34 35.66 32 64 3.66z',
    },
    map: {
      viewBox: '0 0 64 64',
      path:
      'M31.36,0A23.11,23.11,0,0,0,8.28,23.09c0,15.8,20.66,39,21.54,40a2.08,2.08,0,0,0,3.09,0c.88-1,21.54-24.17,21.54-40A23.11,23.11,0,0,0,31.36,0Zm0,34.7A11.62,11.62,0,1,1,43,23.09,11.63,11.63,0,0,1,31.36,34.7Z',
    },
    search: {
      viewBox: '0 0 64 64',
      path:
      'M2.9,64a2.81,2.81,0,0,1-2-.83,2.77,2.77,0,0,1,0-3.93L15.32,45.06A26.58,26.58,0,0,1,9.2,28.12c0-14.83,12.29-26.9,27.39-26.9S64,13.29,64,28.12,51.68,55,36.58,55a27.67,27.67,0,0,1-17.27-6L4.88,63.15A2.86,2.86,0,0,1,2.9,64ZM36.6,6.8A21.56,21.56,0,0,0,14.86,28.12a21.74,21.74,0,0,0,43.48,0A21.56,21.56,0,0,0,36.6,6.8Z',
    },
    plus: {
      viewBox: '0 0 16 16',
      path: 'M7 7H0v2h7v7h2V9h7V7H9V0H7v7z',
    },
    star: {
      viewBox: '0 0 64 64',
      path: 'M64,24.46,41.9,21.09,32,0,22.1,21.09,0,24.46,16,40.84,12.21,64,32,53.07,51.79,64,48,40.84ZM20.54,39.31,9.05,27.54l15.87-2.42L32,10l7.08,15.07,15.87,2.42L43.46,39.31l2.71,16.58L32,48.06,17.83,55.89Z',
    },
    tick: {
      viewBox: '0 0 64 64',
      path: 'M64,24.09a3.82,3.82,0,0,1-1.16,2.81L33,56.8l-5.62,5.62a4,4,0,0,1-5.62,0L16.1,56.8,1.15,41.85a4,4,0,0,1,0-5.62l5.62-5.62a3.82,3.82,0,0,1,2.81-1.16,3.82,3.82,0,0,1,2.81,1.16L24.53,42.8,51.62,15.67a4,4,0,0,1,5.62,0l5.62,5.62A3.82,3.82,0,0,1,64,24.09Z',
    },
    starFilled: {
      viewBox: '0 0 64 64',
      path: 'M64,24.46,41.9,21.09,32,0,22.1,21.09,0,24.46,16,40.84,12.21,64,32,53.07,51.79,64,48,40.84Z',
    },
    facebook: {
      viewBox: '0 0 16 16',
      path:
        'M9.079 5.587V4.381c0-.181.008-.321.025-.42a.833.833 0 0 1 .115-.294.47.47 0 0 1 .289-.2c.133-.037.31-.055.531-.055h1.206V1H9.317c-1.116 0-1.917.265-2.404.794-.487.53-.731 1.31-.731 2.34v1.453H4.738V8h1.444v7h2.897V8h1.928l.255-2.413H9.079z',
    },
    instagram: {
      viewBox: '0 0 448 512',
      path:
        'M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z',
    },
    linkedin: {
      viewBox: '0 0 16 16',
      path:
        'M1.173 5.658h3.008v9.032H1.173zM14.052 6.483c-.632-.69-1.467-1.035-2.506-1.035-.383 0-.731.047-1.044.141a2.369 2.369 0 0 0-.793.396 3.877 3.877 0 0 0-.515.475c-.121.138-.24.3-.356.482V5.658H5.84l.009.438c.006.292.009 1.191.009 2.698s-.006 3.473-.018 5.897h2.999V9.65c0-.31.033-.556.1-.738.128-.31.32-.57.579-.779.258-.21.579-.315.962-.315.523 0 .907.181 1.153.542.246.361.369.861.369 1.499v4.831H15V9.513c0-1.331-.316-2.341-.948-3.03zM2.695 1.31c-.504 0-.913.147-1.226.442S1 2.419 1 2.869c0 .443.152.814.456 1.111.304.298.705.447 1.203.447h.018c.51 0 .922-.149 1.235-.447.313-.298.466-.668.46-1.112-.006-.45-.161-.822-.465-1.116-.303-.295-.707-.442-1.212-.442z',
    },
    twitter: {
      viewBox: '0 0 512 512',
      path:
        'M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z',
    },
    youtube: {
      viewBox: '0 0 576 512',
      path:
        'M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z',
    },
    mail: {
      viewBox: '0 0 64 64',
      path: 'M64,11.52V14L32,33,0,14V11.52A2.52,2.52,0,0,1,2.52,9h59A2.52,2.52,0,0,1,64,11.52Zm0,33.09V19L43,31.49ZM32,39l-7.34-4.36L0,50v2.46A2.52,2.52,0,0,0,2.52,55h59A2.52,2.52,0,0,0,64,52.48V49.57L39.71,34.42ZM0,19V45.05L21.4,31.71Z',
    },
  };
  
  export default paths;
  