import React from 'react';
import AnchorStyle from "./Anchor.style";
import { MouseEvent } from "react";

export interface Props {
    action?: (e: MouseEvent) => void;
    children: any;
    href?: string;
    rel?: "canonical" | "external" | "next" | "nofollow" | "noopener" | "prev";
    target?: "_blank";
    className?:string;
    
}

const Anchor: React.FC<Props> = (props: Props) => {
    const {
        action,
        href,
        rel,
        target,
        children,
        className,
    } = props;
    const newClass = className || "";

    return (
        <AnchorStyle.a className={`a-anchor ${newClass}`} onClick={action} href={href} rel={rel} target={target}>
            {children}
        </AnchorStyle.a>
    );
};

export default Anchor;
