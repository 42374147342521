import styledComponents from "styled-components";

export const a = styledComponents.a`
&.a-anchor {
	color: #1351B5;
	cursor: pointer;
	text-decoration: none;
	
	&:hover,
	&:focus,
	&:active {
		text-decoration: underline;
		transition: all .25s ease-in 0s;
	}
}
`;


export const  AnchorStyle = {
    a: a,
};

export  default  AnchorStyle ;
