import { connect } from "react-redux";
import { GlobalState } from "../../store";
import { GlobalSettings } from "../../store/GlobalSettings/types";
import { bindActionCreators, Dispatch } from "redux";
import { Action, SetLocalStorageAction , GetLocalStorageAction } from "../../store/GlobalSettings/store";

export interface LocalProps {
    globalSettings: GlobalSettings;
}

const actions = {
    setGlobalSettingsState: (action:string, globalSettings: GlobalSettings): Action => ({
        type: action,
        globalSettings: globalSettings,
    }),

    setViewName: (action:string, viewName: string, dealerId:number): Action => ({
        type: action,
        viewName: viewName,
        dealerId: dealerId,
    }),

    setLanguage: (action:string, languageMarket: string): Action => ({
        type: action,
        languageMarket: languageMarket
    }),

    setCountryCode: (action:string, countryCode: string): Action => ({
        type: action,
        countryCode: countryCode
    }),

    setUserCurrentLocation: (action:string, latitude: string, longitude: string, location: string): Action => ({
        type: action,
        userLocation: {latitude:latitude, longitude:longitude, location: location},

    }),
    
    setLocalStorage: (action: string, key: string, value: any): SetLocalStorageAction => ({
        type: action,
        key: key,
        value: value,
    }),

    getLocalStorage: (action: string, key?: string): GetLocalStorageAction => ({
        type: action,
        key: key,
    }),

    setBusLocator: (action: string, isBusLocator: boolean): Action => ({
        type: action,
        isBusLocator: isBusLocator,
    }),

    setTruckConfigurator: (action: string, isTruckConfigurator: boolean): Action => ({
        type: action,
        isTruckConfigurator: isTruckConfigurator,
    }),
    setIgnoreLocation: (action: string, ignoreLocation: boolean): Action => ({
        type: action,
        ignoreLocation: ignoreLocation,
    }),
};

export type Props = typeof actions & LocalProps;

function mapStateToProps(state: GlobalState) {
    return {
        globalSettings: state.globalSettings,
    };
}

export default connect(mapStateToProps, (dispatch: Dispatch) => bindActionCreators(actions, dispatch));
