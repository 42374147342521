import { Reducer } from "redux";
import { GlobalSettings } from "./types";
import actionTypes from "../../constants/actionTypes";
import { saveToLocalStorage, getFromLocalStorage } from  "../../utils/commons";
import commons from "../../constants/commons";

export interface State {
    globalSettings: GlobalSettings;
}

export interface Action {
    type: string;
    globalSettings?: GlobalSettings;
    viewName?: string;
    previousViewName? : string
    languageMarket?: string;
    countryCode?: string;
    dealerId?: number | 0;
    isBusLocator?: boolean,
    isTruckConfigurator?: boolean,
    userLocation?: UserLocation;
    ignoreLocation?: boolean;
}

export interface UserLocation {
    latitude: string;
    longitude:string;
    location: string;
}

export interface SetLocalStorageAction {
    type: string;
    key:string;
    value:string;
}

export interface GetLocalStorageAction {
    type: string;
    key?:string;
}

export const defaultValue: GlobalSettings = {
    applicationName: "Volvo Truck Dealer Locator",
    viewName:"DealerSearchView",
    previousViewName:"DealerSearchView",
    dealerId:0,    
    languageMarket:"",
    countryCode: "",
    localStorageData:"",    
    openLocationSelectorView: false,
    userLocation:
    {
        latitude: "",
        longitude: "",
        location: "",
    },
    filtersDefaultValue : {
        radiusFilter: {
            maxValue: 3000,
            defaultRadiusValue: ""+commons.NUMBER_HUNDRED,
        },
        classTypes: {
            onlyDealer: "A,B",
            onlyWorkshop: "B,C",
            bothDealerAndWorkshop: "B",
        }
    },
    filterSelectedValue: {
        selectedLocationName: "",
        selectedhLatitude: "",
        selectedLongitude: "",
        selectedRange: ""+commons.NUMBER_HUNDRED,
        onlyDealerService: false,
        onlyWorkShopService: false,
        selectedServices: [],
        noOfFilter: 0,
    },
    locationPinData: {
        coordinates:[],
    },
    isBusLocator: false,
    isTruckConfigurator: false,
    isError:false,
    ignoreLocation: false
};



const reducerFn: Reducer<GlobalSettings, any> =
    (state, action) => {
        const newValue = state || defaultValue;

        switch (action.type) {
            case actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_DATA.toString():
                return {
                    ...newValue
                };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.UPDATE_GLOBAL_SETTINGS_DATA.toString():
                // Do stuff to update the values 
                    return {
                        ...newValue
            };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString():
            newValue.previousViewName = newValue.viewName;
            newValue.viewName = action.viewName;
            newValue.dealerId = action.dealerId;
                    return {
                        ...newValue
            };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_LANGUAGE_MARKET.toString():
            newValue.languageMarket = action.languageMarket;
                    return {
                        ...newValue
            };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_COUNTRYCODE.toString():
            newValue.countryCode = action.countryCode;
                    return {
                        ...newValue
            };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_USER_LOCATION.toString():
            newValue.userLocation.latitude = action.userLocation.latitude;
            newValue.userLocation.longitude= action.userLocation.longitude;
            newValue.userLocation.location= action.userLocation.location;
                    return {
                        ...newValue
            };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_LOCAL_STORAGE.toString():
            saveToLocalStorage(action.key, action.value, newValue.isBusLocator);
                return {
                        ...newValue,
                        localStorageData:getFromLocalStorage(newValue.isBusLocator)
            };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.GET_LOCAL_STORAGE.toString():
            const localStorageData:any = getFromLocalStorage(newValue.isBusLocator, action.key);
                    return {
                        ...newValue,
                        localStorageData:localStorageData
            };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_BUS_LOCATOR.toString():
            newValue.isBusLocator = action.isBusLocator;
                    return {
                        ...newValue
            };

            case actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_TRUCK_CONFIGURATOR.toString():
            newValue.isTruckConfigurator = action.isTruckConfigurator;
                    return {
                        ...newValue
            };


            default: return newValue;
        }
    };

export const reducer = {
    globalSettings: reducerFn,
};
