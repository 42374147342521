import React from "react";

export interface Props {
    level: 1 | 2 | 3 | 4 | 5;
    type?: 1 | 2 | 3 | 4 | 5;
    title: string | undefined;
}

const Title: React.FC<Props> = (props: Props) => {
    const {
        level,
        title,
    } = props;

    let { type } = props;

    if (!type) {
        type = 2;
    }

    const cn = `title_type_h${type} `;

    switch (level) {
        case 1: return <h1 className={cn}>{title}</h1>;
        case 2: default: return <h2 className={cn}>{title}</h2>;
        case 3: return <h3 className={cn}>{title}</h3>;
        case 4: return <h4 className={cn}>{title}</h4>;
        case 5: return <h5 className={cn}>{title}</h5>;
    }
};

export default Title;
