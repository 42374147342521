import styledComponents from "styled-components";
import rtl from "../../../../withStyles/styled-components-rtl";

export const div = styledComponents.div`
&.p-dealerLocator { 

  ${rtl`
    height: 680px;
    max-height: calc(100vh - 215px);
    min-height: 450px;

    @media (max-width: 1299px) {
      max-height: calc(100vh - 263px);
    }

    @media (max-width: 1024px) {
      display: flex;
      height: 100vh;
      max-height: 100vh;
      min-height: 0px;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow: hidden;
    }

    @media screen and (max-width: 768px) {
      overflow: visible;
      height: 100vh;

      .a-input:focus,
      input:focus {
        -webkit-text-size-adjust: 100% !important;
      }

      .a-input,
      input,
      textarea {
        font-size: initial !important;
      }
    }

    .p-dealerLocator__map {
      flex: 1 0 auto;

      @media (max-width: 1024px) {
        flex: 0 0 375px;
        order: -1;
      }

      @media (max-width: 768px) {
        flex: 0 0 300px;
        flex: 0 0 80vw;

        > .m-mapBox {
          min-height: 300px;
          min-height: 80vw;
        }
      }
    }

    .p-dealerLocator__content {
      height: 100%;
      flex: 0 0 600px;
      position: relative;

      @media (max-width: 1299px) {
        flex: 0 0 475px;
      }

      @media (max-width: 1024px) {
        flex: 1 0 200px;
      }

      @media (max-width: 768px) {
        flex: 1 0 0;
        overflow: visible;
        height: auto;
      }
    }

    .o-searchDealer {
      background: #fff;
      height: 100%;

      .p-dealerLocator__contentList {
        padding: 0;
        flex-wrap: nowrap;
        flex-basis: 0;
      }

      .o-searchDealer__search {
        background: #F5F5F5;
        border-top: 5px solid #1351B5;
        color: #404040;
        flex-wrap: nowrap;
        font-size: 16px;
        min-height: 90px; /*safari fix*/
        padding: 16px 32px;
        position: relative;

        @media (max-width: 1024px) {
          font-size: 14px;
          min-height: 70px; /*safari fix*/
          padding: 12px 20px;
        }

        > div:first-child {
          border-bottom: 1px solid #d0d0d0;
          position: relative;
        }

        .o-searchDealer__searchSubmit {
          background: transparent;
          border: none;
          box-shadow: none;
          line-height: 1;
          font-weight: normal;
          padding: 0;

          .fa {
            font-size: 18px;
            color: #1351B5;
          }
        }

        .fa {
          color: #404040;
          vertical-align: text-bottom;
          font-size: 18px;
          margin-left: 8px;
        }

        input {
          color: #727375;
          letter-spacing: .5pt;
          padding-left: 13px;
          position: relative;
          width: calc(100% - 24px);

          &:placeholder {
            opacity: 1;
          }
        }

        .closeSearchNarrowing {
          background: none;
          box-shadow: none;
          color: #000;
          font-size: 18px;
          font-weight: normal;
          padding-right: 13px;
          cursor: pointer;
        }
      }
      
      .o-searchDealer__filter {
        color: #404040;
        height: 30px;
        margin: 14.6px 0 0 32px;

        @media (max-width: 1024px) {          
          margin: 7px 0 0 32px;
        }

      }
    
      .o-searchDealer__filterSubmit {
        background: transparent;
        border: none;
        box-shadow: none;
        color: #404040;
        font-weight: normal;
        letter-spacing: .5pt;
        padding: 0;
        white-space: nowrap;

        @media (max-width: 1024px) {
          font-size: 14px;
        }

        .fa {
          font-size: 20px;
        }
      }

      .m-dealerListWrapper {
        flex-wrap: nowrap;
        flex-basis: 0;
        overflow-y: auto;
      }
    }

    .m-locationSearchWrapper {
      flex-wrap: nowrap;
      overflow: hidden;
    }

    .o-searchDealer__locationSearch {
      color: #4D4E53;
      font-size: 14px;
      overflow: hidden;
      flex-wrap: nowrap;
      flex-basis: 0;

      @media screen and (max-width: 768px) {
        font-size: 12px;
        min-height: 350px;
      }

      .o-locationSearch__currentLocation {
        position: relative;
        padding: 14px 32px 11px;
        align-items: center;

        @media screen and (max-width: 768px) {
          padding: 5px 20px 5px;
        }

        .fa {
          font-size: 22px;
          color: #1951B5;
          margin-right: 24px; 
          width: 20px;
        }

        .a-anchor {
          position: absolute; 
          top: 0; 
          left: 0; 
          width: 100%; 
          height: 100%;
        }

        span.no-grow {
          flex-basis: 42px;
        }
      }

      .o-locationSearch__quickSearch {
        overflow: hidden; 
        flex-wrap:nowrap; 

        .m-locationList {
          overflow-y: auto; 
        }
      }
  
      .o-locationSearch__filterHeading {
        min-height: 40px; 
        padding: 8px 32px 8px 74px;
        background: #f5f5f5; 

        @media (max-width: 768px) {
          padding: 8px 20px 8px 62px;
        }

        h4 {
          margin: 0; 
          font-size: 14px; 
        }
      }

      .m-locationList__item {
        .fa {
          font-size: 27px;
        }
      }
    }

    .o-locationSearch__distance {
      padding: 6px 32px 6px;
      position: relative; 
      float: left;
      width: 100%;
      background: #F5F5F5;

      @media (max-width: 768px) {
        padding: 2px 22px 2px;
      }

      &.withOverlay:after {
        content:""; 
        background: rgba(0, 0, 0, 0.25);
        width: 100%;  
        height: 100%; 
        position: absolute; 
        z-index: 1; 
        top: 0; 
        left: 0; 
      }
    }


    .m-lastLocationSearchList {
      width: 100%;
    
      .m-lastLocationSearchList__itemsWrapper {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    
      .m-lastLocationSearchList__item {
        background: #fff;
        border-bottom: 1px solid #d0d0d0;
    
        &:hover {
          background: #CFEAEF; 
          transition: background .25s ease-in 0s;
        }
    
        .a-anchor {
          color: #4D4E53;
          display: flex; 
          text-decoration: none;
          flex-wrap: nowrap;
        }
    
        .fa {
          color: #1251B5;
          font-size: 20px;
          margin-left: 24px;
        }
    
        img {
          width: 20px; 
          height: auto;
          margin-left: 24px;
        }
      }
    }

    &.rtl {
    .m-dealerList__item h4 {
      padding-left: 0;
    }

    .o-viewDealer__openText {
      text-align: right;
    }
  
    .m-dealerList .m-dealerList__iconWrapper .a-anchor {
      margin-left: 16px;
      margin-right: 0;
    }

    .m-dealerList .m-dealerList__itemLocation {
      text-align: right;
    }

    .m-lastSearchObjectList .m-lastSearchObjectList__item
      .fa, img {
        margin-left: 0;
        margin-right: 24px;
      }
    }


    @media screen and (max-width: 1024px) {
      .m-dealerList .m-dealerList__itemDetails {
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      .m-dealerList .m-dealerList__itemDetails > span {
        margin-right: 10px;
        margin-left: 0;
      }
      
      .m-dealerList .m-dealerList__itemDetails > :last-child {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .m-dealerList .m-dealerList__iconWrapper {
      margin-left:8px;
      margin-right: 0;
    }

    .o-viewDealer .o-viewDealer__links .fa {
      margin-right: 15px;
      margin-left: 0; 
    } 
    
    .o-viewDealer .m-accordion__contactItem .fa {
      margin-right: 12px;
      margin-left: 0;
    }
    
    .o-viewDealer .m-accordion__serviceItem {
      padding-left: 0;
      padding-right: 8px; 
    }
    
    .o-viewDealer .m-accordion__serviceItem .fa {
      margin-right: 12px;
      margin-left: 0;
    }

    .o-viewDealer .m-accordion__hoursItem > span:last-child {
      margin-left: 32px;
      margin-right: 0;
    }
  
    .o-viewDealer .o-viewDealer__close {
      margin-left: 20px;
      margin-right: 0;
    }
  
    .a-rangeSlider span {
        margin-left: 32px;
        margin-right: 0;
        text-align: left
    }
    
    .o-filterDealer .o-filterDealer__makeChoice .a-checkBox {
      margin-right: 32px;
      margin-left: 0;
    }
    
    .a-checkBox {
      padding-right: 24px;
      padding-left: 0;
    }
    
    .a-checkBox label {
      margin-right: 7.5px;
      margin-left: 0;
    }

    .o-searchDealer__locationSearch .m-locationList__item .fa {
      margin-right: 30px;
      margin-left: 0; 
    }

    .m-locationList .m-locationList__item img {
      margin-right: 24px;
      margin-left: -2px;
    }
  }`}
}


`;

export const DealerLocatorStyle = {
  div: div
};

export default DealerLocatorStyle;
