import styledComponents from "styled-components";

export const div = styledComponents.div`

&.m-iframe {

    margin-top: 20px;
    margin-bottom: 20px;

    > div {
      height: 0;
      position: relative;
      padding-bottom: 56.25%;
      width: 100%;
    }

    iframe {
      width:100%; 
      min-height: 120px;
    }
  }

`;


export const  IframeStyle = {
  div: div,
};

export  default  IframeStyle ;