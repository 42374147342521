import styledComponents from "styled-components";

export const input = styledComponents.input`
&.a-input {
    background: transparent;
    border: none;
    box-shadow: none;
    height: 30px;
    margin: 0;
    padding: 5px 0;
    width: 100%;

    &:focus {
        outline:none;
    }
}
`;


export const  InputStyle = {
    input: input,
};

export  default  InputStyle ;




