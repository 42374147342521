import { Component, Fragment } from "react";
import * as React from "react";
import { debounce } from "lodash";
import { ThemeProvider } from 'styled-components';
import DealerLocatorConatiner, { Props as DealerLocatorConatinerProps } from "../../../../containers/DealerLocator/DealerLocator";
import GlobalSettingsConatiner, { Props as GlobalSettingsConatinerProps } from "../../../../containers/GlobalSettings/GlobalSettings";
import SquidexContentConatiner, { Props as SquidexContentProps } from "../../../../containers/SquidexContent/SquidexContent";
import LocationIQConatiner, {  Props as LocationIQConatinerProps } from "../../../../containers/LocationIQ/LocationIQ";
import { RouteComponentProps } from "react-router-dom";
import DealerLocatorStyle from './DealerLocator.style';
import DealerFilterView from '../organisms/DealerFilterView/DealerFilterView';
import DealerLocationSearchView from './DealerLocationSearchView';
import DealerDetailsView from '../organisms/DealerDetailsView';
import OffScreenElement from '../../../common/organisms/OffScreenElement';
import { DealersListData, DealerInfoData } from "../../../../store/DealerLocator/types";
import { LocationInfoData } from "../../../../store/LocationIQ/types";
import actionTypes from "../../../../constants/actionTypes";
import MapBox from '../../../common/molecules/MapBox';
import ErrorMessage from '../../../common/molecules/ErrorMessage';
import DealerList from "../../../common/molecules/DealerList/DealerList";
import Input from "../../../common/atoms/Input";
import Loader from "../../../common/atoms/Loader/Loader";
import Button from "../../../common/atoms/Button/Button";
import RangeSlider from '../../../common/atoms/RangeSlider';
import commons from "../../../../constants/commons";
import { getNumber, isNotEmptyString, isDualBrandedData } from "../../../../utils/commons";
import { trackEvent } from "../../../../utils/trackEvent";
import { TrackEvent } from "../../../../constants/trackEvent";

import {
  getLanguageAndMarket,
  getInitialDealersList,
  getCountryCode,
  getFilteredDealersListByLatitudeLongitude,
  setLastLocationSearchToLocalStorage,
  getMainDealerId,
  getOrganizationId,
  getDealerId,
  getBusLocatorFlag,
  getTruckConfiguratorFlag,
  getPreSelectedServices,
} from "../../../shared/PropsHelper";

type Props = GlobalSettingsConatinerProps & DealerLocatorConatinerProps & RouteComponentProps & SquidexContentProps & LocationIQConatinerProps;

interface State {
  updatedDealersListData?: DealersListData,
  isOffScreenElementOpen: boolean,
  isMobileSingleDealerView: boolean,
  searchQueryText: string;
  showDealerLocationSearchView: boolean;
  isFavoriteDealersList: boolean;
  isUserCurrentLocation: boolean;
  iqLocationListData? :  Array<LocationInfoData> | undefined;
  dealersLocationListData? : Array<DealerInfoData> | undefined;
  dealerSearchView2: boolean;
  radiusRange: string;
  isRadiusRangeChanged: boolean;
}

/** Map height and width to adjust the zoom level */
let d = document, documentElement = d.documentElement,
  mapDiv = d.getElementById('dealer_locator_map_id'),
  windowInnerWidth = ((mapDiv && mapDiv.offsetWidth) || documentElement.clientWidth),
  windowInnerHeight = ((mapDiv && mapDiv.offsetHeight) || documentElement.clientHeight);


class DealerLocator extends Component<Props, State> { 
  public mapViewboxBoundText:any = "";
  constructor(props: any) {
    super(props);
    this.state = {
      updatedDealersListData: {} as DealersListData,
      isOffScreenElementOpen: false,
      searchQueryText: "",
      showDealerLocationSearchView: false,
      isFavoriteDealersList: false,
      isUserCurrentLocation: false,
      iqLocationListData: [],
      dealersLocationListData: [],
      dealerSearchView2: false,
      isMobileSingleDealerView: false,
      radiusRange: props.globalSettings.filterSelectedValue.selectedRange,
      isRadiusRangeChanged: false
    };    
    this.loadInitialDealersList = this.loadInitialDealersList.bind(this);
    this.toggleFavoriteDealersListFilter = this.toggleFavoriteDealersListFilter.bind(
      this
    );
    this.updateUserCurrentLocationFlag = this.updateUserCurrentLocationFlag.bind(
      this
    );
    this.getDealersListForUserCurrentLocation = this.getDealersListForUserCurrentLocation.bind(this);
    this.onRadiusRangeChange = this.onRadiusRangeChange.bind(this);
    this.isRadiusRangeChanged = this.isRadiusRangeChanged.bind(this);
    this.setRadiusRangeChanged = this.setRadiusRangeChanged.bind(this);
    this.callAutoCompleteLocationsListService = debounce(this.callAutoCompleteLocationsListService.bind(this), commons.DEBOUNCE_WAIT_TIME);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.props.setLanguage(
        actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_LANGUAGE_MARKET.toString(),
        getLanguageAndMarket(this.props)
      );
      this.props.setCountryCode(
        actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_COUNTRYCODE.toString(),
        getCountryCode(this.props)
      );
      this.props.setBusLocator(
        actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_BUS_LOCATOR.toString(),
        getBusLocatorFlag(this.props)
      );
      this.props.setTruckConfigurator(
        actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_TRUCK_CONFIGURATOR.toString(),
        getTruckConfiguratorFlag(this.props)
      );

      let preselected = !this.props.globalSettings?.isBusLocator ? getPreSelectedServices(this.props) : []; 
      if(preselected && preselected.length > 0) {
        this.loadServicesThenInitDealers();
      } else {
        this.props.getServices({
          servicesListInput: { languageMarket: getLanguageAndMarket(this.props) }
        });
        this.loadInitialDealersList();
      }

      this.props.getLocalStorage(
        actionTypes.GLOBAL_SETTINGS_ACTIONS.GET_LOCAL_STORAGE.toString()
      );
      this.props.getSquidexContentsList({
        squidexContentInput: { languageMarket: getLanguageAndMarket(this.props) }
      });
    }
  }

  public loadServicesThenInitDealers() {
    const servicesEnsured = new Promise((resolve) => {
      resolve(this.props.getServices({
        servicesListInput: { languageMarket: getLanguageAndMarket(this.props) }
      }));
    });
    servicesEnsured.then(this.loadInitialDealersList);
  }

  /**
   * componentWillReceiveProps
   */
  public componentWillReceiveProps(nextProps: Props) {
    const { isUserCurrentLocation } = this.state;
    
     if (nextProps.returnDealerId &&
      JSON.stringify(nextProps.returnDealerId) !==
      JSON.stringify(this.props.returnDealerId)
    ) {
      const { globalSettings } = this.props;
     this.props.getDealerDetails({
      dealerDetailsInput: {
        languageMarket: globalSettings.languageMarket,
        dealerId: getNumber(nextProps.returnDealerId),
        isBusLocator: globalSettings.isBusLocator,
      }
    });
    }    
    if (isUserCurrentLocation && nextProps.squidexContentsList.yourCurrentLocationText && (
      JSON.stringify(nextProps.squidexContentsList.yourCurrentLocationText) !==
      JSON.stringify(this.props.squidexContentsList.yourCurrentLocationText))
    ) {
      this.setState({searchQueryText : nextProps.squidexContentsList.yourCurrentLocationText});
    }
  }

  /**
   * Method to load initial dealers list based on the parameters provided
   * 1) if "dealerid" is available in url then get the specific dealer data
   * 2) if "maindealerid" is available in url then get the dealers data
   * 2.2) if "organizationId" is available in the url then also take that into account
   * 3) then get the dealers data based on the user location and country code
   */
  private loadInitialDealersList() {
    const { globalSettings, servicesListData } = this.props;
    const { filterSelectedValue } = globalSettings;

    let dealerId = getDealerId(this.props);
    let mainDealerId = getMainDealerId(this.props);
    let organizationId = getOrganizationId(this.props);
    let languageMarket = getLanguageAndMarket(this.props);
    let countryCode = getCountryCode(this.props);
    let isBusLocator = getBusLocatorFlag(this.props);
    let preSelectedServices = !isBusLocator ? getPreSelectedServices(this.props) : [];

   if (preSelectedServices && 
      preSelectedServices.length > 0 && 
      servicesListData && 
      servicesListData.value) {
      preSelectedServices.map(e => {
        let service = servicesListData?.value.find(a => a.code === e);
        if(service !== undefined && 
        filterSelectedValue.selectedServices.indexOf(service.id) === -1) {
          filterSelectedValue.selectedServices.push(service.id);
          filterSelectedValue.noOfFilter+=1;
        }
      }); 
    }
    //console.log(filterSelectedValue.selectedServices);
    //set services*/
    if (dealerId && getNumber(dealerId) > 0) {
      this.props.getDealersListCtdiId({
        dealersListInput: {
          languageMarket: languageMarket,
          filterQueryCriteria: {
            ctdiId: dealerId,
            organizationId : organizationId
          }
        }
        });
        // Check if device width is greater than tablet then open details view 2 otherwise
        // open serach view 2 and show only one dealer in the list
      if(window.innerWidth > commons.DEVICE_TYPES.tablet) {
        this.props.setViewName(
          actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString(),
          commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW2.toString(),
          getNumber(dealerId)
        );    
        this.setState({
          isOffScreenElementOpen: true
        });
      } else {
        this.setState({
          dealerSearchView2: true
        });  
        this.setState({isMobileSingleDealerView : true}) ;
      }
    } else if (mainDealerId && getNumber(mainDealerId) > 0 ) {
      this.props.getDealersList({
        dealersListInput: {
          languageMarket: languageMarket,
          filterQueryCriteria: {
            mainDealerId: mainDealerId,           
            organizationId : organizationId,
            services : filterSelectedValue?.selectedServices
          },
          isBusLocator: isBusLocator,
        }
      });
      this.setState({
        dealerSearchView2: true
      });   

    }
    else {      
      getInitialDealersList(this, false);
    }
  }

  /*public resolvePromise = () => {
    return new Promise((resolve) => { 
      console.log("promise?"); 
      resolve({data: null});
    });
  };*/

  /**
   * componentWillReceiveProps
   */
  public updatedDealersListData( dealersListData: DealersListData | undefined) {
    let { isMobileSingleDealerView, isFavoriteDealersList } = this.state;
    const { globalSettings, dealerDetailsListData, favoriteDealersListData } = this.props;
    const { viewName } = globalSettings;
    let updatedDealersListData = {} as DealersListData;
    // Favorite Dealers List 
    if (isFavoriteDealersList) {
      if ( favoriteDealersListData ) {
        updatedDealersListData = favoriteDealersListData;
      }
    } else {
      if (viewName === commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW.toString() ||
      viewName === commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW2.toString() || isMobileSingleDealerView) {
        const dealerInfoDataObjArray: Array<DealerInfoData> = [];
        const dealersListData = {} as DealersListData;
        if (dealerDetailsListData && dealerDetailsListData.length > 0) {
          const dealerInfoDataObj: DealerInfoData = dealerDetailsListData[0];
          dealerInfoDataObjArray.push(dealerInfoDataObj);
          dealersListData.value = dealerInfoDataObjArray;
          updatedDealersListData = dealersListData;
        }
      } else {
        if (dealersListData) {
          updatedDealersListData = dealersListData;
        }
      }
    }
    return updatedDealersListData;
  }

  public render() {
    const {
      isOffScreenElementOpen,
      searchQueryText,
      showDealerLocationSearchView,
      dealerSearchView2,
      isUserCurrentLocation,
      isFavoriteDealersList,
      radiusRange
    } = this.state;
    const { squidexContentsList, globalSettings, dealersListData, fetchingDealerList } = this.props;
    const { viewName, filterSelectedValue, filtersDefaultValue } = globalSettings;
    const dealerLocatorView = this.supportedDealerViews[
      viewName
    ];
    const updatedDealersListData = this.updatedDealersListData(dealersListData);
    const dir = squidexContentsList.themeDir;
    const isNotUserCurrentLocation= this.isNotUserCurrentLocation();
    return (
      <Fragment>
      {dir ? <ThemeProvider theme={{ dir }}>
      <DealerLocatorStyle.div className={`${dir} p-dealerLocator flex`} dir={dir}>
        <div className="flex s-twelve p-dealerLocator__content">
          <Loader 
            visisble={fetchingDealerList || false}>
          </Loader>
          {/* Common Section which will alwasys available on the screen and will be cover by offscreen element (Filter and Details View) */}
          <div className="o-searchDealer flex">
            <div className="flex col p-dealerLocator__contentList">
              {!dealerSearchView2 &&
              <div className="o-searchDealer__search flex no-grow">
                <div className="align center left">
                  {isNotEmptyString(searchQueryText) && isNotUserCurrentLocation ? (
                    <Button
                      className="o-searchDealer__searchSubmit"
                      action={e => {
                        setLastLocationSearchToLocalStorage(
                          this.props,
                          "lastLocationSearchList",
                          {
                            type: "search",
                            searchText: searchQueryText
                          }
                        );
                        this.openUpdatedDealerSearchViewButtonSubmit(
                          searchQueryText
                        );
                      }}
                      type="submit"
                    >
                      <i className="fa fa-search" aria-hidden="true" />
                    </Button>
                  ) : (
                    <i className="fa fa-search" aria-hidden="true" />
                  )}

                  <Input
                    id="searchId"
                    value={searchQueryText}
                    placeholder={squidexContentsList.searchInputLabel}
                    type="text"
                    onFocus={e => {
                      if(this.state.isUserCurrentLocation) {
                        this.updateUserCurrentLocationFlag(false, ""); 
                      }
                      this.openDealerLocationSearchView(e);
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onChange={e => {
                      this.setSearchQueryText(e);
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onClick={e => {
                      this.openDealerLocationSearchView(e);
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onKeyPress={(e: any) => {
                      if (e.key && e.key.toUpperCase() === "ENTER" && isNotEmptyString(searchQueryText) && isNotUserCurrentLocation) {
                        setLastLocationSearchToLocalStorage(
                          this.props,
                          "lastLocationSearchList",
                          {
                            type: "search",
                            searchText: searchQueryText
                          }
                        );
                        this.openUpdatedDealerSearchViewButtonSubmit(
                           searchQueryText
                        );
                        e.target.blur();
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                  />                  
                  <Button action={this.closeDealerLocationSearchView} type="submit" className="closeSearchNarrowing no-grow">
                    <i className="fa fa-close" aria-hidden="true"></i>
                  </Button>

                </div>
                
                <div className="no-grow o-searchDealer__filter align center right">
                  <Button
                    className="o-searchDealer__filterSubmit"
                    action={this.openDealerFilterView}
                    label={squidexContentsList.filterButtonLabel}
                    type="submit"
                  >
                    {filterSelectedValue.noOfFilter > 0
                      ? `(${filterSelectedValue.noOfFilter})`
                      : ""}
                    <i className="fa fa-filter" aria-hidden="true" />
                  </Button>

                                 
                </div>

              </div>
              }
              {!dealerSearchView2 && 
                  <div className={`o-locationSearch__distance no-grow`} >
                    <div className="o-locationSearch__rangeWrapper">               
                      <RangeSlider labelText={squidexContentsList.adjustRange} 
                        value={getNumber(radiusRange)} 
                        min={0} max={filtersDefaultValue.radiusFilter.maxValue}
                        valueUpdated={this.onRadiusRangeChange}
                        updateResult={e => this.updateDealersListForFilterView()}
                      />
                    </div>
                  </div> 
              }

              <div className={`${showDealerLocationSearchView ? "hide" : "m-dealerListWrapper flex col"}`} >
                <React.Fragment>
                  {updatedDealersListData && updatedDealersListData.value && updatedDealersListData.value.length > 0 ? (
                    <DealerList
                      dealerList={updatedDealersListData}
                      action={this.openDealerDetailsView}
                    />
                  ) : <ErrorMessage errorMessageString={squidexContentsList.searchErrorMessage} /> }
                </React.Fragment>
              </div>

              <div className={`${!showDealerLocationSearchView ? "hide" : "m-locationSearchWrapper flex col"}`} >
                <DealerLocationSearchView
                  searchQueryText={searchQueryText}
                  openDealerDetailsView={this.openDealerDetailsView}
                  openUpdatedDealerSearchViewLocationSelection={
                    this.openUpdatedDealerSearchViewLocationSelection
                  }
                  openUpdatedDealerSearchViewTextSelection={
                    this.openUpdatedDealerSearchViewButtonSubmit
                  }
                  getDealersListForUserCurrentLocation= {
                    this.getDealersListForUserCurrentLocation
                  }
                />
              </div>
            </div>
          </div>

          {/* OffScreenElement that cover and slide up to show filter and details view */}
          <OffScreenElement
            isOffScreenElementOpen={isOffScreenElementOpen}
            toggleOffScreenElement={this.toggleOffScreenElement}
            offScreenId={globalSettings.viewName}
            className={``}
            childClassName={``}
          >
            <React.Fragment>{dealerLocatorView}</React.Fragment>
          </OffScreenElement>
        </div>

        {/* Map component with the updates action and data */}
        <div
          className="eight s-twelve p-dealerLocator__map"
          id="dealer_locator_map_id"
        >
          <MapBox
            data={updatedDealersListData ? updatedDealersListData.value : []}
            windowInnerWidth={windowInnerWidth}
            windowInnerHeight={windowInnerHeight}
            action={this.selectDealerItem}
            isFavoriteDealersList={isFavoriteDealersList}
            toggleFavoriteDealersListFilter={
              this.toggleFavoriteDealersListFilter
            }
            getDealersListForUserCurrentLocation={
              this.getDealersListForUserCurrentLocation
            }
            isUserCurrentLocation={
              isUserCurrentLocation
            }
            updateUserCurrentLocationFlag={
              this.updateUserCurrentLocationFlag
            }
            updateReactMapGLRef={this.updateReactMapGLRef}
            dealerSearchView2 = {dealerSearchView2}
          />
        </div>
      </DealerLocatorStyle.div>
      </ThemeProvider> : ''}
      </Fragment>
    );
  }

  /**
   * Method to fetch react map gl properties (example-bound) whenever required
   * so that we can pass them to locationIQ api to filter data
   */
  updateReactMapGLRef = (mapGLRef: any) => {
    if (mapGLRef) {
      const map = mapGLRef.getMap();
      if (map && map.getBounds()) {
        const bounds = map.getBounds();
        this.mapViewboxBoundText = `${bounds._sw.lng},${bounds._sw.lat},${bounds._ne.lng},${
          bounds._ne.lat
        }`;
      }
    }
  };


  /**Method to check if user current location is active*/
  isNotUserCurrentLocation = () => {
    const { searchQueryText, isUserCurrentLocation } = this.state;
    const { squidexContentsList } = this.props;
    return !(isUserCurrentLocation && searchQueryText === squidexContentsList.yourCurrentLocationText);
  };

  /**Method to open and close the off screen element */
  toggleOffScreenElement = () => {
    const { isOffScreenElementOpen } = this.state;
    this.setState({
      isOffScreenElementOpen: !isOffScreenElementOpen
    });
  };

  /**Method to open the dealer deatails views based on dealer id  */
  private selectDealerItem = (e: React.MouseEvent, dealerId: number, dealerName: string) => {
    if (
      commons.DEALER_VIEW_NAMES.DEALER_SEARCH_VIEW.toString().localeCompare(
        this.props.globalSettings.viewName
      ) !== 0
    ) {
      this.toggleOffScreenElement();
    }
    this.props.setViewName(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString(),
      commons.DEALER_VIEW_NAMES.DEALER_SEARCH_VIEW.toString(),
      dealerId
    );
    trackEvent(TrackEvent.TRACK_NAMES.DEALER_CLICK.toString(), dealerName, dealerId.toString());
    this.setState({ showDealerLocationSearchView: false });
  };

  /**
   * Method to open the dealer filter view with all the necessary
   * labels and data
   */
  private openDealerFilterView = (e: React.MouseEvent) => {
    const { globalSettings } = this.props;
    this.props.setViewName(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString(),
      commons.DEALER_VIEW_NAMES.DEALER_FILTER_VIEW.toString(),
      globalSettings.dealerId
    );
    this.toggleOffScreenElement();
  };

  /**
   * Method to open the dealer details view with all the necessary
   * labels and data
   */
  private openDealerDetailsView = (e: React.MouseEvent, dealerId: number) => {
    const { globalSettings } = this.props;
    const { isTruckConfigurator } = globalSettings;
    if(isTruckConfigurator){
      this.props.setViewName(
        actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString(),
        commons.DEALER_VIEW_NAMES.DEALER_SEARCH_VIEW.toString(),
        dealerId
      );
    }
    else{
      this.props.getDealerDetails({
        dealerDetailsInput: {
          languageMarket: globalSettings.languageMarket,
          dealerId: dealerId,
          isBusLocator: globalSettings.isBusLocator,
        }
      });
      this.props.setViewName(
        actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString(),
        commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW.toString(),
        dealerId
      );
      //this.setState({ isUserCurrentLocation: false });
      this.toggleOffScreenElement();
    }    
    
  };

  /**
   * Method to call different endpoints to get the dealer data based on the query entered
   * This method will be used to filter the available dealer data based on the query entered
   */

  private setSearchQueryText = (event: any) => {
    this.setState({ showDealerLocationSearchView: true });
    this.getAutoCompleteLocationsList(event.target.value);
  };

  private openDealerLocationSearchView = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    this.setState({ showDealerLocationSearchView: true });
  };

  private closeDealerLocationSearchView = () => {
    this.setState({ showDealerLocationSearchView: false });
    getInitialDealersList(this, false);
  };

  private getAutoCompleteLocationsList = (searchQueryText: string) => {  
    this.setState({ searchQueryText: searchQueryText });
    if ( isNotEmptyString(searchQueryText,commons.NUMBER_TWO) ) {
      this.callAutoCompleteLocationsListService();
    }
  };

  private callAutoCompleteLocationsListService = () => {
    const { globalSettings } = this.props;
    const { countryCode, isBusLocator } = globalSettings;
    const { searchQueryText } = this.state;
    this.props.getIQLocationsListWithQuery({
      locationsListInput: {
        searchQueryText: searchQueryText,
        mapViewboxBoundText: this.mapViewboxBoundText,
        countryCode: countryCode,
      }
    })
    this.props.getDealerLocationsList({
      dealersListInput: {
        languageMarket: globalSettings.languageMarket,
        searchQueryText: searchQueryText,
        isBusLocator: isBusLocator,
        limit: commons.NUMBER_THREE
      }
    })
  };

  /**
   * Method to open the dealer details view with all the necessary
   * labels and data
   */
  openUpdatedDealerSearchViewButtonSubmit = (searchQueryText: string) => {
    if ( isNotEmptyString(searchQueryText) ){
      this.updateDealersListForQueryAndFilter(searchQueryText);
      this.updateCurrentSelectedLocationData("", "", "");
      this.setState({ searchQueryText: searchQueryText });
      this.setState({ showDealerLocationSearchView: false });
      this.resetMapDealersListFilter();
    }
  };

  reloadGlobalSettings = () => {
    this.props.setGlobalSettingsState(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_DATA.toString(),
      this.props.globalSettings
    );
  };

  getDealersListForUserCurrentLocation = () => {
    getInitialDealersList(this, true);
    this.resetDefaultSearchView();
  };

  updateUserCurrentLocationFlag = (flag: boolean, searchQueryText: string) => {
    if (this.state.isUserCurrentLocation) {
      this.setState({ searchQueryText: searchQueryText });
    }
    this.setState({ isUserCurrentLocation: flag });    
  };

  resetMapDealersListFilter = (
    ) => {
      this.setState({ isFavoriteDealersList : false});
      this.setState({ isUserCurrentLocation: false });
    };
  
  /** Method to update the current selecetd locatioon data by which
   * We will show a maker on the map
   */
  updateCurrentSelectedLocationData = (latitude: string, longitude: string, locationName: string) => {
    const { globalSettings } = this.props;
    const { locationPinData, filterSelectedValue } = globalSettings;
    if (latitude && longitude) {
      locationPinData.coordinates = [
        [getNumber(latitude), getNumber(longitude)]
      ];
    } else {
      locationPinData.coordinates = [];
    }
    filterSelectedValue.selectedhLatitude = latitude;
    filterSelectedValue.selectedLongitude = longitude;
    filterSelectedValue.selectedLocationName = locationName;
    this.reloadGlobalSettings();

  }

   
  /**
   * Method to set radius range change value 
   */
  onRadiusRangeChange = (value: number) => {
    const { filterSelectedValue } = this.props.globalSettings;
    filterSelectedValue.selectedRange = ""+value;
    this.setState({ radiusRange: ""+value });
    this.setRadiusRangeChanged(true);
    this.reloadGlobalSettings();
  };

  isRadiusRangeChanged = () => {
    return (this.state && this.state.isRadiusRangeChanged) || false;
  };

  setRadiusRangeChanged = (flag : boolean) => {    
    this.setState({ isRadiusRangeChanged: flag });
  };

  /**
   * Method to open the dealer details view with all the necessary
   * labels and data
   */
  openUpdatedDealerSearchViewLocationSelection = (
    e: React.MouseEvent,
    locationName: string,
    latitude: string,
    longitude: string
  ) => {
    const { globalSettings } = this.props;
    const { languageMarket, filterSelectedValue, countryCode, isBusLocator, filtersDefaultValue } = globalSettings;
    const { classTypes } = filtersDefaultValue;
    const classValue = filterSelectedValue.onlyDealerService &&
      filterSelectedValue.onlyWorkShopService
      ? classTypes.bothDealerAndWorkshop
      : filterSelectedValue.onlyDealerService
        ? classTypes.onlyDealer
        : filterSelectedValue.onlyWorkShopService
          ? classTypes.onlyWorkshop
          : "";    
    this.updateCurrentSelectedLocationData(latitude, longitude, locationName);
    if (
      filterSelectedValue.selectedhLatitude &&
      filterSelectedValue.selectedLongitude
    ) {
      this.props.getDealersList({
        dealersListInput: {
          languageMarket: languageMarket,
          filterQueryCriteria: {
            latitude: filterSelectedValue.selectedhLatitude,
            longitude: filterSelectedValue.selectedLongitude,
            range: filterSelectedValue.selectedRange,
            countryCode: countryCode,            
            class: classValue,
            services: filterSelectedValue.selectedServices
          },
          isBusLocator: isBusLocator,
          isDualBranded: isDualBrandedData()
        }
      });
    }
    this.resetMapDealersListFilter();
    this.setState({ searchQueryText: locationName });
    this.setState({ showDealerLocationSearchView: false });
  };

  /**
   * Method to call different endpoints to get the dealer data based on the query entered
   * This method will be used to filter the available dealer data based on the query entered
   */
  private resetDefaultSearchView = () => {
    this.props.setViewName(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString(),
      commons.DEALER_VIEW_NAMES.DEALER_SEARCH_VIEW.toString(),
      this.props.globalSettings.dealerId
    );
    this.setState({ showDealerLocationSearchView: false });
    this.setState({ isOffScreenElementOpen: false });
  };

  toggleFavoriteDealersListFilter = () => {
    const { isFavoriteDealersList } = this.state; 
    if(!isFavoriteDealersList) {
      trackEvent(TrackEvent.TRACK_NAMES.DEALER_FAVORITE_MAP.toString(), "", "");
    }
    this.setState({
      isFavoriteDealersList: !isFavoriteDealersList
    });
    this.setState({
      searchQueryText: ""
    });
    this.resetDefaultSearchView();
  };

  updateDealersListForFilterView = () => {
    const { globalSettings } = this.props;
    const { filterSelectedValue, languageMarket, countryCode } = globalSettings;
    const { searchQueryText } = this.state;

    if ( this.isNotUserCurrentLocation() && isNotEmptyString(searchQueryText) && 
     !filterSelectedValue.selectedhLatitude && !filterSelectedValue.selectedLongitude) {
      this.updateDealersListForQueryAndFilter(searchQueryText);
    } else {
    getFilteredDealersListByLatitudeLongitude(
      this.props,
      filterSelectedValue.selectedhLatitude,
      filterSelectedValue.selectedLongitude,
      languageMarket,
      countryCode
    );
   }
  };

 
  private supportedDealerViews: any = {
    DealerSearchView: "",
    DealerFilterView: (
      <DealerFilterView
        toggleOffScreenElement= {this.toggleOffScreenElement}
        resetDefaultSearchView= {this.resetDefaultSearchView}
        updateDealersListForFilterView = {this.updateDealersListForFilterView}
        isRadiusRangeChanged = {this.isRadiusRangeChanged}
        setRadiusRangeChanged = {this.setRadiusRangeChanged}
      />
    ),
    DealerDetailsView: (
      <DealerDetailsView
        toggleOffScreenElement= {this.toggleOffScreenElement}
      />
    ),
    DealerDetailsView2: (
      <DealerDetailsView
        toggleOffScreenElement= {this.toggleOffScreenElement}
      />
    )
  };

  private updateDealersListForQueryAndFilter(searchQueryText: string) {
    const { globalSettings } = this.props;
    const { filterSelectedValue, userLocation, filtersDefaultValue, isBusLocator } = globalSettings;
    let correctSearchQueryText = searchQueryText;
    const correctSearchQueryTextArray = searchQueryText.split(",");
    if (correctSearchQueryTextArray && correctSearchQueryTextArray.length > 0) {
      correctSearchQueryText = correctSearchQueryTextArray[0];
    }
    const { classTypes } = filtersDefaultValue;
    const classValue = filterSelectedValue.onlyDealerService &&
      filterSelectedValue.onlyWorkShopService
      ? classTypes.bothDealerAndWorkshop
      : filterSelectedValue.onlyDealerService
        ? classTypes.onlyDealer
        : filterSelectedValue.onlyWorkShopService
          ? classTypes.onlyWorkshop
          : "";
    this.props.getDealersList({
      dealersListInput: {
        languageMarket: globalSettings.languageMarket,
        searchQueryText: correctSearchQueryText,
        filterQueryCriteria: {
          longitude: userLocation.longitude,
          latitude: userLocation.latitude,
          class: classValue,
          services: filterSelectedValue.selectedServices
        },
        isBusLocator: isBusLocator,
        isDualBranded: isDualBrandedData()
      }
    });
  }
}

export default GlobalSettingsConatiner(SquidexContentConatiner(DealerLocatorConatiner(LocationIQConatiner(DealerLocator))));
