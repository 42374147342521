import styledComponents from "styled-components";

export const div = styledComponents.div`
&.loader {
    position:absolute;
    top:50%;
    z-index: 10;
    left: 50%;
    margin-left: -30px;
    border: 10px solid #D0D0D0; /* Light grey */
    border-top: 10px solid #1351B5; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    display: none;

    &.show {
        display:block;
    }
  }


  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


export const  LoaderStyle = {
    div: div,
};

export  default  LoaderStyle ;