import styledComponents from "styled-components";

export const div = styledComponents.div`
&.appWrapper {

	color: #000;
	font-family: Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	letter-spacing: 0.5pt;
	margin: 0;
	padding: 0;
	-webkit-overflow-scrolling: touch;
	overflow: hidden;

	.bottomLine {
		margin: 0;
		height: 10px;
		background: #D3D3D3;

		@media (max-width: 768px) {		
			height: 4px;
		  }
    }
	
	* {
		box-sizing: border-box;
		-webkit-overflow-scrolling: touch;
	}
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #000;
		font-family: Arial, sans-serif;
		font-weight: bold;
		letter-spacing: 0.75pt;
		margin: 0 0 6.5px;
		padding: 0;
	}
	
	h3 {
		font-size: 18px;
		margin: 0 0 4px;
	}
	
	h4 {
		font-size: 16px;
		margin: 0 0 10px;
	}
	
	.semi {
		font-weight: 600;
	}
	
	a {
		color: #1251b5;
	}

	@media (max-width: 768px) {		
		margin-bottom: 20px;
		padding-bottom: 120px;
	  }


	
}
&.truckConfigurator {
	height:	100vh;

	.p-dealerLocator {
		height:100vh;
		max-height:100vh; 
	}
}
`;


export const  AppStyle = {
	div: div,
};

export  default  AppStyle ;
