import styledComponents from "styled-components";

export const div = styledComponents.div`

&.m-accordion {
  margin: 16px 0;

  .m-accordion__title {
    border-bottom: 1px solid #d0d0d0;
    margin: 10px 0 30px;
    padding: 10px 0;
    position: relative;

    .a-anchor {
      height: 100%;
      left: ;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .a-svgIcon {
      fill: #4D4E53;
      height: 20px;
      width: 20px;
    }
  }
}

`;


export const  AccordionStyle = {
  div: div,
};

export  default  AccordionStyle ;
