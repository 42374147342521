import React, { Component } from 'react';
import SVGPaths from './SVGPaths';
import SVGIconStyle from './SVGIcon.style';


interface Props  {
  className?:string,
  icon:string,
  iconLabel?:string,
  
};

interface State {
}

class SVGIcon extends Component<Props, State>  {
  render() {
    const { className, icon} = this.props;
    const { viewBox, path } = SVGPaths[icon];

    return (
      <SVGIconStyle.svg 
          className={`${className} a-svgIcon`}
          data-icon={icon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
          >
          <path d={path} />
      </SVGIconStyle.svg>
    );
  }
}

 
export default SVGIcon;