import styledComponents from "styled-components";

export const div = styledComponents.div`

&.o-filterDealer { 
  background: #fff;
  flex-wrap: nowrap;
  height: 100%;

  .o-filterDealer__filter {
    padding: 10px 32px 0;
    align-items: center;
    min-height: 50px; /*safari fix*/

    @media screen and (max-width: 1024px) {
      padding: 10px 20px 0;
    }

    @media screen and (max-width: 768px) {
      padding: 10px 16px 0;
    }
 
    h3 {
      text-transform: capitalize;
      margin-bottom: 0; 
    }

    button.close {
      float: right; 
      background: transparent;
      border: none;
      box-shadow: none;
      color: #4D4E53;
      font-size: 28px;
      margin-left: 20px;
      padding: 0;
      flex: 0 0 auto;
    }

    .clearFilter {
      background: transparent;
      border: none;
      box-shadow: none;
      color: #1251B5;
      font-size: 14px;
      margin: 0;
      padding: 0; 
    }
  }

  .o-filterDealer__typeOf {
    flex-wrap: nowrap;
    overflow: hidden;

    .m-filterList__itemsWrapper {
      overflow-y: auto;
    }
  }

  .o-filterDealer__distance {
    align-content: flex-end;
    padding: 24px 0 0;
  }

  .o-filterDealer__typeFiltered {
    overflow: hidden;
  }

  .o-filterDealer__makeChoice {
    border-bottom: 1px solid #909090;
    border-top: 1px solid #909090;
    padding: 16px 32px;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
      padding: 16px 20px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    .a-checkBox {
      margin-right: 32px;
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }
 
  .m-filterList__itemsWrapper {
    color: #404040;
    flex-wrap: nowrap;
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 5px 0 80px;
    overflow

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .m-filterList__item {
    border-bottom: 1px solid #d0d0d0;
    padding: 10px 32px;
    position: relative;

    @media screen and (max-width: 1024px) {
      padding: 10px 20px;
    }

    .a-checkBox {
      padding: 0;
      position: static;
    }

    .a-checkBox__tick {
      display: none;
    }

    .fa {
      color: #00A0CE;
      font-size: 22px;
      margin-right: 24px;
    }
  }
 
  .o-filterDealer__buttonWrapper {
    bottom: 20px;
    left: 0;
    position: absolute;
    width: 100%;
  
    button {
      min-width: 250px;
    }
  }
}

`;

export const DealerFilterViewStyle = {
  div: div
};

export default DealerFilterViewStyle;
