import styledComponents from "styled-components";
export const button = styledComponents.button`

&.a-button  {
  background: #1251B5;
  border: none;
  border-radius: 4px;
  box-shadow: 1px 1px 5px #0006;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: .75pt;
  padding: 9px 32px;
  transition: background .25s ease-in 0s;
  z-index: 1;

  &:hover, &:focus {
    background: #00399D;
  }
}
`;


export const  ButtonStyle = {
    button: button,
};

export  default  ButtonStyle ;





