import React , { Component } from 'react';
import LoaderStyle from './Loader.style';

interface Props {   
    visisble: boolean;
};

interface State {
};

class Loader extends Component<Props, State> {  
    constructor(props: Props) {
        super(props);
    }

  render() {
      const { visisble } = this.props; 
    return (
        <LoaderStyle.div className={`loader ${
            visisble ? "show" : ""
          }`} >
        </LoaderStyle.div>        
    );
  }
}

export default Loader;
