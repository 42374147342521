import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "./ui/assets/styles/fonts.scss"
import "./ui/assets/styles/generalStyles.scss"
import "./ui/assets/styles/flexBoxGrid.scss"
import es6promise from 'es6-promise';
import { AppContainer } from "react-hot-loader";
import { configureStore, GlobalState } from "./store";
import { Provider } from "react-redux";
import { Router } from "react-router";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as globalSettings from "./store/GlobalSettings/store";
import App from "./App";

es6promise.polyfill();

const history = require("history").createBrowserHistory();
export default history;

const w: any = window;

function preloadedState(): GlobalState {
  const defaultState = {
    globalSettings: globalSettings.defaultValue,
    dealersList: {
      isFetching: false,
      isInvalidated: false
    },
    dealersListCtdiId: {
      isFetching: false,
      isInvalidated: false
    },
    dealerDetailsList: {
      isFetching: false,
      isInvalidated: false
    },
    locationsList : {
      isFetching: false,
      isInvalidated: false
    },
    squidexContentsList : {
      isFetching: false,
      isInvalidated: false
    },
    servicesList: {
      isFetching: false,
      isInvalidated: false
    },
    dealersLocationListData: {
      isFetching: false,
      isInvalidated: false
    },

    reverseGeoLocation: {
      isFetching: false,
      isInvalidated: false
    },
    ...w.pds,
  };

  return defaultState;
}
const store = configureStore(preloadedState());

function load() {
  ReactDOM.render((
    <AppContainer>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </AppContainer>
  ), document.getElementById("dealerlocator-root"));
}

// This is needed for Hot Module Replacement
if (module.hot) {
  module.hot.accept("./App", load);
}

load();
