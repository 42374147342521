import React, { Component } from "react";
import LocationListStyle from "./LocationList.style";
import Anchor from "../../../common/atoms/Anchor";
import { LocationInfoData } from "../../../../store/LocationIQ/types";
import { DealerInfoData } from "../../../../store/DealerLocator/types";
import GlobalSettingsConatiner, {
  Props as GlobalSettingsConatinerProps
} from "../../../../containers/GlobalSettings/GlobalSettings";
import SquidexContentConatiner, {
  Props as SquidexContentConatinerProps
} from "../../../../containers/SquidexContent/SquidexContent";
import { LastSearchObjectType, setLastLocationSearchToLocalStorage } from "../../../shared/PropsHelper";
import { apiEndpoints } from "../../../../constants/apiEndpoints";

type Props = GlobalSettingsConatinerProps &
  SquidexContentConatinerProps & {
    iqLocationsListData: Array<LocationInfoData>;
    dealersLocationListData: Array<DealerInfoData>;
    openUpdatedDealerSearchViewLocationSelection: (
      e: React.MouseEvent,
      locationName: string,
      latitude: string,
      longitude: string
    ) => void;
    openUpdatedDealerDetailsViewDealerSelection: (e: React.MouseEvent, dealerId: number) => void;
  };

interface State {}

class LocationList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getLocationList = this.getLocationList.bind(this);
  }

  componentDidMount() {}

  private getLocationList = () => {
    const {
      iqLocationsListData,
      dealersLocationListData,
      openUpdatedDealerSearchViewLocationSelection,
      openUpdatedDealerDetailsViewDealerSelection,
      squidexContentsList, globalSettings
    } = this.props;
     const { isBusLocator } = globalSettings;
    return (
      <ul className="m-locationList__itemsWrapper">
        {dealersLocationListData &&
          dealersLocationListData.length > 0 &&
          dealersLocationListData.map(
            (dealerLocationInfoItem: DealerInfoData, index: number) => {
              const { id, name, city } = dealerLocationInfoItem;
              const displayName = name + `${city ? ", " + city : ""}`;
              const lastSearchDealerObjectType: LastSearchObjectType = {
                type: "dealer",
                dealerId: id,
                dealerName: name
              };

              return (
                <li
                  className="m-locationList__item"
                  key={`dealerLocationListItem_${index}`}
                >
                  <Anchor
                    className="flex align center left"
                    key={"dealerLocation_" + index}
                    action={e => {
                      setLastLocationSearchToLocalStorage(
                        this.props,
                        "lastLocationSearchList",
                        lastSearchDealerObjectType
                        
                      );
                      openUpdatedDealerDetailsViewDealerSelection(e, id);
                    }}
                  >
                    <span className="no-grow">
                      <img
                        src={apiEndpoints.DEALER_API_DOMAIN + apiEndpoints.DEALER_API_ENDPOINTS.GET_CONTENT_ASSESTS.toString()
                          + (isBusLocator === true ? squidexContentsList.busPinImageURL : squidexContentsList.dealerPinImageURL)}
                        alt="Dealer map pin"
                      />
                    </span>
                    <span>{displayName} </span>
                  </Anchor>
                </li>
              );
            }
          )}
        {iqLocationsListData &&
          iqLocationsListData.length > 0 &&
          iqLocationsListData.map(
            (iqLocationInfoItem: LocationInfoData, index: number) => {
              const { address, display_place, lat, lon } = iqLocationInfoItem;

              const displayName =
                display_place + `${address ? ", " + 
                `${address.country}${address.state ? " (" + address.state + ")":
                 (address.county ? " (" + address.county + ")":"")}` : ""}`;

                const lastSearchLocationObjectType: LastSearchObjectType = {
                  type: "location",
                  locationName:displayName,
                  locationLatitude:lat,
                  locationLongitude:lon
                };

              return (
                <li
                  className="m-locationList__item"
                  key={`iqLocationListItem_${index}`}
                >
                  <Anchor
                    className="flex align center left"
                    key={"iqLocation_" + index}
                    action={e => {
                      setLastLocationSearchToLocalStorage(
                        this.props,
                        "lastLocationSearchList",
                        lastSearchLocationObjectType
                      );
                      openUpdatedDealerSearchViewLocationSelection(e, displayName, lat, lon);
                    }}
                  >
                    <span className="no-grow">
                      <i className="fa fa-map-marker" aria-hidden="true" />
                    </span>
                    <span>{displayName} </span>
                  </Anchor>
                </li>
              );
            }
          )}
      </ul>
    );
  };

  render() {
    return (
      <LocationListStyle.div className="m-locationList">
        {this.getLocationList()}
      </LocationListStyle.div>
    );
  }
}

export default GlobalSettingsConatiner(SquidexContentConatiner(LocationList));
