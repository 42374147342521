import { Component, Fragment } from "react";
import * as React from "react";
import DealerLocatorConatiner, {
  Props as DealerLocatorConatinerProps
} from "../../../../../containers/DealerLocator/DealerLocator";
import GlobalSettingsConatiner, {
  Props as GlobalSettingsConatinerProps
} from "../../../../../containers/GlobalSettings/GlobalSettings";
import SquidexContentConatiner, { Props as SquidexContentConatinerProps } 
from "../../../../../containers/SquidexContent/SquidexContent";
import Accordion from '../../../../common/molecules/Accordion';
import Button from "../../../../common/atoms/Button/Button";
import Anchor from "../../../../common/atoms/Anchor";
import Title from '../../../../common/atoms/Title/Title';
import Iframe from '../../../../common/atoms/Iframe/Iframe';
import DealerDetailsViewStyle from "./DealerDetailsView.style";
import commons from "../../../../../constants/commons";
import actionTypes from "../../../../../constants/actionTypes";
import { Contact, OpeningHour, Service } from "../../../../../store/DealerLocator/types";
import { getValueFromMap, getAdjustedUrl, getDirectionUrl, formatHoursTime, getTranslatedText, isObjectEmpty, isDualBrandedData } from "../../../../../utils/commons";
import { DealerDetailsData } from "../../../../../store/DealerLocator/types";
import { getValueFromLocalStorage, setValueToLocalStorage } from '../../../../shared/PropsHelper';
import { trackEvent, trackEventVar } from "../../../../../utils/trackEvent";
import { TrackEvent } from "../../../../../constants/trackEvent";

type Props = GlobalSettingsConatinerProps & SquidexContentConatinerProps &  DealerLocatorConatinerProps & {
  toggleOffScreenElement: () => void ,
};

interface State {

}

interface OpeningHourGroup {
  open?: string;
  close?: string;
  lunchStart?: string;
  lunchEnd?: string;
  start?: number;
  end?: number;
  todayFlag?: boolean;
}

interface OpeningHoursData {
  dayNames: string,
  openingClosingHours: string,
  lunchHours: string,
}

class DealerDetailsView extends Component<Props, State>  {

  /**
   * componentWillReceiveProps
   */
  public componentWillReceiveProps(nextProps: Props) {
    if (
      JSON.stringify(nextProps.dealerDetailsListData) !==
      JSON.stringify(this.props.dealerDetailsListData)
    ) {
      if (nextProps.dealerDetailsListData && nextProps.dealerDetailsListData.length > 0) {
        const dealerDetailsData: DealerDetailsData = nextProps.dealerDetailsListData[0];
        if (dealerDetailsData) {
         // console.log(dealerDetailsData.id, dealerDetailsData.ctdiId);
          trackEventVar(TrackEvent.TRACK_VARS.DEAELR_NAME.toString(), dealerDetailsData.name);
          trackEventVar(TrackEvent.TRACK_VARS.DEALER_CITY.toString(), dealerDetailsData.city);          
          trackEvent(TrackEvent.TRACK_NAMES.DEALER_LOCATED.toString(), dealerDetailsData.name, dealerDetailsData.id.toString());
        }
      }
    }
  }

  public render() {
    const { dealerDetailsListData, squidexContentsList, globalSettings } = this.props;
    const { userLocation, viewName } = globalSettings;
    let openingHours: { [key: string]: OpeningHourGroup[] } = {};
    let dealerDetailsData = {} as DealerDetailsData;


    if (dealerDetailsListData && dealerDetailsListData.length > 0) {
      openingHours = this.getOpeningHours(dealerDetailsListData[0].openingHours);
      dealerDetailsData = dealerDetailsListData[0];
    }

    let contacts = this.getValidContactsContacts(dealerDetailsData);
    let services = this.getValidServices(dealerDetailsData);

    return (

      <Fragment> { !isObjectEmpty(dealerDetailsData) && 
      <DealerDetailsViewStyle.div className="o-viewDealer flex col" >
            <div className="o-viewDealer__wrapper">
              <div className="o-viewDealer__main flex">
                    <React.Fragment>
                      {/* Title, close and favrotite icon */}
                      <div className="twelve align center left s-top flex">
                        <span className="s-nine"><h3>{dealerDetailsData.name}</h3></span>
                        { dealerDetailsData.openStatus ?
                        <div className={`flexhide m-flex nine o-viewDealer__openText ${dealerDetailsData.openStatus === 'open' ? 'openNow' : ''}`}>
                          {getValueFromMap(squidexContentsList.openingStatuses,
                            dealerDetailsData.openStatus)}
                        </div> : <span className={`flexhide m-flex nine o-viewDealer__openText`}></span>}

                        <Anchor className="no-grow o-viewDealer__favo m-three" action={(() => {setValueToLocalStorage(this.props, 'favouriteList', dealerDetailsData.id);
                         this.trackFavouriteEvent(dealerDetailsData.id, dealerDetailsData.name);})}>
                        {getValueFromLocalStorage(this.props, 'favouriteList', dealerDetailsData.id) ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i> }
                        </Anchor>
                        {viewName !== commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW2.toString() && 
                        <Button className="o-viewDealer__close no-grow" action={this.openDealerSearchView} type="submit" >
                          <i className="fa fa-close" aria-hidden="true"></i>
                        </Button> }
                        
                      </div>

                      {/* Address & opening info */}
                      <div className="twelve flex o-viewDealer__address">
                        <div className="eight">
                          {dealerDetailsData.addressLine1 ? <Fragment>{dealerDetailsData.addressLine1} <br /></Fragment> : ''}
                          {dealerDetailsData.addressLine2 ? <Fragment>{dealerDetailsData.addressLine2} <br /></Fragment> : ''}
                          {dealerDetailsData.addressLine3 ? <Fragment>{dealerDetailsData.addressLine3} <br /></Fragment> : ''}
                          {dealerDetailsData.postcode} {dealerDetailsData.city}, {dealerDetailsData.countryName}
                        </div>

                        { dealerDetailsData.openStatus ?
                        <div className={`four l-hide o-viewDealer__openText ${dealerDetailsData.openStatus === 'open' ? 'openNow' : ''}`}>
                          {getValueFromMap(squidexContentsList.openingStatuses,
                            dealerDetailsData.openStatus)}
                        </div> : null}
                      </div>
                      <div className="four">
                        {dealerDetailsData && <Title level={4} type={4} title={''} />}
                      </div>

                      <div className="twelve flex o-viewDealer__links">
                        { dealerDetailsData.website && 
                          <React.Fragment>
                            <div className="four l-twelve m-four s-twelve align top left">
                              <span className="no-grow">
                                <i className="fa fa-external-link" aria-hidden="true"></i>
                              </span>
                              <Anchor href={getAdjustedUrl(dealerDetailsData.website)} action={(e) => {trackEvent(TrackEvent.TRACK_NAMES.DEALER_WEB_REF.toString(), dealerDetailsData.name, dealerDetailsData.id.toString())}}
                              target="_blank">
                                {squidexContentsList.openNewTabText}
                              </Anchor>
                            </div>
                            </React.Fragment>
                        }
                        { dealerDetailsData.phone &&
                        <React.Fragment>
                          <div className="four l-twelve m-four s-twelve align top left">
                            <span className="no-grow">
                              <i className="fa fa-phone" aria-hidden="true"></i>
                            </span>
                            <Anchor href={`tel:${dealerDetailsData.phone}`} action={(e) => {trackEvent(TrackEvent.TRACK_NAMES.DEALER_PHONE.toString(), dealerDetailsData.name, dealerDetailsData.id.toString())}} >
                              {dealerDetailsData.phone}
                            </Anchor>
                          </div>
                          </React.Fragment>
                        }
                          <div className="four l-twelve m-four s-twelve align top left">
                            <span className="no-grow">
                              <i className="fa fa-location-arrow" aria-hidden="true"></i>
                            </span>
                            <Anchor href={getDirectionUrl(userLocation.latitude, userLocation.longitude,dealerDetailsData.latitude, dealerDetailsData.longitude)}
                             action={(e) => {trackEvent(TrackEvent.TRACK_NAMES.DEALER_DIRECTIONS.toString(), dealerDetailsData.name, dealerDetailsData.id.toString())}} target="_blank">
                             {squidexContentsList.getDirectionText}
                            </Anchor>
                          </div>
                      </div>
                    </React.Fragment>
              </div>

              {/* Hours Accordian to show all the contact realted data here */}
              {openingHours ? (
              <Accordion title={squidexContentsList.hoursText} >
                {openingHours && Object.keys(openingHours).map((type: string) => {
                  const openingHoursType = openingHours[type];
                  return (<Fragment key={`openingHours_type_${type}`}>
                      <Title level={5} type={5} title={getValueFromMap(squidexContentsList.openingHourTypes, type)} /> 
                      {openingHoursType.map((openingHour: OpeningHourGroup, index: number) => (
                        this.calculateOpeningHoursData(openingHour, type, index)
                      ))}
                  </Fragment>);
                })}
              </Accordion>) :''}


              {/* Contact Accordian to show all the contact realted data here */}
              {contacts && contacts.length > 0 ? (
              <Accordion title={squidexContentsList.contactsText} className="m-accordion__contacts" contentClassName="flex padding">
                {contacts.map((contactItem: Contact, index: number) => {
                  const {
                    description,
                    code,
                    name,
                    email,
                    phoneNumber,
                    mobileNumber
                  } = contactItem;
                  let phone = phoneNumber || mobileNumber; 

                  return (
                      <div className="m-accordion__contactItem six s-twelve" key={`contactsList_${index}`}>
                        <Title level={4} type={4} title={getTranslatedText(squidexContentsList.contactDescriptions,
                            code,description )} />
                        {name ? (<strong>{name}</strong>) : '' }
                        {phone ? (<Anchor href={`tel:${phone}`} action={(e) => {trackEvent(TrackEvent.TRACK_NAMES.DEALER_PHONE.toString(), dealerDetailsData.name, dealerDetailsData.id.toString())}} className="align center left">
                          <span className="no-grow"><i className="fa fa-phone" aria-hidden="true"></i></span>
                          <span>{phone}</span>
                        </Anchor>) : ''}
                        {email ? (<Anchor href={`mailto:${email}`} action={(e) => {trackEvent(TrackEvent.TRACK_NAMES.DEALER_EMAIL.toString(), dealerDetailsData.name, dealerDetailsData.id.toString())}} target="_blank" className="align top left">
                          <span className="no-grow"><i className="fa fa-envelope" aria-hidden="true"></i></span>
                          <span>{email} </span>
                        </Anchor>) : ''}
                      </div>
                  );
                })}
              </Accordion>) : ''}

              {/* Services Accordian to show all the contact realted data here */}
              {services && services.length > 0 ? (
              <Accordion title={squidexContentsList.servicestext} contentClassName="flex padding">
              {services.map((serviceItem: Service, index: number) => {
                const {
                  id, translatedName,
                  name
                } = serviceItem;
                const servicename = translatedName || name;
                return (
                  <Fragment key={`servicesList_${index}`}>
                    <div className="m-accordion__serviceItem six s-twelve align top left" key={`searchFilterListItem_${id}`}  >
                      <span className="no-grow"><i className="fa fa-wrench" aria-hidden="true"></i></span>
                      <span>{servicename}</span>
                    </div>
                  </Fragment>
                );
              })}
              </Accordion>) : '' } 

              {/* Zooma contact form integration if contactFormEnabled flag is true show contact form otherwise not */}
              { dealerDetailsData.contactFormEnabled === true && <Iframe id="dealer-form" url={commons.ZOOMA_CONTACT_FORM_URL+`${dealerDetailsData.email}`} title="" ></Iframe> }            
    
            </div>
 
            </DealerDetailsViewStyle.div> }
      </Fragment>
    );
  }

  constructor(props: any) {
    super(props);
    this.state = {
      openingHoursData : [] as Array<OpeningHoursData> ,
    };
  }

  /**
   * Method to open the dealer search view with all the necessary 
   * labels and data
   */
  private openDealerSearchView = (e: React.MouseEvent<Element>) => {
    const { globalSettings } = this.props;
    this.props.setViewName(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString(),
      commons.DEALER_VIEW_NAMES.DEALER_SEARCH_VIEW.toString(), globalSettings.dealerId
    );
    this.props.toggleOffScreenElement();
  }

  /**
   * Method to track favourite event when user select the favourite button 
   * do not trigger any event if the button is unselect
   */
  trackFavouriteEvent(id : number, name : string) {
    if(getValueFromLocalStorage(this.props, 'favouriteList',id)){   
      trackEvent(TrackEvent.TRACK_NAMES.DEALER_FAVORITE_LIST.toString(), name, id.toString());
    }
  }
 
  /**
  * Method to open the dealer search view with all the necessary 
  * labels and data
  */
  private calculateOpeningHoursData = (openingHourGroup: OpeningHourGroup, type: string, index: number) => {
    const { squidexContentsList } = this.props;
    return (
      <Fragment key={`openingHours_type_item_${index}`}>
        {openingHourGroup.open && openingHourGroup.close ? (
          <div key={`openingHours_type_item_div_${index}`} className={`m-accordion__hoursItem flex ${openingHourGroup.todayFlag ? 'today': ''}`}>
            <span>{getValueFromMap(squidexContentsList.weekDayNames, ""+(openingHourGroup.start))} {openingHourGroup.end !== undefined && (<span> - {getValueFromMap(squidexContentsList.weekDayNames, ""+(openingHourGroup.end))}</span>)}</span>
            {openingHourGroup.lunchStart  && openingHourGroup.lunchEnd ? (
              <span className="no-grow lunchBreak">({squidexContentsList.lunchBreakText}: {formatHoursTime(openingHourGroup.lunchStart)} - {formatHoursTime(openingHourGroup.lunchEnd)})</span>
            ) : null}
            <span className="no-grow">{formatHoursTime(openingHourGroup.open)} - {formatHoursTime(openingHourGroup.close)}</span>
          </div>) : ''}
      </Fragment>
    );
  }
 
  private getValidContactsContacts(dealer : DealerDetailsData) {
    let contacts = [] as Contact[]; 
    if (dealer && dealer.contacts && dealer.contacts.length > 0) { 
      contacts = isDualBrandedData()
        ? dealer.contacts
        : dealer.contacts.filter((contact) => contact.brandCode !== "120");
    }

    return contacts; 
  }

  private getValidServices(dealer : DealerDetailsData) {
    let services = [] as Service[]; 
    if (dealer && dealer.services && dealer.services.length > 0) {   
      services = isDualBrandedData()
        ? dealer.services.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.name === thing.name
        ))
      )
        : dealer.services.filter((service) => service.brandCode !== "120");
    }

    return services; 
  }

  /**
  * Method to group the opening hours 
  */
  private getOpeningHours(openingHoursResponse: OpeningHour[]) {
    const { squidexContentsList } = this.props;
    const types = ["parts", "sales", "service"];
    const general = ["general"];
    const numericWeekdays: Array<number> = squidexContentsList.openingHoursSequence;
    const openingHours: {[key: number]: OpeningHour} = openingHoursResponse.reduce((memo: {[key: string]: OpeningHour}, ohr) => {
      memo[ohr.weekday] = ohr;
      return memo;
    }, {});
    var hours = types.reduce(hoursReducerFunction, {});
    
    if(hours === null || Object.keys(hours).length === 0) {
      hours = general.reduce(hoursReducerFunction, {});
    }

    function hoursReducerFunction(memo: {[key: string]: OpeningHourGroup[]}, type: string) {
      const results: OpeningHourGroup[] = [];
      let result: OpeningHourGroup = {};
      const today = new Date();
      const todayNumber = today.getDay();
      let todayFlag:boolean = false;
      for (const weekday of numericWeekdays) {
        const day: any = openingHours[weekday] as any;
        if (day){
        const open = day[`${type}Opening`]
        const close = day[`${type}Closing`];
        const lunchStart = day.lunchOpening;
        const lunchEnd = day.lunchClosing;
        
          todayFlag = (todayNumber === weekday ? true : false);         
        
        if (open
          && close
          && result.open === open
          && result.close === close
          && result.lunchStart === lunchStart
          && result.lunchEnd === lunchEnd) {
          result.end = day.weekday;
          result.todayFlag = !result.todayFlag? todayFlag: result.todayFlag;
        } else {
          if (result.open) {
            results.push(result);
          }
          result = {
            start: day.weekday,
            end: undefined,
            open,
            close,
            lunchStart,
            lunchEnd,
            todayFlag: todayFlag,
          };
        }
      }
      }
      if (result.open) {
        results.push(result);
      }
      if (results.length) {
        memo[type] = results;
      }
      return memo;
    };

    return hours;
  }
}


export default GlobalSettingsConatiner(SquidexContentConatiner(
  DealerLocatorConatiner(DealerDetailsView))
);
