import { Component } from "react";
import * as React from "react";
import Button from "../../../../common/atoms/Button/Button";
import Checkbox from "../../../../common/atoms/CheckBox/CheckBox";
import DealerFilterViewStyle from "./DealerFilterView.style";

import DealerLocatorConatiner, {
  Props as DealerLocatorConatinerProps
} from "../../../../../containers/DealerLocator/DealerLocator";
import GlobalSettingsConatiner, {
  Props as GlobalSettingsConatinerProps
} from "../../../../../containers/GlobalSettings/GlobalSettings";
import SquidexContentConatiner, { Props as SquidexContentConatinerProps }
  from "../../../../../containers/SquidexContent/SquidexContent";

import LocationIQConatiner, {
  Props as LocationIQConatinerProps
} from "../../../../../containers/LocationIQ/LocationIQ";

import actionTypes from "../../../../../constants/actionTypes";
import commons from "../../../../../constants/commons";
import { Service } from "../../../../../store/DealerLocator/types";
import { isArraysEquals } from "../../../../../utils/commons";

type Props = GlobalSettingsConatinerProps & SquidexContentConatinerProps & LocationIQConatinerProps & DealerLocatorConatinerProps & {
  toggleOffScreenElement: () => void;
  resetDefaultSearchView: () => void;
  updateDealersListForFilterView: () => void;
  isRadiusRangeChanged: () => boolean;
  setRadiusRangeChanged: (flag: boolean) => void;
};

interface State {
  onlyDealerService: boolean;
  onlyWorkShopService: boolean;
  selectedServices: number[];
  lngService : Service | undefined;
  electricService: Service | undefined;
  isBusLocator: boolean;
}


class DealerFilterView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      lngService: this.getFilterFromCode("7408"),
      electricService: this.getFilterFromCode("7410"),
      isBusLocator: props.globalSettings.isBusLocator,
      onlyDealerService:
        props.globalSettings.filterSelectedValue.onlyDealerService,
      onlyWorkShopService:
        props.globalSettings.filterSelectedValue.onlyWorkShopService,
      selectedServices: [
        ...props.globalSettings.filterSelectedValue.selectedServices
      ],
    };
    this.openDealerSearchView = this.openDealerSearchView.bind(this);
  }

  private setServicesDataState = (value: any) => {
    const { selectedServices } = this.state;
    let values = selectedServices || [];
    if (values && values.length > 0) {
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(value);
      }
    } else {
      values.push(value);
    }
    this.setState({ selectedServices: values });
  };

  private getFilterFromCode = (code: any) => {
    const {
      servicesListData
    } = this.props;
    let service : Service | undefined; 

    if(servicesListData &&
    servicesListData.value &&
    servicesListData.value.length > 0) {
      service = servicesListData.value.find(e => e.code == code);     
    }
    return service;
  }

  /***
   * This method is used to fetch the event value from the checkbox and set  it to the local storage
   */
  onEventChange = (key: string, event: any) => {
    if (key === "onlyDealerService") {
      this.setState({ onlyDealerService: event.target.checked });
    } else if (key === "onlyWorkShopService") {
      this.setState({ onlyWorkShopService: event.target.checked });
    } else if (key === "lngSelected") {
     this.setServicesDataState(this.state.lngService?.id);
    } else if (key === "electricSelected") {
     this.setServicesDataState(this.state.electricService?.id);
    }
  };

  reloadGlobalSettings = () => {
    this.props.setGlobalSettingsState(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_DATA.toString(),
      this.props.globalSettings
    );
  };

  /**
   * Method to open the dealer search view with all the necessary
   * labels and data
   */
  private openDealerSearchView = (e: React.MouseEvent<Element>) => {
    const { globalSettings } = this.props;
    const { filterSelectedValue } = globalSettings;
    const { selectedServices } = this.state;
    this.setState({
      selectedServices: filterSelectedValue.selectedServices,
      onlyDealerService: filterSelectedValue.onlyDealerService,
      onlyWorkShopService: filterSelectedValue.onlyWorkShopService
    });
    this.props.setViewName(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_VIEW_NAME.toString(),
      commons.DEALER_VIEW_NAMES.DEALER_SEARCH_VIEW.toString(),
      globalSettings.dealerId
    );
    this.props.toggleOffScreenElement();
  };


   /**
   * Method to open the dealer search view with all the necessary
   * labels and data
   */
  private clearAllFilters = () => {
    this.setState({selectedServices: [], onlyDealerService:false, onlyWorkShopService: false});
  };

  /**
   * Method to open the dealer search view with all the necessary
   * labels and data
   */
  private getFilterDealersList(e: React.MouseEvent<Element>) {
    const { filterSelectedValue } = this.props.globalSettings;
    let noOfFilter: number = 0;

    if (this.state.selectedServices && this.state.selectedServices.length > 0) {
      noOfFilter = noOfFilter + this.state.selectedServices.length;
    }
    if (this.state.onlyDealerService) {
      noOfFilter = noOfFilter + 1;
    }
    if (this.state.onlyWorkShopService) {
      noOfFilter = noOfFilter + 1;
    }

    filterSelectedValue.noOfFilter = noOfFilter;
    filterSelectedValue.onlyDealerService = this.state.onlyDealerService;
    filterSelectedValue.onlyWorkShopService = this.state.onlyWorkShopService;
    filterSelectedValue.selectedServices = this.state.selectedServices;
   // filterSelectedValue.electricSelected = this.state.selectedServices?.indexOf(this.state.electricId) != -1;
    //filterSelectedValue.lngSelected = this.state.selectedServices?.indexOf(this.state.lngId) != -1;

    this.reloadGlobalSettings();
    this.refershDealersList();
  }

  private refershDealersList() {
    const { updateDealersListForFilterView } = this.props;
    updateDealersListForFilterView();
    this.props.setRadiusRangeChanged(false);
    this.props.toggleOffScreenElement();
    this.props.resetDefaultSearchView();
  }

  /**
   * flag to show the autocomplte search results
   *  based on the given condtion
   */
  isShowApplyButton() {
    let viewFlag: boolean = false;
    const { globalSettings, isRadiusRangeChanged } = this.props;
    const { filterSelectedValue } = globalSettings;
    const isRadiusRangeChangedFlag = isRadiusRangeChanged();
    const {
      onlyDealerService,
      onlyWorkShopService,
      selectedServices
    } = this.state;
    if (
      onlyDealerService !== filterSelectedValue.onlyDealerService ||
      onlyWorkShopService !== filterSelectedValue.onlyWorkShopService ||
      (selectedServices &&
        filterSelectedValue.selectedServices &&
        !isArraysEquals(
          selectedServices,
          filterSelectedValue.selectedServices
        )) ||
        isRadiusRangeChangedFlag
    ) {
      viewFlag = true;
    }

    return viewFlag;
  }

  public render() {
    const {
      squidexContentsList,
      servicesListData
    } = this.props;
    const {
      onlyDealerService,
      onlyWorkShopService,
      selectedServices
    } = this.state;

    const isShowApplyButton = this.isShowApplyButton();
    return (
      <DealerFilterViewStyle.div className="o-filterDealer flex col">
        {/* Clear all button */}
        <div className="o-filterDealer__filter flex no-grow align center left">
          <h3>{squidexContentsList.filtersHeading}</h3>
          <Button
              className="clearFilter no-grow"
              action={this.clearAllFilters}
              type="submit"
            >
              {squidexContentsList.clearAll}
          </Button>
          <Button
            className="close"
            action={this.openDealerSearchView}
            type="submit"
          >
            <i className="fa fa-close" aria-hidden="true" />
          </Button>
        </div>

        {/* Type of filter */}
        <div className="o-filterDealer__typeOf flex col ">
          <div className="o-filterDealer__makeChoice no-grow align center left">
            <Checkbox
              name="onlyWorkShopService"
              inputAriaLabel="Show dealers"
              label={squidexContentsList.dealersLabel}
              checked={onlyDealerService}
              onChange={e => this.onEventChange("onlyDealerService", e)}
            />
            <Checkbox
              name="onlyWorkShopService"
              inputAriaLabel="Show workshops"
              label={squidexContentsList.workshopsLabel}
              checked={onlyWorkShopService}
              onChange={e => this.onEventChange("onlyWorkShopService", e)}
            />
            {!this.state.isBusLocator && this.state.lngService !== undefined ? (
                          <Checkbox
                          name="LNGService"
                          inputAriaLabel={this.state.lngService.translatedName ? this.state.lngService.translatedName : this.state.lngService.name }
                          label= {/*squidexContentsList.lngLabel || */this.state.lngService.translatedName || this.state.lngService.name}
                          checked={this.state.selectedServices?.indexOf(this.state.lngService.id) !== -1}
                          onChange={e => this.onEventChange("lngSelected", e)}
                        />
                 ) : (
                          ""
             )}
            {!this.state.isBusLocator && this.state.electricService !== undefined ? (
              <Checkbox
                name="electricityService"
                inputAriaLabel={this.state.electricService.translatedName ? this.state.electricService.translatedName : this.state.electricService.name }
                label={/*squidexContentsList.electricityLabel || */this.state.electricService.translatedName || this.state.electricService.translatedName }
                checked={this.state.selectedServices?.indexOf(this.state.electricService.id) !== -1}
                onChange={e => this.onEventChange("electricSelected", e)}
              />
            ) : (
              ""
          )}
          </div>

          {/* Filter list */}
          <div className="o-filterDealer__typeFiltered flex col">
            <ul className="m-filterList__itemsWrapper">
              {servicesListData &&
                servicesListData.value &&
                servicesListData.value.length > 0 &&
                servicesListData.value.map(
                  (serviceItem: Service, index: number) => {
                    const { id, name, translatedName } = serviceItem;

                    return (
                      <li
                        id={`serviceListItem_${index}`}
                        className={`m-filterList__item align center left flex`}
                        key={`serviceListItem_${index}`}
                      >
                        <span className="no-grow">
                          <i className="fa fa-wrench" aria-hidden="true" />
                        </span>
                        <Checkbox
                          name={`serviceItem_${id}`}
                          checked={selectedServices.indexOf(id) !== -1}
                          onChange={e => this.setServicesDataState(id)}
                          
                          label={translatedName ? translatedName : name }
                          inputAriaLabel={translatedName ? translatedName : name}
                        />
                        {selectedServices.indexOf(id) !== -1 ? (
                          <span className="no-grow">
                            <i className="fa fa-check" aria-hidden="true" />
                          </span>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  }
                )}
            </ul>
          </div>
        </div>

        {/* Button */}
        <div
          className={`o-filterDealer__buttonWrapper align center no-grow ${
            !isShowApplyButton ? "hide" : ""
          }`}
        >
          <Button
            action={e => this.getFilterDealersList(e)}
            label={squidexContentsList.applyLabel}
            type="submit"
          />
        </div>
      </DealerFilterViewStyle.div>
    );
  }
}

export default GlobalSettingsConatiner(SquidexContentConatiner(LocationIQConatiner(
  DealerLocatorConatiner(DealerFilterView)))
);
