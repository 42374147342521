import { Component } from "react";
import * as React from "react";
import Anchor from '../../../common/atoms/Anchor';
import DealerLocatorConatiner, { Props as DealerLocatorConatinerProps } from "../../../../containers/DealerLocator/DealerLocator";
import GlobalSettingsConatiner, { Props as GlobalSettingsConatinerProps } from "../../../../containers/GlobalSettings/GlobalSettings";
import SquidexContentConatiner, { Props as SquidexContentConatinerProps } from "../../../../containers/SquidexContent/SquidexContent";
import LocationIQConatiner, { Props as LocationIQConatinerProps } from "../../../../containers/LocationIQ/LocationIQ";
import LocationList from "../../../common/molecules/LocationList";
import LastSearchObjectList from "../../../common/molecules/LastSearchObjectList";
import actionTypes from "../../../../constants/actionTypes";
import commons from "../../../../constants/commons";
import { setLastLocationSearchToLocalStorage, getLastLocationSearchFromLocalStorage, LastSearchObjectType } from '../../../shared/PropsHelper';
import { LocationInfoData } from "../../../../store/LocationIQ/types";
import { isNotEmptyString } from "../../../../utils/commons";

type Props = GlobalSettingsConatinerProps & SquidexContentConatinerProps & LocationIQConatinerProps & DealerLocatorConatinerProps & {
  openDealerDetailsView: (e: React.MouseEvent, dealerId: number) => void;
  openUpdatedDealerSearchViewLocationSelection: (e: React.MouseEvent, locationName: string, latitude: string, longitude: string, ) => void;
  searchQueryText: string,
  openUpdatedDealerSearchViewTextSelection: (searchQueryText:string) => void;
  getDealersListForUserCurrentLocation: () => void;
};

interface State {
}

class DealerLocationSearchView extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  /**
   * Method to open the dealer details view with all the necessary
   * labels and data
   */
  onRangeChange = (key: string, value: string) => {
    const { filterSelectedValue } = this.props.globalSettings;
    filterSelectedValue.selectedRange = value;
    this.reloadGlobalSettings();
  };

  reloadGlobalSettings = () => {
    this.props.setGlobalSettingsState(
      actionTypes.GLOBAL_SETTINGS_ACTIONS.SET_GLOBAL_SETTINGS_DATA.toString(),
      this.props.globalSettings
    );
  };


  /**
   * flag to show the autocomplte search results 
   *  based on the given condtion
   */
  isShowAutoCompleteSearchResults() {
    const {
      searchQueryText,
      iqLocationsListData,
      dealersLocationListData,
      iqLocationsListErrors,
      dealersLocationListError
    } = this.props;
    let viewFlag: boolean = false;
    if ( isNotEmptyString(searchQueryText, commons.NUMBER_TWO) &&
      ((iqLocationsListData && iqLocationsListData.length > 0) ||
        (dealersLocationListData &&
          dealersLocationListData.value &&
          dealersLocationListData.value.length > 0 &&
          !(
            iqLocationsListErrors &&
            iqLocationsListErrors.length > 0 &&
            dealersLocationListError &&
            dealersLocationListError.length > 0
          )))
    ) {
      viewFlag = true;
    }

    return viewFlag;
  }

  public render() {
    const {
      globalSettings,
      iqLocationsListData,
      squidexContentsList,
      dealersLocationListData,
      searchQueryText,
      openDealerDetailsView,
      openUpdatedDealerSearchViewLocationSelection,
      openUpdatedDealerSearchViewTextSelection,
      getDealersListForUserCurrentLocation
    } = this.props;
    const { userLocation } = globalSettings;

    const lastSearchObjectList: Array<LastSearchObjectType> = getLastLocationSearchFromLocalStorage(this.props, "lastLocationSearchList");
    let updatedIQLocationsListData: Array<LocationInfoData> = [];
    if ( isNotEmptyString(searchQueryText) && iqLocationsListData) {
      updatedIQLocationsListData = iqLocationsListData.filter(
        x => x.class && x.class === "place"
      );
    }

    return (
      <React.Fragment>
        <div className="o-searchDealer__locationSearch flex col">
          {/* Search by current location */}
          <div className="o-locationSearch__currentLocation flex no-grow">
            <Anchor
              action={e => {
                setLastLocationSearchToLocalStorage(
                  this.props,
                  "lastLocationSearchList",
                  {
                    type: "my_location",
                    locationName: squidexContentsList.yourCurrentLocationText,
                    locationLatitude: userLocation.latitude,
                    locationLongitude: userLocation.longitude
                  }
                );
                getDealersListForUserCurrentLocation();
              }}
            >
            {/* {No children reqquired here} */}
            </Anchor>
            <span className="no-grow">
              <i className="fa fa-location-arrow" aria-hidden="true" />
            </span>
            <span>{squidexContentsList.yourCurrentLocationText}</span>
          </div>

          {/* Search by quicksearch options */}
          {this.isShowAutoCompleteSearchResults() ? (
            <div className="o-locationSearch__quickSearch no-grow flex col">
              <div className="o-locationSearch__filterHeading no-grow">
                <h4>
                  {squidexContentsList.quickSearchText} 
                </h4>
              </div>
              <LocationList
                iqLocationsListData={updatedIQLocationsListData || []}
                dealersLocationListData={
                  (dealersLocationListData &&
                    dealersLocationListData.value) ||
                  []
                }
                openUpdatedDealerSearchViewLocationSelection={
                  openUpdatedDealerSearchViewLocationSelection
                }
                openUpdatedDealerDetailsViewDealerSelection={
                  openDealerDetailsView
                }
              />
            </div>
          ) : (
            // Search by latest options
            <div className="o-locationSearch__latestSearch no-grow">
              {lastSearchObjectList && lastSearchObjectList.length > 0 ? (
                <React.Fragment>
                  <div className="o-locationSearch__filterHeading">
                    <h4>
                      {squidexContentsList.latestSearchText}
                    </h4>
                  </div>
                  <LastSearchObjectList
                    lastSearchObjectList={lastSearchObjectList}
                    openUpdatedDealerDetailsViewDealerSelection={
                      openDealerDetailsView
                    }
                    openUpdatedDealerSearchViewLocationSelection={
                      openUpdatedDealerSearchViewLocationSelection
                    }
                    openUpdatedDealerSearchViewTextSelection={
                      openUpdatedDealerSearchViewTextSelection
                    }
                    getDealersListForUserCurrentLocation={
                      getDealersListForUserCurrentLocation
                    }
                  />
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          )}
        </div>

        
      </React.Fragment>
    );
  }
}

export default GlobalSettingsConatiner(SquidexContentConatiner(LocationIQConatiner(
  DealerLocatorConatiner(DealerLocationSearchView)))
);
