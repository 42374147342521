import { css } from 'styled-components';
//@ts-ignore
import { transform } from 'cssjanus';
//@ts-ignore
function cssToString(cssStyles, p) {
    //@ts-ignore
  return cssStyles.reduce((result, v) => {
    let addition = v
    if (typeof v === 'function') {
      const vResult = v(p)
      addition = typeof vResult === 'string' ? vResult : cssToString(vResult, p)
    }
    return result + addition
  }, '')
}
//@ts-ignore
const rtl = (...styles) => p => {
  //@ts-ignore
  const cssStyles = css(...styles)
  const dir = p.dir || p.theme.dir
  return dir === 'rtl' ? transform(cssToString(cssStyles, p)) : cssStyles
}
 
export default rtl