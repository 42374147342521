import React, { Fragment } from 'react';
import { Marker} from 'react-map-gl';
import MapBoxPin from './MapBoxPin';
import { LocationPinData } from "../../../../store/GlobalSettings/types";

interface Props {
  locationPinData: LocationPinData,
  dealerPinImageURL: string;
}

const MARKER_ICON_HEIGHT = 30;
const MARKER_ICON_WIDTH = 30;
export default class MapBoxLayer extends React.Component<Props> {

  renderMarker = (locationPinData: LocationPinData, index:number) => {
    const { dealerPinImageURL } = this.props;
    const offsetLeft  = -MARKER_ICON_WIDTH/2;
    const offsetTop   = -MARKER_ICON_HEIGHT;
    if (locationPinData.coordinates && locationPinData.coordinates.length > 0 &&
      locationPinData.coordinates[0][0] && locationPinData.coordinates[0][1]) {
      return (
        <Marker 
          key={`layer-marker-${index}`}          
          latitude={locationPinData.coordinates[0][0]} 
          longitude={locationPinData.coordinates[0][1]} 
          offsetLeft={offsetLeft} 
          offsetTop={offsetTop} >
          <MapBoxPin dealerPinImageURL={dealerPinImageURL} index={index} locationPinData={locationPinData} />
        </Marker>
      );
    }
    return null;
    
  }

  render() {
    const { locationPinData } = this.props;
    return (
      <Fragment key={`map-range-circle`}>

      {locationPinData.coordinates && locationPinData.coordinates.length > 0  ? ( 
        <div>
          {this.renderMarker(locationPinData,1)}
      </div>) : ''}
     
      </Fragment>
    );
  }
}

