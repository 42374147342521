// import { Reducer } from "redux";
import { LocationIQResponse, ReverseGeoLocationResponse } from "../Models/types";
import { makeAsyncActionCreator } from "../makeAsyncActionCreator";
import { GlobalState } from "..";
import actionTypes from "../../constants/actionTypes";
import commons from "../../constants/commons";
import apiEndpoints from "../../constants/apiEndpoints";
import queryParams from "../../constants/queryParams";


export interface LocationsListActionType {
    searchQueryText?: string;
    mapViewboxBoundText?: string;
    countryCode?: string;
}


export interface LocationsListInput {
    locationsListInput: LocationsListActionType;

}

export interface ReverseGeoLocationInput {
    latitude: string;
    longitude: string;

}

export const actionCreatorLocationsList= makeAsyncActionCreator<LocationsListInput, LocationIQResponse>()({
    requestType: actionTypes.LOCATIONIQ_ACTIONS.GET_AUTOCOMPLETE_DATA_REQUEST.toString(),
    failureType: actionTypes.LOCATIONIQ_ACTIONS.GET_AUTOCOMPLETE_DATA_FAILURE.toString(),
    successType: actionTypes.LOCATIONIQ_ACTIONS.GET_AUTOCOMPLETE_DATA_SUCCESS.toString(),
    getSection: (state: GlobalState) => state.locationsList,
    inputToHttpRequest: (input: LocationsListInput) => {
        let inputQueryText = "";

        if (input.locationsListInput.searchQueryText) {
          inputQueryText += `${commons.AMPERSAND}${
            queryParams.LOCATION_IQ.SEARCH_QUERY_Q
          }${commons.EQUALS}${
            input.locationsListInput.searchQueryText
          }`;
        }
        if (input.locationsListInput.mapViewboxBoundText) {
          inputQueryText += `${commons.AMPERSAND}${
            queryParams.LOCATION_IQ.MAP_VIEWBOX
          }${commons.EQUALS}${input.locationsListInput.mapViewboxBoundText}`;
          inputQueryText += `${commons.AMPERSAND}${
            queryParams.LOCATION_IQ.BOUNDED
          }${commons.EQUALS}1`;
        }

        return {
            url: `${apiEndpoints.LOCATIONIQ_AUTOCOMPLETE_ENDPOINT}${commons.MAP_ACCESS_TOKEN}${inputQueryText}`,
            request: {},
        };
    },
    getInputKey: (input: LocationsListInput) => `GetLocationsIQList-${Date.now()}`,
    successHandler: (input: LocationsListInput, dalerData: LocationIQResponse) => {

    },
});


export const actionCreatorReverseGeoLocation= makeAsyncActionCreator<ReverseGeoLocationInput, ReverseGeoLocationResponse>()({
    requestType: actionTypes.LOCATIONIQ_ACTIONS.GET_REVERSE_GEOLOCATION_DATA_REQUEST.toString(),
    failureType: actionTypes.LOCATIONIQ_ACTIONS.GET_REVERSE_GEOLOCATION_DATA_FAILURE.toString(),
    successType: actionTypes.LOCATIONIQ_ACTIONS.GET_REVERSE_GEOLOCATION_DATA_SUCCESS.toString(),
    getSection: (state: GlobalState) => state.reverseGeoLocation,
    inputToHttpRequest: (input: ReverseGeoLocationInput) => {
        let queryCriteria = '';
 
        // User current location based on latitude and longitude reverse geo location            
        if (input.latitude) {
            queryCriteria += `${commons.AMPERSAND}${queryParams.LOCATION_IQ.LATITUDE.toString()}${commons.EQUALS}${input.latitude}`;
        } if (input.longitude) {
            queryCriteria += `${commons.AMPERSAND}${queryParams.LOCATION_IQ.LONGITUDE.toString()}${commons.EQUALS}${input.longitude}`;
        }
        queryCriteria +=`${commons.AMPERSAND}${queryParams.FORMAT_JSON}`;

        return {
            url: `${apiEndpoints.LOCATIONIQ_REVERSE_GEO_LOCATION_ENDPOINT}${commons.MAP_ACCESS_TOKEN}${queryCriteria}`,
            request: {},
        };
    },
    getInputKey: (input: ReverseGeoLocationInput) => "GetReverseGeoLocation-" + Date.now(),
    successHandler: (input: ReverseGeoLocationInput, dalerData: ReverseGeoLocationResponse) => {
       
    },
});


export const fetchLocationsListIfNeeded = actionCreatorLocationsList.fetchIfNeeded;
export const fetchReverseGeoLocationIfNeeded = actionCreatorReverseGeoLocation.fetchIfNeeded;
export type FetchIfNeededLocationsList = typeof fetchLocationsListIfNeeded;



export const reducer = {
    locationsList: actionCreatorLocationsList.reducer,
    reverseGeoLocation: actionCreatorReverseGeoLocation.reducer,
};
