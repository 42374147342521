import { connect } from "react-redux";
import { GlobalState } from "../../store";
import { LocationInfoData, ReverseGeoLocationData } from "../../store/LocationIQ/types";
import * as LocationIQStore from  "../../store/LocationIQ/store";
import { bindActionCreators, Dispatch } from "redux";
import { Error } from "../../store/Models/types";

export interface LocalProps {
    iqLocationsListData? :  Array<LocationInfoData>,
    reverseGeoLocationData? :  ReverseGeoLocationData,    
    iqLocationsListErrors: Error[],
}

const actions = {
    getIQLocationsListWithQuery: LocationIQStore.fetchLocationsListIfNeeded,
    getReverseGeoLocation: LocationIQStore.fetchReverseGeoLocationIfNeeded,
 
};

export type Props = typeof actions & LocalProps;

function mapStateToProps(state: GlobalState) : LocalProps {
    return {
        iqLocationsListData: state.locationsList.value || [],
        reverseGeoLocationData: state.reverseGeoLocation.value,
        iqLocationsListErrors: state.locationsList.errors || [],
    };
}

export default connect(
    mapStateToProps,
    (dispatch: Dispatch) => bindActionCreators(actions, dispatch));
