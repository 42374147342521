import { connect } from "react-redux";
import { GlobalState } from "../../store";
import { SquidexContentData } from "../../store/SquidexContent/types";
import * as SquidexContentStore from  "../../store/SquidexContent/store";
import { bindActionCreators, Dispatch } from "redux";

export interface LocalProps {
    squidexContentsList:  SquidexContentData,
}

const actions = {
    getSquidexContentsList: SquidexContentStore.fetchSquidexContentsListIfNeeded,
 
};

export type Props = typeof actions & LocalProps;

function mapStateToProps(state: GlobalState) : LocalProps {
    return {
        squidexContentsList: state.squidexContentsList.value || SquidexContentStore.defaultValue,
    };
}

export default connect(
    mapStateToProps,
    (dispatch: Dispatch) => bindActionCreators(actions, dispatch));
