export enum TRACK_NAMES {
    DEALER_CLICK = "dealerClick" as any,
    DEALER_LOCATED = "dealerLocated" as any,
    DEALER_DIRECTIONS = "dealerDirections" as any,
    DEALER_PHONE = "dealerPhone" as any,
    DEALER_EMAIL = "dealerEmail" as any,
    DEALER_FAVORITE_LIST = "dealerFavoriteList" as any,    
    DEALER_FAVORITE_MAP = "dealerFavoriteMap" as any,
    DEALER_WEB_REF = "dealerWebRef" as any
}

export enum TRACK_VARS {
    DEAELR_NAME = "dealerName" as any,
    DEALER_CITY = "dealerCity" as any,
}

export const  TrackEvent = {
    TRACK_NAMES: TRACK_NAMES,
    TRACK_VARS: TRACK_VARS
    
};

export  default  TrackEvent ;