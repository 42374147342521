import { connect } from "react-redux";
import { GlobalState } from "../../store";
import { DealersListData,  DealerDetailsListData, DealerDetailsData,  ServicesListData, DealersLocationListData, DealerInfoData } from "../../store/DealerLocator/types";
import * as DealerLocatorStore from  "../../store/DealerLocator/store";
import { bindActionCreators, Dispatch } from "redux";
import { Error } from "../../store/Models/types";
import { isSalesDealersOnlyData } from "../../utils/commons";

export interface LocalProps {
    dealersListData? :  DealersListData,    
    dealersLocationListData? : DealersLocationListData;
    dealersLocationListError:  Error[],
    dealerDetailsListData?  :  DealerDetailsListData,
    servicesListData? :  ServicesListData,
    favoriteDealersListData? :  DealersListData, 
    returnDealerId?: string,
    fetchingDealerList?: boolean
}

export const classTypes = {
    onlyDealer: "A",
    bothDealerAndWorkshop: "B",
}

const actions = {
    getDealersList: DealerLocatorStore.fetchDealersListIfNeeded,  
    getDealersListCtdiId: DealerLocatorStore.fetchCreatorDealersListForCtdiIdIfNeeded,  
    getDealerLocationsList: DealerLocatorStore.fetchDealersListWithSearchQueryIfNeeded,
    getDealerDetails: DealerLocatorStore.fetchDealerDetailsIfNeeded,
    getServices: DealerLocatorStore.fetchServicesListIfNeeded,
    getFavoriteDealersList: DealerLocatorStore.fetchFavoriteDealersListIfNeeded,   
 
};

function _getDealerId(state: GlobalState): string {
    let returnDealerId = "";
    if (state && state.dealersListCtdiId && state.dealersListCtdiId.value) {
        const dealersListCtdiId: DealersListData = state.dealersListCtdiId.value;
        if (dealersListCtdiId && dealersListCtdiId.value && dealersListCtdiId.value.length > 0) {
            returnDealerId = "" + dealersListCtdiId.value[0].id;
        }
    }

    return returnDealerId;

}

function _filterDealersListData(
  dealersDataValue: DealersListData | undefined
): DealersListData | undefined {
  if (
    isSalesDealersOnlyData() &&
    dealersDataValue &&
    dealersDataValue.value &&
    dealersDataValue.value.length > 0
  ) {
    const fileteredValues = dealersDataValue.value.filter(
      (data: DealerInfoData) =>
        data &&
        data.class &&
        (classTypes.onlyDealer === data.class.trim() ||
          classTypes.bothDealerAndWorkshop === data.class.trim())
    );

    return { ...dealersDataValue, value: fileteredValues };
  }

  return dealersDataValue;
}

  function _filterDealersLocationListData(
    dealersLocationDataValue: DealersLocationListData | undefined
  ): DealersLocationListData | undefined {
    if (
      isSalesDealersOnlyData() &&
      dealersLocationDataValue &&
      dealersLocationDataValue.value &&
      dealersLocationDataValue.value.length > 0
    ) {
      const fileteredValues = dealersLocationDataValue.value.filter(
        (data: DealerInfoData) =>
          data &&
          data.class &&
          (classTypes.onlyDealer === data.class.trim() ||
            classTypes.bothDealerAndWorkshop === data.class.trim())
      );

      return { ...dealersLocationDataValue, value: fileteredValues };
    }

    return dealersLocationDataValue;
  }

  function _filterDealerDetailsListData(
    dealerDetailsListDataValue: DealerDetailsListData | undefined
  ): DealerDetailsListData | undefined {
    if (
      isSalesDealersOnlyData() &&
      dealerDetailsListDataValue &&
      dealerDetailsListDataValue.length > 0
    ) {
      const dealerDetailsDataValue: DealerDetailsData =
        dealerDetailsListDataValue[0];
      if (
        dealerDetailsDataValue &&
        dealerDetailsDataValue.class &&
        (classTypes.onlyDealer === dealerDetailsDataValue.class.trim() ||
          classTypes.bothDealerAndWorkshop ===
            dealerDetailsDataValue.class.trim())
      ) {
        return dealerDetailsListDataValue;
      } else {
        return [];
      }
    }

    return dealerDetailsListDataValue;
  }

export type Props = typeof actions & LocalProps;

function mapStateToProps(state: GlobalState) : LocalProps {
    return {
        dealersListData:  _filterDealersListData(state.dealersList.value),           
        dealersLocationListData:  _filterDealersLocationListData(state.dealersLocationListData.value),            
        dealersLocationListError: state.dealersLocationListData.errors  || [],  
        dealerDetailsListData:  _filterDealerDetailsListData(state.dealerDetailsList.value) || [],        
        servicesListData: state.servicesList.value,                   
        favoriteDealersListData:  _filterDealersListData(state.favoriteDealersList.value),      
        returnDealerId: _getDealerId(state),
        fetchingDealerList: state.dealersList.isFetching
    };
}

export default connect(
    mapStateToProps,
    (dispatch: Dispatch) => bindActionCreators(actions, dispatch));
    
