
import styledComponents from "styled-components";

export const div = styledComponents.div`

&.o-offScreen__wrapper {
  z-index: 100;

  &[aria-expanded='false'] {
    background: rgba(0, 0, 0, 0.2);
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    transition: opacity 0.25s cubic-bezier(0.165,0.84,0.44,1);
    white-space: nowrap;
    width: 1px;

    @media screen and (max-width: 1024px) {
      position: fixed;
    }
  }

   &[aria-expanded='true'] {
      background: rgba(0, 0, 0, 0.2);
      height: 100%;
      left:0;
      opacity: 1;
      position: absolute;
      top:0;
      transition: opacity 0.25s cubic-bezier(0.165,0.84,0.44,1);
      width: 100%;

      @media screen and (max-width: 1024px) {
        position: fixed;
      }
    }

  .o-offScreen {
    height: 100%;
    left: -400px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 100%;
    z-index: 1001;

    &.isOpen {
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(400px, 0, 0);
      transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  .o-offScreen__close {
    position: absolute;
    right: 24px;
    top: 16px;
    z-index: 2;

    .a-svgIcon {
      height: 20px;
      width: 20px;
    }

  }

  .o-offScreen__content {
    background: #f2f2f2;
    bottom: 0;
    color: #000;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .o-offScreen__overlay {
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

 
`;


export const  OffScreenElementStyle = {
  div: div,
};

export  default  OffScreenElementStyle ;
  