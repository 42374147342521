import React, { Component } from 'react';
import IframeStyle from "./Iframe.style";

export interface IframeProps {
  id: string;
  url: string;
  title: string;
}

class Iframe extends Component<IframeProps> {
  private iframeRef: any;
  constructor(props: IframeProps) {
    super(props);
    this.iframeRef = React.createRef();
  }
  componentDidMount() {
    let iframe = this.iframeRef;
    window.addEventListener("message", function (event) {
      if (iframe && iframe.current) {
        if (event.data["height"]) {
          iframe.current.style.height = event.data["height"] + 'px';
        } else {
          iframe.current.style.height = '120px';
        }
      }
    });
  }

  render() {
    const { id, url, title } = this.props;

    return (
      <IframeStyle.div className={`m-iframe`}
      >
        <iframe
          id={id}
          title={title}
          src={url}
          frameBorder="0"
          ref={this.iframeRef}
        />
      </IframeStyle.div>
    );
  }
}

export default Iframe;
