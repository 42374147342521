import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DealerListStyle from './DealerList.style';
import Anchor from '../../../common/atoms/Anchor';
import { DealersListData, DealerInfoData } from "../../../../store/DealerLocator/types";
import { getValueFromMap, animateScrollTo } from "../../../../utils/commons";
import commons from "../../../../constants/commons";
import GlobalSettingsConatiner, {
    Props as GlobalSettingsConatinerProps
  } from "../../../../containers/GlobalSettings/GlobalSettings";
import SquidexContentConatiner, {
    Props as SquidexContentConatinerProps
  } from "../../../../containers/SquidexContent/SquidexContent";
import { SquidexContentData } from "../../../../store/SquidexContent/types";
import { getValueFromLocalStorage, setValueToLocalStorage } from '../../../shared/PropsHelper';
import { trackEvent } from "../../../../utils/trackEvent";
import { TrackEvent } from "../../../../constants/trackEvent";
import { GlobalState } from "../../../../store";

type Props  = GlobalSettingsConatinerProps & SquidexContentConatinerProps & {
    dealerList: DealersListData;
    action: (e: React.MouseEvent, dealerId:number) => void;
  };
  
  interface State {
    clicked: boolean;
  }

  class DealerList extends Component<Props, State>  {
    private itemList: any;
    private selectedItem: any;
    componentDidMount() {
    }
      componentDidUpdate() {        
      const { globalSettings, dealerList } = this.props;
      const { previousViewName } = globalSettings;
      console.log("view file", dealerList);
        if (this.itemList && this.selectedItem) {
          const radioListElem = ReactDOM.findDOMNode(this.itemList);
          if (this.selectedItem && radioListElem) {
            const selectedItem:any = ReactDOM.findDOMNode(this.selectedItem);
            if (selectedItem) {
              animateScrollTo(radioListElem, selectedItem.offsetTop - 1.25 * (selectedItem.clientHeight ? selectedItem.clientHeight : 180), 
              (previousViewName === commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW.toString() ||
               previousViewName === commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW2.toString() ? 20 :  1000));
            }
          }
        }
      }
  

     List = (dealerList: DealersListData, action:(e: React.MouseEvent, dealerId:number) => void , squidexContentsList: SquidexContentData) => (

         <ul className="m-dealerList__itemsWrapper" ref={n=>this.itemList = n} >
        {dealerList && dealerList.value && dealerList.value.map((dealerInfoData: DealerInfoData, index:number) => {
            const {
                id,
                name,
                city,
                countryName,
                distanceToDealer,
                openStatus,
                classDescription
            } = dealerInfoData;

            return (
              <li id={`DealerListItem_${index}`} className={`m-dealerList__item ${this.props.globalSettings.dealerId === id ? 'active' : ''}`}
                key={`DealerListItem_${index}`} ref={n => this.setFirstSelectedElem(n, id)} onClick={((e) => { action(e, id) })}>
                <div className="flex m-dealerList__header">
                  <h4>{name}</h4>
                  {openStatus ? <div className={`m-dealerList__openText ${openStatus === 'open' ? 'openNow' : ''}`}>
                    <span>{getValueFromMap(squidexContentsList.openingStatuses, openStatus)}</span>
                  </div>:''}
                  <div className="m-dealerList__iconWrapper no-grow no-shrink margin align top">
                    <Anchor action={((e) => { setValueToLocalStorage(this.props, 'favouriteList',id);
                    this.trackFavouriteEvent(id, name); e.stopPropagation(); })} >
                    {getValueFromLocalStorage(this.props, 'favouriteList',id) ? <i className="fa fa-star"></i> : <i className="fa fa-star-o"></i> }
                    </Anchor>
                    <Anchor action={((e) => { action(e, id) })}>
                      <i className="fa fa-info-circle"></i>
                    </Anchor>
                  </div>
                </div>
                <div className="m-dealerList__itemContent">
                  <div className="m-dealerList__itemDetails twelve flex">
                    <span>{getValueFromMap(squidexContentsList.classDescriptions, classDescription)}</span>
                    <span className="no-grow m-dealerList__dealerDistance">{distanceToDealer ? ` ${distanceToDealer}  ${squidexContentsList.distanceUnitLabel}`:""}</span>
                    <span className="m-dealerList__itemLocation no-grow">{city}, {countryName}</span>
                  </div>
                  {openStatus ? <div className={`m-dealerList__openText ${openStatus === 'open' ? 'openNow' : ''}`}>
                    <span>{getValueFromMap(squidexContentsList.openingStatuses, openStatus)}</span>
                  </div>:''}
                </div>
              </li>
            );
        })}
  </ul>
);


  
    setFirstSelectedElem(node: any, id: number) {
      if (id === this.props.globalSettings.dealerId) {
        this.selectedItem = node;
      }
    }
    resetFirstSelectedElem() {
      this.selectedItem = null;
    }

  /**
   * Method to track favourite event when user select the favourite button 
   * do not trigger any event if the button is unselect
   */
  trackFavouriteEvent(id : number, name : string) {
    if(getValueFromLocalStorage(this.props, 'favouriteList',id)){
      trackEvent(TrackEvent.TRACK_NAMES.DEALER_FAVORITE_LIST.toString(), name, id.toString());
    }
  }

  render(){
    this.resetFirstSelectedElem();
      const { dealerList, action, squidexContentsList } = this.props;
  
    return (
      <DealerListStyle.div className="m-dealerList">
        {this.List(dealerList, action, squidexContentsList)}
      </DealerListStyle.div>
      );
    }
  }
  
  
  
  export default GlobalSettingsConatiner(SquidexContentConatiner(DealerList));
