import {MapController} from 'react-map-gl';
import {debounce} from 'lodash';

const getPageScrollTop = () => ((window.pageXOffset !== undefined) ? window.pageYOffset : ((document.compatMode || "") === "CSS1Compat") ? 
              document.documentElement.scrollTop : document.body.scrollTop);

const scroll = (deltaY: any) => {
    window.scrollTo(0, getPageScrollTop() - deltaY)
}
const smoothScroll = debounce((deltaY: any)=> scroll(deltaY), 15)

interface Props {
    disableMapDragging:boolean,
}
  

export default class MapBoxController extends MapController  {
    public  disableMapDragging:boolean = false;
    constructor(props:Props) {
        super();
        this.disableMapDragging = props.disableMapDragging;
    }

    handleEvent(event:any) {
        var eventTriggered:boolean = false;

        if (event.type.startsWith('wheel') && !event.srcEvent.ctrlKey) {
            eventTriggered = true;
        }

        if(this.disableMapDragging && window.scrollTo && event.type.startsWith('panmove') && event.pointers && event.pointers.length === 1 ){
            smoothScroll(event.deltaY);
            eventTriggered = true;
        }

        if (!eventTriggered) {
            return super.handleEvent(event);
        }
        else {
            return false;
        }
    }
}
