import React , {Component} from 'react';
import InputStyle from "./Input.style";


export interface Props {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    onFocus: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress: (e:any) => void;
    placeholder?: string;
    type: "text";
    maxLength?:20;
    id: string;
    name?: string;
    value?: string;
}

export interface State { }

class Input extends Component<Props, State> {
  render() {
    const {
      placeholder,
      type,
      maxLength,
      onChange,
      onClick,
      onFocus,
      id,
      name,
      value,
      onKeyPress,
  } = this.props;

    return (
      <InputStyle.input
      id={id}
      className="a-input"
      name={name}
      maxLength={maxLength}
      type={type}
      onFocus = {onFocus}
      onKeyPress = {onKeyPress}
      onChange={onChange}
      placeholder={`${placeholder}`}
      onClick = {onClick}
      autoComplete={'off'}
      value={value}
    />
    );
  }
}

export default Input;
