import styledComponents from "styled-components";

export const svg = styledComponents.svg`
&.a-svgIcon {
  height: 100%;
  width: 100%;
}
`;


export const  SVGIconStyle = {
    svg: svg,
};

export  default  SVGIconStyle ;




