import styledComponents from "styled-components";

export const div = styledComponents.div`
&.m-lastSearchObjectList {
  width: 100%;
 
  .m-lastSearchObjectList__itemsWrapper {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .m-lastSearchObjectList__item {
    background: #fff;
    border-bottom: 1px solid #d0d0d0;
    padding: 16px 32px 16px 32px;

    @media (max-width: 768px) {
      padding: 5px 20px 5px 20px;
    }

    &:hover {
      background: #CFEAEF;
      transition: background .25s ease-in 0s;
    }

    span.no-grow {
      flex-basis: 42px;
    }

    .a-anchor {
      color: #4D4E53;
      display: flex;
      flex-wrap: nowrap;
      text-decoration: none;
    }
 
    .fa {
      color: #1251B5;
      font-size: 20px;
      margin-right: 24px;
      width: 20px;
      text-align: center;

      &.fa-marker-map {

      }
    }

    img {
      width: 20px;
      height: auto;
      margin-right: 24px;
    }
  }
}
`;


export const  LastSearchObjectList = {
  div: div,
};

export  default  LastSearchObjectList ;
