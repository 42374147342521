import React, { Component } from 'react';
//Styles
import AccordionStyle from './Accordion.style';
//Components
import Anchor from '../../../common/atoms/Anchor';
import SVGIcon from '../../../common/atoms/SVGIcon/SVGIcon';


interface Props  {
  className?:string,
  contentClassName?:string,
  children: any,
  title: string,
  childClassName?: string,
};

interface State {
  clicked: boolean;
}

class Accordion extends Component<Props, State>  {
  constructor(props: any) {
    super(props);
    this.state = {
      clicked: false,
    };
    this.itemDetailClick = this.itemDetailClick.bind(this);
}
  componentWillMount() {}

  private itemDetailClick = (e: React.MouseEvent) => {
   this.setState({
      clicked: !this.state.clicked,
    });
  }
  

  render() {
    const { children, title, className, contentClassName } = this.props;

    return (
      <AccordionStyle.div className={`m-accordion ${className}`} >
        <div className="flex m-accordion__title">
          <Anchor className="no-grow" action={this.itemDetailClick} >
          </Anchor>
          <h4>{title}</h4>
          <span className="no-grow">
            {this.state.clicked ? <SVGIcon icon="chevronUp" /> : <SVGIcon icon="chevronDown" /> }
          </span>
        </div>
        <div className={`m-accordion__content ${contentClassName}`} >
          {this.state.clicked ? children :null}
        </div>
        
      </AccordionStyle.div>
    );
  }
}



export default Accordion;

