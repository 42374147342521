export const MAP_VIEWPORT = {
    longitude: 12.273474096369373,
    latitude: 58.269762339430336,
    zoom: 6,
    speed: 0.2,
    bearing: 0,
    pitch: 0,
    width:800,
    height:600,
    };
  
  export const MAP_SETTINGS = {
    dragPan: true,
    dragRotate: false,
    minZoom: 3,
    maxZoom: 20,
    minPitch: 0,
    maxPitch: 0,
    speed: 0.6,
    }
  
  export const SETTINGS = {
    map_viewport: MAP_VIEWPORT,
    map_settings: MAP_SETTINGS,
  };
  