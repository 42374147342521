import React, { Fragment } from "react";
import { DealerInfoData } from "../../../../store/DealerLocator/types";
import styled from "styled-components";
import { postToParent } from "../../../../utils/commons";

interface Props {
  index: number;
  data: DealerInfoData;
  chooseDealerButtonText: string;
}
export default class MapBoxPopup extends React.Component<Props> {
  callConfiguratorCallBack = (data: DealerInfoData) => {
   /* let truckConfiguratorCallbackURL: string =
      getURLParamValue("truckConfiguratorCallbackURL") ||
      (window as any).truckConfiguratorCallbackURL ||
      "";
    if (truckConfiguratorCallbackURL) {*/
      //let queryString = "";
      let requestData = { 
        name : data.name,
        addressLine1 : data.addressLine1,
        addressLine2 : data.addressLine2,
        addressLine3 : data.addressLine3,
        postcode : data.postcode,
        city : data.city,
        countryname : data.countryName,
        ctdiid: data.ctdiId,
        organizationid: data.organizationId
      };
      let requestBody = {
        from:'dealer_locator', 
        data : requestData
      };
      console.log(requestBody);
      postToParent(requestBody); 

     /* if (data.name) {
        queryString += `${commons.AMPERSAND}${queryParams.CONFIGURATOR_QUERY.NAME}${commons.EQUALS}${data.name}`;
        requestObject.name = data.name; 
      }
      if (data.addressLine1) {
        queryString += `${commons.AMPERSAND}${queryParams.CONFIGURATOR_QUERY.ADDRESS_LINE1}${commons.EQUALS}${data.addressLine1}`;
      }
      if (data.addressLine2) {
        queryString += `${commons.AMPERSAND}${queryParams.CONFIGURATOR_QUERY.ADDRESS_LINE2}${commons.EQUALS}${data.addressLine2}`;
      }
      if (data.addressLine3) {
        queryString += `${commons.AMPERSAND}${queryParams.CONFIGURATOR_QUERY.ADDRESS_LINE3}${commons.EQUALS}${data.addressLine3}`;
      }
      if (data.postcode) {
        queryString += `${commons.AMPERSAND}${queryParams.CONFIGURATOR_QUERY.POSTCODE}${commons.EQUALS}${data.postcode}`;
      }
      if (data.city) {
        queryString += `${commons.AMPERSAND}${queryParams.CONFIGURATOR_QUERY.CITY}${commons.EQUALS}${data.city}`;
      }
      if (data.countryName) {
        queryString += `${commons.AMPERSAND}${queryParams.CONFIGURATOR_QUERY.COUNTRY_NAME}${commons.EQUALS}${data.countryName}`;
      } 
      queryString = queryString.replace(`${commons.AMPERSAND}`, ''); // Remove the first one
      
      console.log(requestObject);*/
      //callHttpGet(truckConfiguratorCallbackURL, queryString);

      //callHttpPost(truckConfiguratorCallbackURL, requestBody);
      
    /*} else {
      console.log("truckConfiguratorCallbackURL is blank =>>>>>> ");
    }*/
  };

  render() {
    const { index, data, chooseDealerButtonText } = this.props;
    return (
      <Fragment key={`popup_${index}`}>
        <DealerNameWrapper>
          <p>{data.name}</p>
        </DealerNameWrapper>
        <ButtonWrapper>
          <Button
            type="submit"
            onClick={e => this.callConfiguratorCallBack(data)}
          >
            {chooseDealerButtonText}
          </Button>
        </ButtonWrapper>
      </Fragment>
    );
  }
}

export const ButtonWrapper = styled.div`
  align-items: center;
  background: #1251b5;
  margin: -10px -10px -25px -10px;
  color: #ffffff;
  font-family: Arial;
  font-size: 14px;
  line-height: 50px;
`;

export const Button = styled.button`
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #1251b5;
  border: 0px;
  color: #ffffff;
  width: 100%;

  &:after {
    content: "\f105";
    font-family: "fontawesome";
    padding-left: 8px;
  }
`;

export const DealerNameWrapper = styled.div`
  align-items: center;
  line-height: 31px;
  background: #ffffff;

  p {
    color: #000000;
    font-family: Arial;
    font-size: 14px;
  }
`;
