import React , { Component } from 'react';
import CheckBoxStyle from './CheckBox.style';

export interface Props {
    label: any,
    name: string,
    checked: boolean,
    inputAriaLabel: any,
    onChange: (e:any) => void ,    
    disabled?: boolean,
    readOnly?: boolean,
};

export interface State {
};

class Checkbox extends Component<Props, State> {  

  render() {
    const {
        name,
        checked,
        onChange ,
        disabled,
        readOnly,
        inputAriaLabel,
        label
    } = this.props;


    return (
        <CheckBoxStyle.div className="a-checkBox">
            <label>{label}</label>
            <input name={name} className="a-checkBox__input"
                type="checkbox"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                readOnly={readOnly}
                aria-label={inputAriaLabel}
            />
            <span className="a-checkBox__tick"></span>
        </CheckBoxStyle.div>        
    );
  }
}

export default Checkbox;
