/**
 * Common simplified types for redux components
 */

import { Action, createStore, applyMiddleware, combineReducers, Reducer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Error , DealersListResponse, DealerDetailsListResponse, LocationIQResponse, SquidexContentResponse , ServicesResponse,
    DealersLocationListResponse, ReverseGeoLocationResponse} from "./Models/types";
import { GlobalSettings } from "./GlobalSettings/types";

import { reducer as globalSettingsReducer } from "./GlobalSettings/store";
import { reducer as dealerLocatorReducer } from "./DealerLocator/store";
import { reducer as locationIQReducer } from "./LocationIQ/store";
import { reducer as squidexContentReducer } from "./SquidexContent/store";
import thunkMiddleware, { ThunkAction } from "redux-thunk";


/**
 * Simplified async action return type.
 */
export type AsyncAction<TAction extends Action, TState = GlobalState> = ThunkAction<Promise<TAction> | undefined, TState, void, TAction>;

export interface SubStore<TOutput, SectionKey extends string, TActions extends Action> {
    reducer: {
        [section in SectionKey]: Reducer<TOutput, TActions>
    };
    defaultValue: TOutput;
}

/**
 * Root object of the redux store.
 */
export interface GlobalState {

    /**
     * Content managed texts and global settings.
     */
    globalSettings: GlobalSettings;


    /**
     * Dealer Locator list to fetch all the available dealers
     */
    dealersList :  AsyncState<DealersListResponse>;

    /**
     * Dealer Locator list to fetch all the available dealers
     */
    dealersLocationListData :  AsyncState<DealersLocationListResponse>; 

    /**
     * Dealer details to fetch details of specific dealer
     */
    dealerDetailsList :  AsyncState<DealerDetailsListResponse>;

    /**
     * Dealers List Response fetch details of specific dealer with ctdiID
     */
    dealersListCtdiId :  AsyncState<DealersListResponse>;    

    /**
     * Available LocationIQ locations
     */
    locationsList :  AsyncState<LocationIQResponse>;

    /**
     * Available SquidexContentResponse 
     */
    squidexContentsList :  AsyncState<SquidexContentResponse>;

    /**
     * Available dealers services 
     */
    servicesList :  AsyncState<ServicesResponse>;

     /**
     * Available dealers services 
     */
    reverseGeoLocation :  AsyncState<ReverseGeoLocationResponse>;

    /**
     * Favorite Dealer Locator list to fetch all the available favorite dealers
     */
    favoriteDealersList :  AsyncState<DealersListResponse>;

}

/**
 * Generic wrapper of asynchronously loaded state.
 */
export interface AsyncState<TState> {
    isFetching?: boolean;
    isInvalidated?: boolean;
    key?: string;
    value?: TState;
    errors?: Error[];
}

/**
 * Generic way to check if new data should be fetched for an async state subsection.
 * @param asyncState
 * @param valueIsStale Function that provides business logic to determine if the current value is stale and should be refreshed.
 */
export function shouldFetch<TState>(asyncState: AsyncState<TState>, inputKey?: string) {

    // We should not fetch if the fetch is already in progress.
    if (asyncState.isFetching) {
        return false;
    }

    // If value is manually invalidated, then we should fetch new data.
    if (asyncState.isInvalidated) {
        return true;
    }

    // If a request has already completed with the same key, then we should not fetch.
    if (inputKey &&
        asyncState.key === inputKey) {
        return false;
    }

    // If there is no fetch in progress and no value is set, then we should fetch new data.
    if (!asyncState.value) {
        return true;
    }

    // If an input key is included and the key different than the current value, then we should fetch new data.
    if (inputKey &&
        asyncState.key !== inputKey) {
        return true;
    }

    // We are not fetching, we have data and it is not stale, do not fetch new data.
    return false;
}

// export type Reducer<TState, TAction> = (state: TState, action: TAction) => TState;

export const rootReducer = combineReducers({
    ...globalSettingsReducer,
    ...dealerLocatorReducer,
    ...locationIQReducer,
    ...squidexContentReducer,
});

/**
 * Configure the redux store.
 * @param preloadedState
 */
export function configureStore(preloadedState: GlobalState) {


    // const enhancers = compose.apply(undefined, temp);
    const composeEnhancers = composeWithDevTools({
        // Specify name here, actionsBlacklist, actionsCreators and other options if needed
        name: "Volvo Dealer Locator",
    });
    return createStore(
        rootReducer,
        preloadedState, composeEnhancers(
            applyMiddleware(
                thunkMiddleware
                // , createLogger()
            )
        ));
}
