import React from 'react';
import ButtonStyle from './Button.style';

import { MouseEvent } from "react";

export interface Props {
    action: (e: MouseEvent) => void;
    children?: any;
    label?: string; 
    className?: string;
    type: "submit" | "button";
}

const Button: React.FC<Props> = (props: Props) => {
    const {
        action,
        label,
        type,
        children,
        className } = props;

    const newClass = className || "";

    return (
        <ButtonStyle.button 
            className={`a-button ${newClass}`} 
            onClick={action} type={type}
            >
            
            {label &&
                label
            }
            {children}
        </ButtonStyle.button>
    );
};

export default Button;
