import React, { Component } from "react";
import LocationListStyle from "./LastSearchObjectList.style";
import Anchor from "../../../common/atoms/Anchor";
import GlobalSettingsConatiner, {
  Props as GlobalSettingsConatinerProps
} from "../../../../containers/GlobalSettings/GlobalSettings";
import SquidexContentConatiner, {
  Props as SquidexContentConatinerProps
} from "../../../../containers/SquidexContent/SquidexContent";
import { LastSearchObjectType } from "../../../shared/PropsHelper";
import { apiEndpoints } from "../../../../constants/apiEndpoints";

type Props = GlobalSettingsConatinerProps &
  SquidexContentConatinerProps & {
    openUpdatedDealerDetailsViewDealerSelection: (
      e: React.MouseEvent,
      dealerId: number
    ) => void;
    openUpdatedDealerSearchViewLocationSelection: (
      e: React.MouseEvent,
      locationName: string,
      latitude: string,
      longitude: string
    ) => void;
    openUpdatedDealerSearchViewTextSelection: (searchQueryText: string) => void;
    lastSearchObjectList: Array<LastSearchObjectType>;
    getDealersListForUserCurrentLocation: () => void;
  };

interface State {}

class LastSearchObjectList extends Component<Props, State> {
  componentDidMount() {}

  private getLastSearchObjectList = () => {
    const {
      lastSearchObjectList,
      openUpdatedDealerDetailsViewDealerSelection,
      openUpdatedDealerSearchViewLocationSelection,
      openUpdatedDealerSearchViewTextSelection,
      getDealersListForUserCurrentLocation,
      squidexContentsList, 
      globalSettings
    } = this.props;
    const { isBusLocator } = globalSettings;
    return (
      <ul className="m-lastSearchObjectList__itemsWrapper">
        {lastSearchObjectList &&
          lastSearchObjectList.length > 0 &&
          lastSearchObjectList.map(
            (lastSearchObject: LastSearchObjectType, index: number) => {
              const {
                type,
                dealerId,
                dealerName,
                locationName,
                locationLatitude,
                locationLongitude,
                searchText
              } = lastSearchObject;
              const id = dealerId || 0;
              const name = dealerName || "";
              const locName = locationName || "";
              const locLat = locationLatitude || "";
              const locLon = locationLongitude || "";
              const text = searchText || "";

              return (
                <li
                  className="m-lastSearchObjectList__item"
                  key={`lastSearchObjectList_${index}`}
                >
                  {type === "dealer" ? (
                    <Anchor
                      className="flex align center left"
                      key={"lastSearchObjectList_dealer_" + index}
                      action={e => {
                        openUpdatedDealerDetailsViewDealerSelection(e, id);
                      }}
                    >
                      <span className="no-grow">
                        <img
                          src={apiEndpoints.DEALER_API_DOMAIN + apiEndpoints.DEALER_API_ENDPOINTS.GET_CONTENT_ASSESTS.toString()
                             + (isBusLocator === true ? squidexContentsList.busPinImageURL : squidexContentsList.dealerPinImageURL)}
                          alt="Dealer map pin"
                        />
                      </span>
                      <span>{name} </span>
                    </Anchor>
                  ) : type === "location" ? (
                    <Anchor
                      className="flex align center left"
                      key={"lastSearchObjectList_location" + index}
                      action={e => {
                        openUpdatedDealerSearchViewLocationSelection(
                          e,
                          locName,
                          locLat,
                          locLon
                        );
                      }}
                    >
                      <span className="no-grow">
                        <i className="fa fa-map-marker" aria-hidden="true" />
                      </span>
                      <span>{locName} </span>
                    </Anchor>
                  ) : type === "my_location" ? (
                    <Anchor
                      className="flex align center left"
                      key={"lastSearchObjectList_my_location" + index}
                      action={e => {
                        getDealersListForUserCurrentLocation();
                      }}
                    >
                      <span className="no-grow">
                        <i
                          className="fa fa-location-arrow"
                          aria-hidden="true"
                        />
                      </span>
                      <span>{squidexContentsList.yourCurrentLocationText} </span>
                    </Anchor>
                  ) : (
                    <Anchor
                      className="flex align center left"
                      key={"lastSearchObjectList_search" + index}
                      action={e => {
                        openUpdatedDealerSearchViewTextSelection(text);
                      }}
                    >
                      <span className="no-grow">
                        <i className="fa fa-search" aria-hidden="true" />
                      </span>
                      <span>{text} </span>
                    </Anchor>
                  )}
                </li>
              );
            }
          )}
      </ul>
    );
  };

  render() {
    return (
      <LocationListStyle.div className="m-lastSearchObjectList">
        {this.getLastSearchObjectList()}
      </LocationListStyle.div>
    );
  }
}

export default GlobalSettingsConatiner(
  SquidexContentConatiner(LastSearchObjectList)
);
