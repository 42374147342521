export enum DEALER_VIEW_NAMES {
    DEALER_SEARCH_VIEW = "DealerSearchView" as any,
    DEALER_FILTER_VIEW = "DealerFilterView" as any,
    DEALER_DETAILS_VIEW = "DealerDetailsView" as any,
    DEALER_DETAILS_VIEW2 = "DealerDetailsView2" as any,
}

const  MAP_ACCESS_TOKEN:string = 'pk.ba19f7e003e2d55dd7a1526d15457791';

const  ZOOMA_CONTACT_FORM_URL:string = 'https://content.volvotrucks.com/da/dealer-form?456&dealer_contact_email=';

const DEALER_FILTER_CRITERIAS = 'dealerFilterKey';

const AMPERSAND:string = '&';
const EQUALS:string = '=';
const NUMBER_THREE:number = 3;
const NUMBER_THOUSANDS:number = 1000;
const NUMBER_TWO:number = 2;
const NUMBER_HUNDRED:number = 100;
const DEBOUNCE_WAIT_TIME:number = 500;
const VTC_HQ_LATITUDE:number = 57.7126311;
const VTC_HQ_LONGITUDE:number = 11.9230829;

const DEVICE_TYPES = {
    tablet: 768,
    small: 1024,
    medium: 1300,
    large: 1440,
    widescreen: 1650
  }

export const  Commons = {
    DEALER_VIEW_NAMES: DEALER_VIEW_NAMES,
    MAP_ACCESS_TOKEN: MAP_ACCESS_TOKEN,
    AMPERSAND: AMPERSAND,
    EQUALS: EQUALS,
    DEALER_FILTER_CRITERIAS_KEY: DEALER_FILTER_CRITERIAS,
    NUMBER_THREE: NUMBER_THREE,
    NUMBER_TWO: NUMBER_TWO,
    DEBOUNCE_WAIT_TIME: DEBOUNCE_WAIT_TIME,
    NUMBER_THOUSANDS: NUMBER_THOUSANDS,
    NUMBER_HUNDRED: NUMBER_HUNDRED,
    VTC_HQ_LATITUDE: VTC_HQ_LATITUDE,
    VTC_HQ_LONGITUDE: VTC_HQ_LONGITUDE,
    DEVICE_TYPES: DEVICE_TYPES,
    ZOOMA_CONTACT_FORM_URL: ZOOMA_CONTACT_FORM_URL,
    
};

export  default  Commons ;
