import styledComponents from "styled-components";

export const div = styledComponents.div`

&.m-dealerList {
  width: 100%;
  height: 100%;

  .m-dealerList__itemsWrapper {
    height: auto;
    list-style-type: none;
    margin: 0;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden; 
    padding: 0;
  }

  .m-dealerList__item {
    border-bottom: 2px solid #ECECEC;
    flex-wrap: nowrap;
    list-style-type: none;
    margin: 0;
    padding: 17.5px 32px 16.5px;
    position: relative;
    transition: background .25s ease-in 0s;

    &.active{
      background: #CFEAEF;
    }

    &:hover {
      background: #F4F4F4;
      cursor: pointer;
      transition: background .25s ease-in 0s;
    }

    @media screen and (max-width: 1024px) {
      padding: 16px 20px;
    }

    > div:first-child {
      position: relative;
    }

    h4 {
      hyphens: auto;
      padding-right: 16px;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
        margin: 0;
      }
    }

    .m-dealerList__itemLink {
      bottom: 0;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  .m-dealerList__itemDetails {
    color: #4D4E53;
    display: flex;
    font-size: 14px;
    justify-content: flex-start;
    flex-wrap: nowrap;

    > * {
      flex-shrink: 0;

      &:first-child {
        flex-basis: 140px;
        flex-grow: 0;

        @media (max-width: 1024px) {
          flex-basis: auto;
        }
      }

      &.m-dealerList__dealerDistance {
        flex-basis: 100px;

        @media (max-width: 1024px) {
          flex-basis: auto;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      flex-wrap: wrap
    }

    @media screen and (max-width: 1024px) {
      justify-content: space-between;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 2; 

      > *:last-child {
        margin: 0;
      }
    }

    .m-dealerList__dealerDistance {
      margin-left: 7.5px;
      margin-right: 7.5px;

      @media screen and (max-width: 1200px) {
        margin-left: 0;
      }
    }
  }

  .m-dealerList__itemLocation {
    hyphens: auto;
    overflow-wrap: break-word;
    text-align: right;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    flex-shrink: 1;
    text-align: right;
  }

  .m-dealerList__header {
    flex-wrap: nowrap;

    @media screen and (min-width: 1025px) {
      .m-dealerList__openText {
        display: block;
      }
    }
  }

  .m-dealerList__itemContent {
    @media screen and (max-width: 1024px) {
      .m-dealerList__openText {
        display: block;
      }
    }
  }

  .m-dealerList__openText {
    color: #4d4e53;
    font-size: 14px;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
    display: none;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      text-align: left;
    }

    &.openNow {
      color: #009640;
    }
  }

  .m-dealerList__iconWrapper {
    margin-left: 8px;

    .a-anchor {
      display: block;
      margin: 0 0 0 16px;

      @media screen and (max-width: 1024px) {
        &:first-child {
          margin: 0;
        }
      }
    }
  }

  .fa {
    color: #3A4447;;
    font-size: 22px;
    
    @media screen and (max-width: 1024px) {
      font-size: 26px;
    }
  }
}
`;

export const  DealerListStyle = {
  div: div,
};

export  default  DealerListStyle ;
