
import styledComponents from "styled-components";

export const div = styledComponents.div`
&.error-message {
    margin: 16px 0;
    text-align: center;
    font-size: 16px;
}
 
`;

export const  ErrorMessageStyle = {
  div: div,
};

export  default  ErrorMessageStyle ;
