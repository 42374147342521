const satellite = (window as any)._satellite;

export function trackEvent(name: string, dealerName : string, dealerId : string) {
    if (satellite) {
        if(dealerName || dealerId) {
            satellite.track(name, {
                'dealerName': dealerName, 
                'dealerId': dealerId});
        } else {
            satellite.track(name);
        }
    }
}

export function trackEventVar(name: string, value: string) {
    if (satellite) {
        satellite.setVar(name, value);
    }
}
