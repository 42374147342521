
import styledComponents from "styled-components";

export const div = styledComponents.div`
&.m-mapBox  {

  .mapboxgl-canvas-container {
    direction: ltr;
  }

  height: 100%;
  position: relative;

  > div {
    bottom: 0;
    height: 100% !important;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
    width: 100% !important;
    z-index: 0;
  }
  
  .nav {
    margin: 0 8px 16px 0;
  }

  .m-mapBox__icon svg {
    fill: orange;
    height: 32px;
    width: auto;
  }
  
  .m-mapBox__icon svg.small {
    fill: orange;
    height: 32px;
    width: auto;
  }
  
  .m-mapBox__icon svg.medium {
    fill: orange;
    height: 64px;
    width: auto;
  }

  .mapboxgl-marker {
    img {
      height: 40px;
      width: 30px;
    }
  }


  .mapboxgl-ctrl {
    background: transparent; 
    border: none; 
    border-radius: 0; 
    box-shadow: none;
    
  }

  .mapboxgl-popup {
    background: #FFFFFF;
    z-index: 1;
  }
    
  .mapboxgl-popup-close-button {
    display:none;
  }  

  .mapboxgl-ctrl-group {
    padding: 0 10px;
    margin-top:-100px;
  }

  button.mapboxgl-ctrl-icon {
    background: #fff; 
    background-image: none !important; 
    border: 1px solid #1251b5;
    border-radius: 50%;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.3);
    width: 40px;
    height:40px;
    position: relative; 
    transition: all .25s ease-in 0s;
    margin: 10px 0; 

    @media screen and (max-width: 1024px) {
      width: 34px;
      height: 34px;
    }

    &:after {
      content:"";
      color: #1251b5; 
      font-size: 22px; 
      position: absolute; 
      top: 3px; 
      left: 0; 
      width: 100%; 
      height: 100%; 
      text-align: center; 
      z-index: 1; 
      font-family: FontAwesome;

      @media screen and (max-width: 1024px) {
        font-size: 18px; 
        top: 2px; 
      }
    }

    &.mapboxgl-ctrl-geolocate:after {
      content:"\f124";
    }

    &.mapboxgl-ctrl-zoom-in:after {
      content:"\f067";
    }

    &.mapboxgl-ctrl-zoom-out:after {
      content:"\f068";
    }

    &.mapboxgl-ctrl-compass  {
     display: none; 
    }

    &:hover {
      background: #1251b5; 

      &:after {
        color: #fff; 
        transition: all .25s ease-in 0s;
      }
    }

    &:focus {
      border-radius:50%;
    }
  }

  .m-mapBox__favoriteIcon {
    position: relative; 
    padding: 0 10px;
    display: block; 
    margin-top: 24px; 

    span {
      background: #fff; 
      border: 1px solid #1251b5;
      border-radius: 50%;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.3);
      display: block; 
      width: 40px;
      height: 40px;
      transition: all .25s ease-in 0s;
      text-align: center; 

      @media screen and (max-width: 1024px) {
        width: 34px;
        height: 34px;
      }

      &.active {
        background: #1251b5; 
  
        .fa {
          color: #fff;
        }
      }
    }

    .fa {
      color: #1251b5; 
      font-size: 22px; 
      text-align: center; 
      z-index: 1;
      padding-top: 7px; 
      transition: all .25s ease-in 0s;

      @media screen and (max-width: 1024px) {
        font-size: 18px; 
      }
    }

    &:hover span {
      background: #1251b5; 
    }

    &:hover .fa {
      color: #fff; 
    }
  }
}

.m-mapBox__navigationIcon {
  position: relative; 
  padding: 0 10px;
  display: block; 
  margin-top: 8px; 

  span {
    background: #fff; 
    border: 1px solid #1251b5;
    border-radius: 50%;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.3);
    display: block; 
    width: 40px;
    height: 40px;
    transition: all .25s ease-in 0s;
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: 34px;
      height: 34px;
    }
    
    &.active {
      background: #1251b5; 

      .fa {
        color: #fff;
      }
    }
  }

  .fa {
    color: #1251b5; 
    font-size: 22px; 
    text-align: center; 
    z-index: 1;
    padding-top: 7px; 
    transition: all .25s ease-in 0s;

    @media screen and (max-width: 1024px) {
      font-size: 18px; 
    }
  }

  &:hover span {
    background: #1251b5; 
  }

  &:hover .fa {
    color: #fff; 
  }
}

.myCurrentLocation {
  font-size: 14px; 
  color: #303030;
  margin-left: 0px;
  margin-top: 18px;
  

  &:after {
    background: #303030;
    border-radius: 50%;
    content: "";
    height: 30px;
    left: -9px;
    opacity: .25;
    position: absolute;
    top: 9px;
    width: 30px;
    z-index: 1;
  }

  .mapboxgl-popup-content {
    background: #FFFFFF;
  }


}
 
 
`;
 


export const  MapBoxStyle = {
  div: div,
};

export  default  MapBoxStyle ;





 