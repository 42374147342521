import React, { Fragment } from "react";
import Anchor from "../../atoms/Anchor";
import { LocationPinData } from "../../../../store/GlobalSettings/types";
import { DealerInfoData } from "../../../../store/DealerLocator/types";
import commons from "../../../../constants/commons";
import { apiEndpoints } from "../../../../constants/apiEndpoints";

interface Props {
  index: number;
  data?: DealerInfoData;
  locationPinData?: LocationPinData;
  action?: (e: React.MouseEvent, dealerId: number, dealerName : string) => void;
  viewName?: string;
  dealerPinImageURL:string;
}
export default class MapBoxPin extends React.Component<Props> {
  render() {
    const { action, data, locationPinData, index, viewName, dealerPinImageURL } = this.props;
    return (
      <Fragment key={`pin_${index}`}>
        {/* <div className="m-mapPin" /> */}

        {data && action ? (
          <Fragment>
            {viewName !==
            commons.DEALER_VIEW_NAMES.DEALER_DETAILS_VIEW2.toString() ? (
              <Anchor
                action={e => {
                  action(e, data.id, data.name);
                }}
              >
                <img
                  src={apiEndpoints.DEALER_API_DOMAIN + apiEndpoints.DEALER_API_ENDPOINTS.GET_CONTENT_ASSESTS.toString() + dealerPinImageURL}
                  alt="map pin"
                />
              </Anchor>
            ) : (
              <Anchor>
                <img
                  src={apiEndpoints.DEALER_API_DOMAIN + apiEndpoints.DEALER_API_ENDPOINTS.GET_CONTENT_ASSESTS.toString() + dealerPinImageURL}
                  alt="map pin"
                />
              </Anchor>
            )}{" "}
          </Fragment>
        ) : (
          ""
        )}

        {locationPinData ? (
          <i className="myCurrentLocation fa fa-circle" aria-hidden="true" />
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}
